import React, {useEffect, useState} from 'react';
import * as GUI from "../styles";
import * as UI from "../style";
import {AboutBlock} from "../Blocks/Blocks";
import {ButtonGetData, GetGoogleSheets, ButtonShowData} from "../Blocks/Buttons";
import {ModalGetGoogleSheetsData, ModalShowGoogleSheetsData} from "../Modal/modal";
import AlgorithmAPIold from "../../api/AlgorithmAPI/AlgorithmAPI";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {DataJson} from "../../types/AlgorithmCore";
import {useTranslation} from "react-i18next";
import {getTheme} from "../../index";
import * as MUI from "../style";
import {algorithmAPI} from "../../services/AlgorithmService";
import {useForm} from "react-hook-form";
import {userAPI} from "../../services/UserService";
import {Spinner} from "../Spinner";
export const AlgorithmGoogleData = ({setGoogleDataApi, setGoogleSheetAuth, setExcelData, setToken}: any) => {
    const { t } = useTranslation();
    const [modalActive, setModalActive] = useState(false)
    const [modalShowActive, setModalShowActive] = useState(false)
    const [ stateExcel, setStateExcel ] = useState('Google Sheet')
    const apiService = new AlgorithmAPIold()
    const [jsonData, setJsonData] = useState()
    const checkOwnKeys = (keys:any, object:any) => {
        return keys.every(function (key:any) {
            return object.hasOwnProperty(key);
        });
    }
    const handleChangeData = () => {
        stateExcel === 'Google Sheet' ? setStateExcel('Excel') : setStateExcel('Google Sheet')
    }
    const [uploadTokenXlsx, {isLoading, isSuccess}] = algorithmAPI.useUploadXslxMutation()
    const [refreshToken] = userAPI.useRefreshTokenMutation()
    const { register, handleSubmit, watch } = useForm()
    const privateToken = watch('privateToken')
    const uploadPrivateToken = async () => {
        const dataPrivate = {
            private_token: privateToken
        }
        const dataRefresh = {
            refresh: localStorage.getItem('tokenRefresh')
        }
        await uploadTokenXlsx(dataPrivate).then((response:any) => {
            if (response?.data?.secret) {
                setExcelData(response)
                SuccessNotify('Success token!')
            }
            if (response?.error?.data?.data === 'JWT encode err: Signature has expired') {
                refreshToken(dataRefresh).then((response:any) => {
                    setToken(response.data.access)
                    localStorage.setItem('token', response.data.access)
                })
                uploadTokenXlsx(dataPrivate).then(() => {
                    if (response?.error?.data?.data === 'Objects not found.') {
                        ErrorNotify('Not correct token!')
                    } else {
                        setExcelData(response)
                        SuccessNotify('Success token!')
                    }
                })
            }
            if (response?.error?.data?.data === 'Objects not found.') {
                ErrorNotify('Not correct token!')
            }
        })
    }
    const getGoogleSheetsData = async (data: any) => {
        data.preventDefault()
        const myData = Object.assign(file, {'sheet_id': data.target.sheetId.value})
        myData.sheet_id = myData.sheet_id.replace(/\s/g, '')
        setGoogleSheetAuth(myData)
        if (checkOwnKeys(DataJson, file) === true) {
            setModalActive(false)
            await apiService.setGoogleSheet(myData)
                .then((r: any) => setJsonData(r))
        } else {
            ErrorNotify('Error in JSON file')
        }
    }
    const [file, setFile] = useState("");
    const [uploadFile, setUploadFile] = useState('Choose')
    const handleChange = (event: any) => {
        try {
            let fileReader = new FileReader();
            fileReader.onload = (event: any) => {
                setFile(JSON.parse(event.target.result))
            };
            fileReader.readAsText(event.target.files[0]);
            setUploadFile(event.target.files[0].name)
        } catch (e) {
            ErrorNotify(`Error: ${e}`)
        }
    };
    useEffect(() => {
        setGoogleDataApi(jsonData)
    }, [jsonData, setJsonData, setGoogleDataApi])
    return (
        <>
            <AboutBlock name='GoogleSheets data'/>
            <GUI.FlexBlock>
                <ButtonGetData setModalActive={setModalActive}/>
                {typeof jsonData === "object" ? <ButtonShowData setModalShowActive={setModalShowActive}/> : ''}
            </GUI.FlexBlock>
            <ModalGetGoogleSheetsData active={modalActive} setActive={setModalActive}>
                {stateExcel === 'Google Sheet' ?
                    <GUI.FormModal onSubmit={getGoogleSheetsData} encType="multipart/form-data">
                        <div style={{display: 'flex', border: '1px solid black', padding: '5px', marginBottom: '20px', borderRadius: '5px'}}>
                            <div style={{marginRight: '10px', color: 'black'}}>Choose:</div>
                            <button onClick={() => handleChangeData()} style={{outline: 'none', border: '0', cursor: 'pointer'}}>
                                {stateExcel}
                            </button>
                        </div>
                        <UI.InstructionLabel style={{border: `1px solid ${getTheme().colorFont}`}}
                                             background={getTheme().colorFontModal}
                                             href="/Instruction.pdf" target="_blank">
                            <GUI.AboutInstruction color={getTheme().colorFont}>
                                {t("Instruction")}
                            </GUI.AboutInstruction>
                            <GUI.SVGinButton
                                src="/images/List.svg"
                                alt="List"/>
                        </UI.InstructionLabel>
                        <GUI.AboutInput style={{color: getTheme().colorFont}}>{t("AddYourJson")}</GUI.AboutInput>
                        <div style={{display: 'flex'}}>
                            <UI.DefaultLabel style={{border: `1px solid ${getTheme().colorFont}`, marginRight: '10px'}}>
                                <GUI.InputFile
                                    type="file" onChange={handleChange} accept=".json"/>
                                <UI.DefaultSpan style={{color: getTheme().colorFont}}>{uploadFile}
                                </UI.DefaultSpan>
                                <GUI.SVGinButton
                                    src="/images/Import.svg"
                                    alt="Upload"/>
                            </UI.DefaultLabel>
                            <UI.DefaultLabel style={{border: `1px solid ${getTheme().colorFont}`}}>
                                <UI.InputStep color={getTheme().colorFont}
                                              placeholder="Your Sheet ID"
                                              style={{width: "100%", textAlign: "center", border: 'none', outline: 'none'}}
                                              type="text" name="sheetId" id="sheetId" required={true}/>
                            </UI.DefaultLabel>
                        </div>
                        <GetGoogleSheets checkOwnKeys={checkOwnKeys} file={file}/>
                    </GUI.FormModal>
                    :
                    <GUI.FormModal onSubmit={handleSubmit(uploadPrivateToken)}>
                        {isLoading && !isSuccess ? <Spinner/> :
                            <>
                                <div style={{
                                    display: 'flex',
                                    border: '1px solid black',
                                    padding: '5px',
                                    marginBottom: '1px',
                                    borderRadius: '5px'
                                }}>
                                    <div style={{marginRight: '10px', color: 'black'}}>Choose:</div>
                                    <button onClick={() => handleChangeData()}
                                            style={{outline: 'none', border: '0', cursor: 'pointer'}}>
                                        {stateExcel}
                                    </button>
                                </div>
                                <GUI.AboutInput style={{color: getTheme().colorFont}}>Add your token</GUI.AboutInput>
                                <div style={{display: 'flex'}}>
                                    <UI.InputStep color={getTheme().colorFont} type="text" placeholder="Your token"
                                                  style={{width: "100%", textAlign: "center",  outline: 'none'}}
                                                  required {...register('privateToken')}/>
                                </div>
                                <MUI.DefaultButton color={getTheme().greenLight}
                                                   background={getTheme().darkGreen}
                                                   width="180px" height="48px" type="submit">{t("Submit")}</MUI.DefaultButton>
                            </>
                        }
                    </GUI.FormModal>
                }
            </ModalGetGoogleSheetsData>
            <ModalShowGoogleSheetsData active={modalShowActive} setActive={setModalShowActive}>
                <GUI.TableData>
                    <tbody>
                    <tr>
                    {typeof jsonData === "object" ? Object.entries(jsonData).map((key: any, index: number) => {
                        return (
                            <td key={index} ><span>{key[0].replace(/%/gi, '')}</span>
                                {Object.values(key[1]).map((value: any, index: number) => {
                                    return <tr key={index}><td>{value}</td></tr>
                                })}
                            </td>
                        )
                    }) : <td>Loading</td>}
                    </tr>
                    </tbody>
                </GUI.TableData>
            </ModalShowGoogleSheetsData>
        </>
    )
}