import {DefaultNotify, ErrorNotify} from "../Notifies/Notifications";
import {macroAPI} from "../services/MacroService";

type item = {
    input_text: string
    put_sms: string
    macros: any
    save_phone: string,
    sms_service_number: string
}

export const coreValidation = (core: item[], googleApiDataList: string[]) => {
    const inputList: any = []
    const potentialErrors: any = []
    const errorList: any = []
    if (googleApiDataList.length > 1 && core.length > 1) {
        // core filter for can just need items (input text, put sms, macros)
        // add macros in array
        if (core) {
            const filterCore = core?.filter((item: item) => item.input_text || item.put_sms || item.save_phone || item.sms_service_number)
            for (const item of filterCore) {
                // if (item?.macros) {
                //     console.log(item)
                //     const {data: macro} =
                //         macroAPI.useGetMacroQuery(Number(item.macros), {})
                //     console.log(macro.sub_core['list'])
                //     if (typeof macro.sub_core['list'] === "string") macro.sub_core['list'] = JSON.parse(item.macros)
                //     const filterMacro = macro.sub_core['list'].filter((macrosItems: item) => macrosItems.input_text || macrosItems.put_sms)
                //     filterMacro?.map((macroItem: item) => {
                //         if (macroItem.input_text) inputList.push({
                //             macros: macro.sub_core['list'].indexOf(macroItem),
                //             field: macroItem.input_text
                //         })
                //         else inputList.push({macros: macro.sub_core['list'].indexOf(macroItem), field: macroItem.put_sms})
                //     })
                // } else {
                //     if (item.input_text) inputList.push({step: core.indexOf(item), field: item.input_text})
                //     else inputList.push({algorithm: core.indexOf(item), field: item.put_sms})
                // }
                if (item.input_text) inputList.push({step: core.indexOf(item), field: item.input_text})
                    else if(item.save_phone) inputList.push({step: core.indexOf(item), field: item.save_phone})
                    else if(item.sms_service_number) inputList.push({step: core.indexOf(item), field: item.sms_service_number})
                    else inputList.push({algorithm: core.indexOf(item), field: item.put_sms})
            }
        }
        // check algorithm core on actual keys
        for (const item of inputList) {
            console.log(item)
            if (item.field[0] === '%' && item.field[item.field.length -1] === '%') {
                potentialErrors.push(item)
            }
        }
        for (const potentialError of potentialErrors) {
            if (!googleApiDataList.includes(potentialError)) errorList.push(potentialError)
        }
        DefaultNotify(`This step(s) not in Google Data: ${errorList.map((elem: any) => `<br/>step: ${elem.step} : ${elem.field}`)}\``)
        // DefaultNotify(`Maybe data is not correct: ${JSON.stringify(errorList)}`, 200000)
        // check correct google Api Data with algorithm core
    }
    return errorList
}