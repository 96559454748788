import * as UI from "../style"
import {useTranslation} from "react-i18next";
import * as GUI from "../styles";
import React, {useEffect, useState} from "react";
import {algorithmAPI} from "../../services/AlgorithmService";
import {CustomNotify, ErrorNotify} from "../../Notifies/Notifications";
import {filesAPI} from "../../services/FileService";
import {remoteAPI} from "../../services/RemoteService";

export const AboutBlock = ({name}: any) => {
    const { t } = useTranslation();
    return <UI.AboutBlock>{t(`${name}`)}</UI.AboutBlock>
}

export const Tags = ({id, setActiveImport, algorithm, setStatusAlgorithm, refetch, currentComp, remoteData}: any) => {
    const [ getDownloadScreenshot ] = filesAPI.useLazyUploadScreenshotQuery()
    const file = algorithmAPI.useExportAlgorithmQuery(id, {
        refetchOnMountOrArgChange: true})

    const getAlgorithmData = () => {
        const data = new Blob([file.data], {
            type: 'application/x-zip-compressed'
        })
        console.log(data)
        const binURL = window.URL.createObjectURL(data)
        const tempLink = document.createElement('a')
        tempLink.href = binURL;
        tempLink.setAttribute('download', `${algorithm.name.replace(/ /ig, '_')}_data_${id}.zip`)
        tempLink.click()
    }
    const checkFile = async () => {
        const username: string = localStorage.getItem('username') || 'none'
        try {
            const data = {
                username: username,
                alg_id: id,
                remote: currentComp
            }
            const image = await getDownloadScreenshot(data)
            const file = new File([image.data], 'test.png', {
                type: 'image/png'
            })
            if (image.status !== 'rejected' && image.data.size > 10) {
                const binURL = window.URL.createObjectURL(file)
                const tempLink = document.createElement('a')
                tempLink.href = binURL;
                tempLink.setAttribute('download', `${algorithm.name.replace(/ /ig, '_')}_core_${id}.png`)
                tempLink.click()
                return true
            } else {
                setTimeout(() => {
                    checkFile()
                }, 2000)
            }
        } catch (error) {
            console.log(`Error = ${error}`)
        }
    }
    const makeScreen = async () => {
        try {
            const data = {
                make_screen_status: !remoteData?.make_screen_status,
            }
            await setStatusAlgorithm({data, currentComp})
                .then((response: any) => CustomNotify(response.data.data, response.data.status))
                .then(() => refetch())

        } catch (error) {
            console.log(error)
        }
        setTimeout(() => {
            checkFile()
        }, 1000)
    }
    useEffect(() => {}, [remoteData, currentComp])
    return (
        <UI.Tags>
            <UI.CardButtonButtonSVG
                onClick={() => getAlgorithmData()}
                src="/images/Export.svg"
                title="Export core"
                alt="Reload Algorithm"/>
            {!remoteData?.stop_runtime_status ?
                <UI.CardButtonButtonSVG
                    src="/images/PrintScreenInactive.svg"
                    aria-disabled={true}
                    title="Make screenshot"
                    alt="Reload Algorithm"/>
                :
                <UI.CardButtonButtonSVG
                    onClick={() => makeScreen()}
                    src="/images/PrintScreen.svg"
                    title="Make screenshot"
                    alt="Reload Algorithm"/>
            }
            <UI.CardButtonButtonSVG
                onClick={() => setActiveImport(true)}
                src="/images/Import.svg"
                title="Import core"
                alt="Reload Algorithm"/>
        </UI.Tags>
    )
}