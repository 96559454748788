import React, {ChangeEvent, useEffect, useState} from 'react';
import { ImportTemplates, ModalRemove } from "../Modal/modal";
import * as UI from "../AlgorithmList/style";
import * as MUI from "../style";
import {getTheme} from "../../index";
import * as GUI from "../styles";
import {AboutBlock} from "../Blocks/Blocks";
import {Spinner} from "../Spinner";
import {useForm} from "react-hook-form";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {templatesAPI} from "../../services/LayoutsService";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {TemplateCore} from "./TemplateCore";


export const TemplateCard = () => {

    const [ activeImport, setActiveImport ] = useState<boolean>(false)
    const { t } = useTranslation();
    const [modalActive, setModalActive] = useState(false)
    const { id }: any = useParams()
    const navigate = useNavigate()
    const {data: template, isLoading, isFetching} =
        templatesAPI.useGetTemplateQuery(id, {
            refetchOnMountOrArgChange: true,
            pollingInterval: 10000,
            skip: false})

    const [deleteTemplate] = templatesAPI.useDeleteTemplateMutation()

    const file = templatesAPI.useExportTemplateQuery(id, {
        refetchOnMountOrArgChange: true})
    const getAlgorithmData = () => {
        const data = new Blob([file.data], {
            type: 'application/json'
        })
        const binURL = window.URL.createObjectURL(data)
        const tempLink = document.createElement('a')
        tempLink.href = binURL;
        tempLink.setAttribute('download', `${template.name.replace(/ /ig, '_')}_core_${id}.json`)
        tempLink.click()
    }
    const removeTemplate = async () => {
        try {
            await deleteTemplate(id)
            SuccessNotify('Template Core has deleted')
            navigate('/templates')
        } catch (error) {
            ErrorNotify(`Template has not deleted! Reason: ${error}`)
        }
    }
    const { register } = useForm()
    const [nameTemplate, setNameTemplate] = useState(template?.name)
    useEffect(() => {
        if(!isFetching){
            setNameTemplate(template?.name)
        }
    }, [template, isFetching])
    if (isLoading) return <Spinner/>
    if (!template) return <div>Error</div>
    const showModal = () => {
        setModalActive(true)
    }

    const ModalItem = () => {
        return  <ModalRemove active={modalActive} setActive={setModalActive}>
            <UI.CardAboutName>
                <MUI.DefaultSpan style={{color: getTheme().colorFont}}>
                    {t("areYouSure")}
                </MUI.DefaultSpan>
            </UI.CardAboutName>
            <GUI.DefaultBlockFlex>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => removeTemplate()}> {t("delete")}
                </MUI.DefaultButton>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => setModalActive(!modalActive)}> {t("cancel")}
                </MUI.DefaultButton>
            </GUI.DefaultBlockFlex>
        </ModalRemove>
    }
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => setNameTemplate(event.target.value)

    return (
        <MUI.MainBlock>
            <ModalItem/>
            <ImportTemplates activeImport={activeImport}
                          setActiveImport={setActiveImport} id={id}/>
            <MUI.DefaultAlgorithm>
                <GUI.TopBlockAlgorithm>
                    <GUI.DefaultBlock>
                        <MUI.TopBlockAlgorithmSettings>
                            <MUI.TopBlockAlgorithmTopPart>
                                <AboutBlock name='Settings'/>
                                <GUI.TopBlockAlgorithmButtons>
                                    <GUI.BlockAlgorithmButton
                                        type="button"
                                        onClick={() => getAlgorithmData()}>
                                        <MUI.CardButtonButtonSVG
                                            src="/images/Export.svg"
                                            title="Export core"
                                            alt="Reload Algorithm"/>
                                    </GUI.BlockAlgorithmButton>
                                    <GUI.BlockAlgorithmButton
                                        type="button"
                                        onClick={() => setActiveImport(true)}>
                                        <MUI.CardButtonButtonSVG
                                            src="/images/Import.svg"
                                            title="Import core"
                                            alt="Reload Algorithm"/>
                                    </GUI.BlockAlgorithmButton>
                                    <GUI.BlockAlgorithmButton
                                        type="button"
                                        onClick={() => showModal()}>
                                        <MUI.CardButtonButtonSVG
                                            src="/images/Remove.svg"
                                            alt="Remove Algorithm"/>
                                    </GUI.BlockAlgorithmButton>
                                </GUI.TopBlockAlgorithmButtons>
                            </MUI.TopBlockAlgorithmTopPart>
                        </MUI.TopBlockAlgorithmSettings>
                        <GUI.SecondBlockAlgorithmMainInfo>
                            <MUI.InputStep color={getTheme().inputColor}
                                           style={{width: "200px", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                           type="text" placeholder={template.name} maxLength={15}
                                           {...register('macro.name', {
                                               value: template.name,
                                               onChange: nameChange
                                           })}
                            />
                        </GUI.SecondBlockAlgorithmMainInfo>
                    </GUI.DefaultBlock>
                </GUI.TopBlockAlgorithm>
            </MUI.DefaultAlgorithm>
            <TemplateCore nameMacr={nameTemplate}/>
        </MUI.MainBlock>
    )
}