import React, {ChangeEvent, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'
import * as UI from '../LoginPage/style'
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {userAPI} from "../../services/UserService";
import {useForm} from "react-hook-form";
import * as GUI from "../style";
import {useTranslation} from "react-i18next";
import {getTheme} from "../../index";
export const RegisterUser = ({token} : any) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [passwordMatch, setPasswordMatch] = useState('')
    const [email, setEmail] = useState('')
    const [loginDirty, setLoginDirty] = useState(false)
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [loginError, setLoginError] = useState('Login can not be empty!')
    const [passwordError, setPasswordError] = useState('Password can not be empty!')
    const [numbPass, setNumbPass] = useState('At least one number')
    const [upperPass, setUpperPass] = useState('One uppercase letter')
    const [lowerPass, setLowerPass] = useState('One lowercase letter')
    const [minPass, setMinPass] = useState('Minimum 8 characters')
    const [formValid, setFormValid] = useState(false)
    const { handleSubmit } = useForm()
    // eslint-disable-next-line no-empty-pattern
    const [createUser] = userAPI.useCreateUserMutation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const User = {
        username: login,
        password: password,
        email: email
    }
    const createUserSubmit = () => {
        if (password !== passwordMatch) {
            ErrorNotify("Passwords doesn't match")
        } else {
            createUser(User).then((data) => {
                if (data.hasOwnProperty('error')) {
                    ErrorNotify('An error has occurred!')
                } else {
                    SuccessNotify('Registration successful!')
                    navigate('/login')
                }
            })
        }
    }
    const loginChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLogin(event.target.value)
        event.target.value.length === 0 ? setLoginError('Login can not be empty!') : setLoginError('')
    }
    const emailChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)
    const passwordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
        const min1numb = /(?=.*[0-9])/g
        const minLowLet = /(?=.*[a-z])/g
        const minUppLet = /(?=.*[A-Z])/g
        event.target.value.length === 0 ? setPasswordError('Password can not be empty!')
            : setPasswordError(''); setPasswordDirty(true)

        event.target.value.length < 8 ? setMinPass('Minimum 8 characters')
            : setMinPass(''); setPasswordDirty(true)

        !min1numb.test(String(event.target.value)) ? setNumbPass('At least one number')
            : setNumbPass(''); setPasswordDirty(true)

        !minLowLet.test(String(event.target.value)) ? setLowerPass('One lowercase letter')
            : setLowerPass(''); setPasswordDirty(true)

        !minUppLet.test(String(event.target.value)) ? setUpperPass('One uppercase letter')
            : setUpperPass(''); setPasswordDirty(true)
    }
    const passwordMatchChange = (event: ChangeEvent<HTMLInputElement>) => setPasswordMatch(event.target.value)
    const blurHandler = (e:any) => {
         switch (e.target.name) {
             case "login" :
                 setLoginDirty(true)
                 break
             case 'password':
                 setPasswordDirty(true)
                 break
         }
    }
    useEffect(() => {
        if (loginError || passwordError || upperPass || lowerPass || numbPass || minPass) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        if (token) {
            navigate('/algorithms')
        }
    }, [login, password, User, loginError, passwordError, upperPass, lowerPass, numbPass, minPass, token, navigate])

    return (
        <GUI.DefaultBlock>
            <GUI.RegisterLoginBlock>
                <GUI.AboutBlockCenter>{t("SignUp")}</GUI.AboutBlockCenter>
                <UI.LoginPage>
                    <UI.InputDataBlock>
                        <UI.LoginForm onSubmit={handleSubmit(createUserSubmit)}>
                            <UI.InputBlock>
                                <UI.LabelItem style={{color: "black"}}>{t("LogIn")}</UI.LabelItem>
                                <UI.InputItem type="text" placeholder="John"
                                              value={login}
                                              name="login" required
                                              onChange={(e) => loginChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                {(loginDirty && loginError) && <div style={{color: 'red'}}>{loginError}</div>}
                                <UI.LabelItem style={{color: "black"}}>{t("Email")}</UI.LabelItem>
                                <UI.InputItem type="email" placeholder="algorithms@example.com"
                                              value={email}
                                              name="email" required
                                              onChange={(e) => emailChange(e)}/>
                                <UI.LabelItem style={{color: "black"}}>{t("Password")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={password}
                                              name="password" required
                                              onChange={(e) => passwordChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                <UI.LabelItem style={{color: "black"}}>{t("ConfirmPassword")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={passwordMatch}
                                              name="password" required
                                              onChange={(e) => passwordMatchChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                {(passwordDirty && passwordError) && <div style={{color: 'red'}}>{passwordError}</div>}
                            </UI.InputBlock>
                            <UI.InputBlock>
                                {(!passwordDirty || numbPass) && <UI.InfoPasswordGrey>✖ {numbPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !numbPass) && <UI.InfoPasswordGreen>✔ At least one number</UI.InfoPasswordGreen>}
                                {(!passwordDirty || upperPass) && <UI.InfoPasswordGrey>✖ {upperPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !upperPass) && <UI.InfoPasswordGreen>✔ One uppercase letter</UI.InfoPasswordGreen>}
                                {(!passwordDirty || lowerPass) && <UI.InfoPasswordGrey>✖ {lowerPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !lowerPass) && <UI.InfoPasswordGreen>✔ One lowercase letter</UI.InfoPasswordGreen>}
                                {(!passwordDirty || minPass) && <UI.InfoPasswordGrey>✖ {minPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !minPass) && <UI.InfoPasswordGreen>✔ Minimum 8 characters</UI.InfoPasswordGreen>}
                            </UI.InputBlock>
                            <GUI.DefaultButton width="385px" height="32px" style={{marginRight: "10px"}}
                                               color={getTheme().lightStyleBackground}
                                               background={getTheme().darkGreen}
                                               type="submit"> {t("Register")}
                            </GUI.DefaultButton>
                            <hr/>
                            <span style={{color: "black"}}>
                                {t("AlreadyHaveAcc")}
                                <UI.ItemLink style={{color: "#29C570"}} to="/login"> {t("Login")}</UI.ItemLink>
                            </span>
                        </UI.LoginForm>
                    </UI.InputDataBlock>
                </UI.LoginPage>
            </GUI.RegisterLoginBlock>
        </GUI.DefaultBlock>
    )
}