import React, {ChangeEvent, useState} from 'react';
import * as GUI from "../styles";
import * as UI from "../style";
import {TopAlgorithmSettings} from "../Blocks/Buttons";
import {DragDropContext} from "@hello-pangea/dnd";
import {Core} from "../Core";
import {templatesAPI} from "../../services/LayoutsService";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useTranslation} from "react-i18next";
import {useFieldArray, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";

export const TemplateCreate = () => {
    const { t } = useTranslation();
    const [name, setName] = useState<string>('default Name')
    const {control, handleSubmit, register, setValue} = useForm({
        defaultValues: {
            list: [
                {home: 'Home'},
                {click_to_path: 'Click to path'},
                {input_text: 'Input text'},
            ]
        }
    })
    const {fields, insert, remove, update, move} = useFieldArray({control, name: "list"})
    const navigate = useNavigate()
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.length === 15){
            ErrorNotify("Algorithm shouldn't exceed 15 characters")
        }
        setName(event.target.value)
    }
    const {data: templates} = templatesAPI.useGetAllTemplatesQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const [createTemplate] = templatesAPI.useCreateTemplateMutation()
    const getCreateTemplate = async (newData: object) => {
        const data = {
            "name": name,
            "user_id": 1,
            "sub_core": newData
        }
        try {
            await createTemplate(data)
            SuccessNotify('Template created! Gratz')
            navigate('/templates')
        } catch (error) {
            ErrorNotify(`${error}`)
            navigate('/templates')
        }
    }

    const handleDrag = ({ source, destination }:any) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };
    return (
        <GUI.ContainerMainBlock>
            <UI.FormAlgorithm onSubmit={handleSubmit(getCreateTemplate)}>
                <GUI.TopBlockAlgorithm>
                    <GUI.DefaultBlock>
                        <TopAlgorithmSettings />
                        <GUI.SecondBlockAlgorithmMainInfo>
                            <GUI.SecondBlockAlgorithmInputName
                                type="text" placeholder="Name"
                                name="name"
                                onChange={nameChange}
                                maxLength={25}/>
                        </GUI.SecondBlockAlgorithmMainInfo>
                    </GUI.DefaultBlock>
                </GUI.TopBlockAlgorithm>
                <GUI.DefaultBlock>
                    <UI.StructureAlgorithmBlock>
                        <GUI.AboutBlock>
                            {t("Structure")}
                        </GUI.AboutBlock>
                        <DragDropContext onDragEnd={handleDrag}>
                            <Core
                                fields={fields} insert={insert} remove={remove}
                                register={register} control={control}
                                setValue={setValue} update={update} macro={templates}/>
                        </DragDropContext>
                    </UI.StructureAlgorithmBlock>
                </GUI.DefaultBlock>
            </UI.FormAlgorithm>
        </GUI.ContainerMainBlock>
    )
}