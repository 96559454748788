import React, {useState} from "react";
import "./Modal.css"
import * as GUI from "../styles";
import * as UI from "../AlgorithmList/style";
import {useForm} from "react-hook-form";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useTranslation} from "react-i18next";
import {algorithmAPI} from "../../services/AlgorithmService";
import {useNavigate} from "react-router-dom";
import {macroAPI} from "../../services/MacroService";
import * as MUI from "../style"
import {getTheme} from "../../index";
import {templatesAPI} from "../../services/LayoutsService";
export const Modal = ({active, setActive, children}:any) => {
    return (
        <GUI.DivBlock className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <GUI.DivBlock style={{background: getTheme().background}}  className={active ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                {children}
            </GUI.DivBlock>
        </GUI.DivBlock>
    );
};

export const ModalRemove = ({active, setActive, children}:any) => {
    return (
        <GUI.DivBlock className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <GUI.DivBlock style={{background: getTheme().background}}  className={active ? "modal__remove active" : "modal__remove"} onClick={e => e.stopPropagation()}>
                {children}
            </GUI.DivBlock>
        </GUI.DivBlock>
    );
};

export const ModalGetGoogleSheetsData = ({active, setActive, children}: any) => {
    return (
        <GUI.DivBlock className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <GUI.DivBlock style={{background: getTheme().background}}   className={active ? "modal__getGoogleData active" : "modal__getGoogleData"} onClick={e => e.stopPropagation()}>
                {children}
            </GUI.DivBlock>
        </GUI.DivBlock>
    )
}

export const ImportMacros = ({activeImport, setActiveImport, id}: any) => {
    const { register, handleSubmit } = useForm();
    const [importMacro] = macroAPI.useImportMacroMutation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [uploadFile, setUploadFile] = useState('choose')
    const [file, setFile] = useState()
    const importAlgorithm = async () => {
        try {
            const request = {
                secret: file,
                id: id
            }
            await importMacro(request)
                .then(() => SuccessNotify('Import was successful'))
                .then(() => navigate(0))
        } catch (error) {
            ErrorNotify(`Error: ${error}`)
        }
    }
    const handleChange = (event: any) => {
        let fileReader = new FileReader();
        // Specify what the FileReader should do on the successful read of a file
        fileReader.onload = (event: any) => {
            // If successfully read, resolve the Promise with JSON parsed contents of the file
            setFile(event.target.result)
        };
        fileReader.readAsText(event.target.files[0]);
        try {
            setUploadFile(event.target.files[0].name)
        } catch (e) {
            ErrorNotify(`Error: ${e}`)
        }
    };
    return (
        <GUI.DefaultBlock className={activeImport ? "modal active" : "modal"} onClick={() => setActiveImport(false)}>
            <GUI.DefaultBlock style={{background: getTheme().background}}  className={activeImport ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                <GUI.FormModal onSubmit={handleSubmit(importAlgorithm)} encType="multipart/form-data">
                    <UI.CardAboutName>
                        <MUI.DefaultSpan>
                            {t("ChooseImportFile")}
                        </MUI.DefaultSpan>
                    </UI.CardAboutName>
                    <MUI.DefaultLabel>
                        <GUI.InputFile
                            type="file"
                            {...register("file")}
                            onChange={handleChange}
                            accept=".zip"
                        />
                        <GUI.SVGinButton
                            src="/images/Import.svg"
                            alt="Upload"/>
                        <MUI.DefaultSpan>{uploadFile}</MUI.DefaultSpan>
                    </MUI.DefaultLabel>
                    <UI.ModalButtonSubmitButton
                        type="submit">
                        {t("Upload")}
                    </UI.ModalButtonSubmitButton>
                </GUI.FormModal>
            </GUI.DefaultBlock>
        </GUI.DefaultBlock>
    )
}

export const ImportTemplates = ({activeImport, setActiveImport, id}: any) => {
    const { register, handleSubmit } = useForm();
    const [ importTemplate ] = templatesAPI.useImportTemplateMutation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [uploadFile, setUploadFile] = useState('choose')
    const [file, setFile] = useState()
    const importAlgorithm = async () => {
        try {
            const request = {
                secret: file,
                id: id
            }
            await importTemplate(request)
                .then(() => SuccessNotify('Import was successful'))
                .then(() => navigate(0))
        } catch (error) {
            ErrorNotify(`Error: ${error}`)
        }
    }
    const handleChange = (event: any) => {
        let fileReader = new FileReader();
        // Specify what the FileReader should do on the successful read of a file
        fileReader.onload = (event: any) => {
            // If successfully read, resolve the Promise with JSON parsed contents of the file
            setFile(JSON.parse(event.target.result))
        };
        fileReader.readAsText(event.target.files[0]);
        try {
            setUploadFile(event.target.files[0].name)
        } catch (e) {
            ErrorNotify(`Error: ${e}`)
        }
    };
    return (
        <GUI.DefaultBlock className={activeImport ? "modal active" : "modal"} onClick={() => setActiveImport(false)}>
            <GUI.DefaultBlock style={{background: getTheme().background}}  className={activeImport ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                <GUI.FormModal onSubmit={handleSubmit(importAlgorithm)} encType="multipart/form-data">
                    <UI.CardAboutName>
                        <MUI.DefaultSpan>
                            {t("ChooseImportFile")}
                        </MUI.DefaultSpan>
                    </UI.CardAboutName>
                    <MUI.DefaultLabel>
                        <GUI.InputFile
                            type="file"
                            {...register("file")}
                            onChange={handleChange}
                            accept=".zip"
                        />
                        <GUI.SVGinButton
                            src="/images/Import.svg"
                            alt="Upload"/>
                        <MUI.DefaultSpan>{uploadFile}</MUI.DefaultSpan>
                    </MUI.DefaultLabel>
                    <UI.ModalButtonSubmitButton
                        type="submit">
                        {t("Upload")}
                    </UI.ModalButtonSubmitButton>
                </GUI.FormModal>
            </GUI.DefaultBlock>
        </GUI.DefaultBlock>
    )
}
export const ImportAlgorithm = ({activeImport, setActiveImport, id}: any) => {
    const { register, handleSubmit } = useForm();
    const [importAlg] = algorithmAPI.useImportAlgorithmMutation()
    const [putAlgorithm] = algorithmAPI.useUpdateDataAlgorithmMutation()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [uploadFile, setUploadFile] = useState('choose')
    const [file, setFile] = useState<any>()
    const importAlgorithm = async () => {
        try {
            switch(id) {
                case 0:
                    const requestWithoutId = {
                        data: file,
                    }
                    await importAlg(requestWithoutId)
                        .then(() => SuccessNotify('Import was successful'))
                        // .then(() => navigate(0))
                        .catch((e) => console.log('catch', e))
                    break
                default:
                    const request = {
                        id: id,
                        data: file,
                    }
                    await putAlgorithm(request)
                        .then(() => SuccessNotify('Update core was successful'))
                        // .then(() => navigate(0))
                        .catch((e) => console.log('catch', e))
            }
            // const request = {
            //     data: file,
            //     id: id
            // }
            // await importAlg(request)
            //     .then(() => SuccessNotify('Import was successful'))
            //     .then(() => navigate(0))
            //     .catch((e) => console.log('catch', e))
        } catch (error) {
            ErrorNotify(`Error: ${error}`)
        }
    }
    const handleChange = (event: any) => {
       try {
           let fileReader = new FileReader();
           fileReader.onload = (event: any) => {
               setFile(event.target.result)
           };
           fileReader.readAsArrayBuffer(event.target.files[0]);
           try {
               setUploadFile(event.target.files[0].name)
           } catch (e) {
               ErrorNotify(`Error: ${e}`)
           }
       } catch (e) {
           console.log('error here', e)
       }
    };
    return (
        <GUI.DivBlock className={activeImport ? "modal active" : "modal"} onClick={() => setActiveImport(false)}>
            <GUI.DivBlock style={{background: getTheme().background}}  className={activeImport ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
                <GUI.FormModal onSubmit={handleSubmit(importAlgorithm)} encType="multipart/form-data">
                    <UI.CardAboutName>
                        <MUI.DefaultSpan style={{color: getTheme().colorFont}}>
                            {t("ChooseImportFile")}
                        </MUI.DefaultSpan>
                    </UI.CardAboutName>
                    <MUI.DefaultLabel style={{border: `2px solid ${getTheme().colorFont}`}}>
                        <GUI.InputFile
                            type="file"
                            {...register("file")}
                            onChange={handleChange}
                            accept=".zip"
                        />
                        <GUI.SVGinButton
                            src="/images/Import.svg"
                            alt="Upload"/>
                        <MUI.DefaultSpan style={{color: getTheme().colorFont}}>{uploadFile}</MUI.DefaultSpan>
                    </MUI.DefaultLabel>
                    <MUI.DefaultButton width="140px" height="40px"
                                       color={getTheme().colorFontModal}
                                       background={getTheme().colorFont}
                                       type="submit"> {t("Upload")}
                    </MUI.DefaultButton>
                </GUI.FormModal>
            </GUI.DivBlock>
        </GUI.DivBlock>
    )
}
export const ModalShowGoogleSheetsData = ({active, setActive, children}: any) => {
    if (children === undefined) {
        return(
            <GUI.DivBlock className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
                <GUI.DivBlock style={{background: getTheme().background}}  className={active ? "modal__getGoogleData active" : "modal__getGoogleData"} onClick={e => e.stopPropagation()}>
                    Loading
                </GUI.DivBlock>
            </GUI.DivBlock>
        )
    }

    return (
        <GUI.DivBlock className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
            <GUI.DivBlock style={{background: getTheme().background}}  className={active ? "modal__showGoogleData active" : "modal__showGoogleData"} onClick={e => e.stopPropagation()}>
                {children}
            </GUI.DivBlock>
        </GUI.DivBlock>
    )
}

export const ModalBankCode = ({modalBankActive, setModalBankActive, children}: any) => {
    return (
        <GUI.DivBlockBank className={modalBankActive ? "modal active" : "modal"} onClick={() => setModalBankActive(false)}>
            <GUI.DivBlockBank style={{background: getTheme().background}}  className={modalBankActive ? "modal__getGoogleData active" : "modal__getGoogleData"} onClick={e => e.stopPropagation()}>
                {children}
            </GUI.DivBlockBank>
        </GUI.DivBlockBank>
    )
}