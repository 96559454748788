import {IAlgorithmCore} from "../interfaces/Algorithm";

export const JustDescription : string[] = [
    "locate_path", "input_text", "sleep", "put_sms",
    "click_coords", "click_unnecessary", "neuro_input_text", "open_app",
    "delete_app", "click_to_path", "click_to_image", "copy_data", "paste_data",
    "sms_service_number", "sms_service_code", "anti_captcha", "click_to_image_unnecessary", "checker", "go_to",
    "open_link", "enable_vpn", "disable_vpn", "close_app", "save_phone", "delete_phone_book", "transfer_images",
    "take_free_number", "set_proxy"
]
export const WithoutDescription : string[] = [
    "reboot", "swipe_up", "swipe_down", "home", "collapse_keyboard", "clean_field", "reset_all",
    "ok_enter", "put_bankcode", "neuro_put_code", "close_apps", "take_warmed_number", "disable_proxy"
]

export const SelectDescription : string[] = [
    "macros"
]




export const DescriptionWithType : IAlgorithmCore | any = {
    click_to_path: 'Click to path',
    click_to_image: 'Click to image',
    input_text: 'Input text',
    sleep: 'Sleep',
    put_sms: 'SMS',
    reboot: 'Reboot',
    swipe_up: 'Swipe up',
    swipe_down: 'Swipe down',
    home: 'Home',
    click_coords: 'Click Coords',
    click_unnecessary: 'Click unnecessary',
    neuro_input_text: 'Neuro input text',
    collapse_keyboard: 'Collapse keyboard',
    clean_field: 'Clean Field',
    close_app: 'Close App',
    reset_all: 'Reset All',
    ok_enter: 'Ok/Enter',
    open_app: 'Open App',
    put_bankcode: 'Put code',
    macros: "Macros",
    delete_app: "Delete App",
    copy_data: "Copy data",
    paste_data: "Paste data",
    sms_service_number: "SMS Service number",
    sms_service_code: "SMS Service code",
    anti_captcha: "Anti Captcha",
    neuro_put_code: "Neuro put code",
    click_to_image_unnecessary: "Unnecessary Click Image",
    checker: "Checker",
    go_to: "Go to",
    open_link: "Open link",
    disable_vpn: "Disable VPN",
    enable_vpn: "Enable VPN",
    close_apps: 'Close apps',
    save_phone: "Save phone",
    transfer_images: "Transfer images",
    delete_phone_book: "Delete phone book",
    take_free_number: "Take free number",
    take_warmed_number: "Take warmed number",
    set_proxy: "Set proxy",
    disable_proxy: "Disable proxy",
}

export const DataJson : string[] = [
    "auth_provider_x509_cert_url", "auth_uri", "client_email", "client_id", "client_x509_cert_url", "private_key",
    "private_key_id", "project_id", "token_uri", "type"
]
