import React from 'react';
import * as UI from "../style";
import {useNavigate} from "react-router-dom";
import {getTheme} from "../../index";
export const MainPage = () => {
    const navigate = useNavigate()
    return (
        <UI.DefaultBlock>
            <UI.MainBlock>
                <UI.FlexBlock>
                    <UI.LeftBlockMainContent>
                        <UI.H1>Headline</UI.H1>
                        <UI.P>
                            Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the
                            1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </UI.P>
                        <UI.DefaultButton color={getTheme().greenLight}
                                          background={getTheme().darkGreen}
                                          type="button" onClick={() => navigate('/algorithms')} width="300px" height="48px">
                            Start using
                        </UI.DefaultButton>
                    </UI.LeftBlockMainContent>
                    <UI.RightBlockMainContent>
                        <UI.AnimationSVG
                            src="/images/AnimationMain.svg"
                            alt="Animation"/>
                    </UI.RightBlockMainContent>
                </UI.FlexBlock>
            </UI.MainBlock>
           <UI.MainBlock>
               <UI.AboutBlockCenter>
                   How it works?
               </UI.AboutBlockCenter>
               <UI.FlexBlockColumnMobile>
                   <UI.LeftBlockContent>
                       <UI.P>
                           Lorem Ipsum is simply dummy text of the printing and typesetting
                           industry. Lorem Ipsum has been the industry's
                           standard dummy text ever since the 1500s,
                           when an unknown printer took a galley of type
                           and scrambled it to make a type specimen book.
                       </UI.P>
                       <UI.AboutMiniPart>Step 1</UI.AboutMiniPart>
                       <UI.P>
                           Lorem Ipsum is simply dummy text of the
                           printing and typesetting industry.
                       </UI.P>
                       <UI.AboutMiniPart>Step 2</UI.AboutMiniPart>
                       <UI.P>
                           Lorem Ipsum is simply dummy text of the
                           printing and typesetting industry.
                       </UI.P>
                       <UI.AboutMiniPart>Step 3</UI.AboutMiniPart>
                       <UI.P>
                           Lorem Ipsum is simply dummy text of the
                           printing and typesetting industry.
                       </UI.P>
                   </UI.LeftBlockContent>
                   <UI.RightBlockContent>
                       <UI.Frame src="https://www.youtube.com/embed/yd1JhZzoS6A"
                                 title="YouTube video player" frameBorder="0"
                                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                 allowFullScreen></UI.Frame>
                   </UI.RightBlockContent>
               </UI.FlexBlockColumnMobile>
           </UI.MainBlock>
        </UI.DefaultBlock>
    )
}