import React, {useRef, useState, useEffect} from 'react';
import * as GUI from "../styles";
import {
    BlockInput,
    BlockSelect,
    ButtonAddInList,
    ButtonsDropdown,
    ButtonTrash,
    StructureItem,
} from "../Blocks/Buttons";
import {
    DescriptionWithType,
    JustDescription,
    SelectDescription,
    WithoutDescription
} from "../../types/AlgorithmCore";
import * as UI from "../style"
import {getTheme} from "../../index";
import {Draggable, Droppable} from '@hello-pangea/dnd';
import {ErrorNotify} from "../../Notifies/Notifications";
import * as MUI from "../style";
import {updateForAdd, updateForRemove} from "../../Validators/updaterForCheckerAndGoto";

interface ICount {
    step: string | number,
    doubleStep: string
}

export const Core = ({fields, insert, remove, register, control, setValue, update, macro}: any) => {

    const clearArray = ['']
    const fullDescriptionList = [...JustDescription, ...SelectDescription, ...WithoutDescription]
    const [doubleCount, setDoubleCount] = useState<ICount[]>([])

    useEffect(() => {
        let counter = 0
        const doubleCounter: ICount[] = []
        fields.map((item: any, index: number) => {
            if (Object.keys(item)[0] === 'macros') {
                const macroLength = macro?.find((mac: any) => mac.id == +item.macros)?.sub_core['list']
                doubleCounter.push({step: index, doubleStep: `${counter} - ${counter + macroLength?.length - 1}`})
                counter += macroLength?.length

            } else {
                doubleCounter.push({step: index, doubleStep: `${counter}`})
                counter++
            }
        })
        setDoubleCount(doubleCounter)
    }, [fields, setDoubleCount, macro])
    useEffect(() => {
    }, [doubleCount])
    return (
        <Droppable droppableId="characters">
            {(provided) => (
                <UI.BlockCores {...provided.droppableProps} ref={provided.innerRef}>
                    {fields && fields.map((item: any, index: number) => {
                        const ButtonSelect = (type: any) => {
                            const [open, setOpen] = useState<boolean>(false);
                            const dropdownRef = useRef<HTMLDivElement>(null);
                            useEffect(() => {
                                const handleClickOutside = (event: MouseEvent) => {
                                    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                                        setOpen(false);
                                    }
                                };
                                document.addEventListener('click', handleClickOutside);
                                return () => {
                                    document.removeEventListener('click', handleClickOutside);
                                };
                            }, [dropdownRef]);
                            return (
                                <GUI.BlockButton>
                                    <div className="app-drop-down-container" ref={dropdownRef}>
                                        <GUI.BlockAlgorithmButton type="button" onClick={() => setOpen(!open)}>
                                            <ButtonAddInList onClick={() => setOpen(!open)} type={type} />
                                        </GUI.BlockAlgorithmButton>
                                        {open && <ButtonsDropdown index={index} insert={insert} type={type} fields={fields} setValue={setValue}/>}
                                    </div>
                                </GUI.BlockButton>
                            );
                        }
                        for (let step of fullDescriptionList) {
                            if (typeof item[step] === 'string') {
                                let checkerStatus = false
                                if(item.checker) {
                                    const splitChecker = item.checker.split('')
                                    const indexSlash = splitChecker.indexOf('/')
                                    const numberOfChecker = splitChecker.slice(indexSlash + 1).join('')
                                    if (numberOfChecker === '-1') checkerStatus = true
                                }
                                if (item.go_to === '-1') checkerStatus = true
                                return (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided) => (
                                            <GUI.StructureCore {...provided.dragHandleProps} {...provided.draggableProps}
                                                               ref={provided.innerRef}>
                                                <GUI.StructureCoreTopBlock
                                                    style={{color: getTheme().colorFont,
                                                        background: getTheme().inputBackground,
                                                        border: checkerStatus ? '3px solid red' : ''}}>
                                                    <GUI.StructureCoreTopBlockBlocks>
                                                        <MUI.CardButtonSVGMini
                                                            src="/images/StructureItem.svg"
                                                            alt="Structure Item"/>
                                                        <GUI.StructureCoreAboutText>
                                                            #{index} | {doubleCount[index]?.doubleStep} | {DescriptionWithType ?
                                                            DescriptionWithType[step] : 'None'}
                                                        </GUI.StructureCoreAboutText>
                                                    </GUI.StructureCoreTopBlockBlocks>
                                                    <GUI.StructureCoreTopBlockBlocks>
                                                        <GUI.ButtonList>
                                                            <ButtonSelect type={'click'}/>
                                                            <ButtonSelect type={'text'}/>
                                                            <ButtonSelect type={'system'}/>
                                                            <ButtonSelect type={'apps'}/>
                                                            <ButtonSelect type={'whatsapp'}/>
                                                        </GUI.ButtonList>
                                                        <GUI.BlockAlgorithmButton
                                                            type="button"
                                                            onClick={() => {
                                                                if (fields.length > 1) {
                                                                    remove(index)
                                                                    updateForRemove(fields, setValue, index)
                                                                } else {
                                                                    ErrorNotify('The algorithm cannot be less than 1 step')
                                                                }
                                                            }}>
                                                            <ButtonTrash/>
                                                        </GUI.BlockAlgorithmButton>
                                                    </GUI.StructureCoreTopBlockBlocks>
                                                </GUI.StructureCoreTopBlock>
                                                {clearArray && clearArray.map(() => {
                                                    if (JustDescription.includes(step)) {
                                                        return (
                                                            <BlockInput
                                                                key={index} item={item} index={index} step={step} register={register}
                                                                control={control} fields={fields} setValue={setValue}
                                                                update={update}/>
                                                        )
                                                    } else if (SelectDescription.includes(step)) {
                                                        return (
                                                            <BlockSelect item={item} key={index} index={index} step={step} register={register}
                                                                         control={control} fields={fields} setValue={setValue}
                                                                         update={update} macro={macro}/>
                                                        )
                                                    }
                                                })}
                                            </GUI.StructureCore>
                                        )}
                                    </Draggable>
                                )
                            }
                        }
                    })}
                    {provided.placeholder}
                </UI.BlockCores>
            )}
        </Droppable>
    )
}