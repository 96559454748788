import React from 'react';
import { useNavigate } from 'react-router-dom'
import * as UI from '../LoginPage/style'
import {useForm} from "react-hook-form";
import * as GUI from "../style";
import {useTranslation} from "react-i18next";

export const ConfirmReset = () => {
    const { t } = useTranslation();
    const { handleSubmit } = useForm()
    const navigate = useNavigate()
    const submitForm = () => {
        navigate('/login')
    }

    return (
        <GUI.DefaultBlock>
            <GUI.RegisterLoginBlock>
                <GUI.AboutBlockCenter>{t("ResetPassword")}</GUI.AboutBlockCenter>
                <UI.LoginPage>
                    <UI.InputDataBlock>
                        <UI.LoginForm onSubmit={handleSubmit(submitForm)}>
                            <h4>{t("EmailSent")}</h4>
                            <UI.ButtonItem type="submit">{t("Ok")}</UI.ButtonItem>
                        </UI.LoginForm>
                    </UI.InputDataBlock>
                </UI.LoginPage>
            </GUI.RegisterLoginBlock>
        </GUI.DefaultBlock>
    )
}