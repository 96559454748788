import React, {useState, useEffect} from 'react';
import {remoteAPI} from "../../services/RemoteService";
import {Spinner} from "../Spinner";
import Creatable from "react-select";
import {useTranslation} from "react-i18next";
import * as UI from "../style"
export const RemoteList = ({currentComp, setCurrentComp, setRemoteStatus}: any) => {
    const { t } = useTranslation();
    const [isClearable, setIsClearable] = useState(true);
    const { data: remotes, isLoading } =
        remoteAPI.useFetchAllRemotesQuery(undefined, {
            refetchOnMountOrArgChange: true,
            skip: false
        })
    useEffect(() => {}, [remotes])
    if (isLoading) return <Spinner/>
    const getValue = () => {
        return currentComp ? remotes.find((c: any) => c.hostname === currentComp) : ''
    }
    const onChange = (newValue: any) => {
        if (newValue === null) {
            localStorage.setItem('hostname', newValue)
            setCurrentComp(newValue)
            setRemoteStatus(undefined)
        } else {
            localStorage.setItem('hostname', newValue.hostname)
            setCurrentComp(newValue.hostname)
            setRemoteStatus(newValue.status) }
    }
    const Checkbox = ({ children, ...props }: JSX.IntrinsicElements['input']) => (
        <label style={{ marginRight: '1em' }}>
        </label>
    )
    const colourOptions: any = [
        { value: 'ocean', label: 'Ocean', color: '#d2d0d0', isFixed: true }
    ];
    const customStyles = {
        container: (base:any) => ({
            ...base,
            backgroundColor: colourOptions[0].color,
            padding: 1,
            borderRadius: 5
        }),
        option: (styles:any, { data, isDisabled, isFocused, isSelected }:any) => {
            if (!isSelected) {
                if (data.status === 'ready') {
                    return {
                        ...styles,
                        backgroundColor: isFocused ? '#e1ecfd' : 'rgba(197,238,183,0.33)',
                        borderBottom: '1px solid rgba(164,164,164,0.34)',
                        color: "#333333"
                    };
                } else {
                    return {
                        ...styles,
                        backgroundColor: isFocused ? '#e1ecfd' : 'white',
                        borderBottom: '1px solid rgba(164,164,164,0.34)',
                        color: "#333333"
                    };
                }
            } else {
                return {
                    ...styles,
                    backgroundColor: isSelected ? '#4183f7' : 'white',
                    color: "white"
                };
            }
        }
    }
// isOptionDisabled={(option) => option.status !== 'ready'}
    return (
        <UI.RemoteListBlock>
            <UI.DefaultSpan style={{fontWeight: "400", color: "gray"}}>
                {t("RemoteList")}
            </UI.DefaultSpan>
            <Creatable
                isClearable={isClearable}
                getOptionLabel={option => option.hostname}
                getOptionValue={option => option.create_dt}
                onChange={onChange}
                value={getValue()}
                options={remotes}
                styles={customStyles}
            />
            <Checkbox
                checked={isClearable}
                onChange={() => setIsClearable((state) => !state)}
            />
        </UI.RemoteListBlock>
    )
}