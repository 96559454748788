import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery} from "@reduxjs/toolkit/query";
import {_baseUrlAPI} from "./index";

export const templatesAPI = createApi({
    reducerPath: 'templatesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrlAPI,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['Template'],
    endpoints: (builder) => ({
        getAllTemplates: builder.query({
            query: () => ({
                url: 'templates/',
                method: 'GET'
            })
        }),
        createTemplate: builder.mutation<any, any>({
            query: (data) => ({
                url: `templates/`,
                method: 'POST',
                body: data,
            }),
        }),
        getTemplate: builder.query<any, any>({
            query: (id) => ({
                url: `templates/${id}/`,
                method: 'GET',
            }),
        }),
        updateTemplate: builder.mutation<any, any>({
            query: ({data, id}) => ({
                url: `templates/${id}/`,
                method: 'PUT',
                body: data,
            }),
        }),
        deleteTemplate: builder.mutation<any, any>({
            query: (id) => ({
                url: `templates/${id}/`,
                method: 'DELETE',
            }),
        }),
        importTemplate: builder.mutation<any, any>({
            query: (data) => ({
                url: `/templates/${data.id}/import_subcore/`,
                method: `POST`,
                body: data.secret,
            })
        }),
        exportTemplate: builder.query<any, any>({
            query: (id) => ({
                url: `/templates/${id}/export_subcore/`,
                method: `GET`,
                responseHandler: (response: any) => response.blob()
            }),

        })
    })
})