import styled from "styled-components";
import {Link} from "react-router-dom";

const lightDark: string = '#36373B';
const colorDark: string = '#202125';

export const Button = styled.button`
  text-decoration: none;
  color: inherit;
  background: #553AC1;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1em;
  padding: 13px 32px;
  @media(max-width: 535px) {
    font-size: 1em;
    padding: 10px 16px;
  }
  @media(max-width: 390px) {
    font-size: 0.8em;
    padding: 8px 10px;
  }`
export const ContainerMainBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
    margin: 5px;
  }
`

export const ContainerMainList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
// ALGORITHM

export const TopBlockAlgorithm = styled.div`
  margin-top: 2em;
  display: flex;
  justify-content: start;
  flex-direction: row;
  width: 100%;
  @media (max-width: 840px) {
    width: 100%;
    margin: 0;
    max-width: 100%;
    align-items: center;
    flex-direction: column !important;
  }
`
export const DefaultBlock = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 100%;
`
export const RightBlockAlgorithm = styled.div`
  margin-top: 2em;

  @media (max-width: 850px){
    display: none;
  }
  @media (max-width: 600px) {
    margin-bottom: 1em;
  }
`
export const MainBlockAlgorithm = styled.div`
  margin: 1em 0 1em 0;
  width: 100%;
  display: none;

  @media (max-width: 850px) {
    display: flex;
    justify-content: center;
  }
`

// change on VIDEO
export const RightBlockAlgorithmVideo = styled.img`
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 840px;
  box-sizing: border-box;
`
export const RightBlockAlgorithmVideoRow = styled.img`
  max-width: 320px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const RefBlock = styled.div`
  width: 100%;
  margin: 15px 0;
  max-width: 320px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  object-fit: cover;
`
export const MainBlockAlgorithmVideo = styled.img`
  max-width: 700px;
  max-height: 400px;
`


export const TopBlockAlgorithmAbout = styled.span`
  color: inherit;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  font-family: 'Inter', sans-serif;

  @media (max-width: 450px) {
    margin-top: 1em;
  }
`

export const TopBlockAlgorithmButtons = styled.div`
  display: flex;
  justify-content: end;
  align-self: center;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 10px 0 10px 0;
  flex-wrap: wrap;
`

export const BlockButton = styled.div`
  margin-right: 10px;
`
export const BlockAlgorithmButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  @media(max-width: 840px) {
    margin-top: 5px;
  }
`

export const ArrowBlockAlgorithmButton = styled.div`
  display: inline-block;
`

export const TopBlockAlgorithmButtonStyleLink = styled.button`
  background: #553AC1;
  border-radius: 2px;
  padding: 12px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: inherit;
  border: none;
  margin: 0 5px;

  @media only screen and (max-width: 520px) {
    margin: 5px;
  }
`

export const CardButtonButtonSVGSpecial = styled.img`
  width: 24px;
  margin-right: 5px;
`
export const CardButtonButtonSVGBackgroundBlue = styled.img`
  margin-right: 1px;
  border-radius: 50%;
  margin-left: 3px;
  max-width: 46px;
  :hover{
    background: lightgray;
  }
`
export const CardButtonButtonLittleSVG = styled.img`
  margin-right: 1px;
  width: 34px;
`
export const SecondBlockAlgorithmMainInfo = styled.div`
  margin-top: 2em;
  color: inherit;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 2em;
`

export const BlockForInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
`
export const BlockForInputSelect = styled.div`
  display: flex;
  margin-top: -25px;
`
export const SecondBlockAlgorithmInputName = styled.input`
  width: 100%;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  border: none;
  margin: 0;
  height: 40px;
  padding-left: 1em;
  box-sizing: border-box;
`

export const SecondBlockAlgorithmSelectTextArea = styled.select`
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  margin-top: 2em;
  height: 40px;
  padding-left: 1em;
  box-sizing: border-box;
`
export const ThirdBlockAlgorithm = styled.div`
  margin-top: 10px;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;

  @media (max-width: 450px) {
    justify-content: center;
  }


`

// BLOCK CORE
export const BlockNumbers = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: start;
  @media (max-width: 1260px) {
    flex-direction: column;
    width: 100%;
  }
`
export const MainBlockNumbers = styled.div`
  padding-top: 2em;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
`
export const StructureNumber = styled.div`
  display: flex;
  white-space: normal;
  justify-content: flex-start;
  padding: 10px;
  max-width: 50%;
  box-sizing: border-box;
`
export const NumberBlock = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 10px 0 0;
`
export const AboutBlock = styled.span`
  color: inherit;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  font-family: 'Inter', sans-serif;
`


export const StructureCore = styled.div`
  margin-top: 0.5em;
  width: 49%;
  @media only screen and (max-width: 520px) {
    width: 100%;
  }
`

export const StructureCoreTopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  height: 30px;
`

export const StructureCoreTopBlockBlocks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`
export const ButtonList = styled.div`
  display: flex;
`
export const StructureCoreAboutText = styled.span`
  color: inherit;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-left: 1em;
`
export const StructureCoreInputText = styled.input`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  border: 1px solid lightgray;
  height: 35px;
  padding-left: 1em;
  margin: 3px 0 0 0;
  box-sizing: border-box;
`
export const StructureCoreInputFile = styled.input`
  width: 100%;
  background: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  border: none;
  height: 35px;
  padding-left: 1em;
  margin: 3px 0px 0 0;
  box-sizing: border-box;
`
export const StructureCoreOptionText = styled.option`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  border: none;
  height: 35px;
  padding-left: 1em;
  margin: 3px 0 0 0;
  box-sizing: border-box;
`


export const FormModal = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`
export const BlockForLists = styled.div`
  width: 40%;

  @media (max-width: 1100px) {
    width: 50%;
  }
  @media (max-width: 900px) {
    width: 60%;
  }
  @media (max-width: 700px) {
    width: 70%;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`

export const TableList = styled.table`
  width: 100%;
  text-align: center;
  border: 1px solid;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
`

export const TableTR = styled.tr`

`
export const TableTHID = styled.th`
  border-bottom: 3px solid black;
  font-size: 22px;
  padding-bottom: 5px;
  width: 30%;
`
export const TableTHText = styled.th`
  border-bottom: 3px solid black;
  font-size: 22px;
  padding-bottom: 5px;
`
export const TableTD = styled.td`
  font-size: 18px;
  background: #F5F5F9;
  margin: 3px;
`

export const TableLink = styled(Link)`
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  text-decoration: none;
  color: inherit;
`

export const LinkBlue = styled(Link)`
  background: #553AC1;
  border-radius: 2px;
  padding: 12px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: inherit;
  border: none;
  width: 100%;
  text-align: center;
  text-decoration: none;
  margin: 10px;
`

export const CreateMain = styled.div`
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3px;
`

export const CreateMini = styled.div`
  display: flex;
  justify-content: space-between;
`

export const InputNumber = styled.input`
  width: 34.33%;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  border: none;
  height: 45px;
  padding-left: 1em;
  margin: 3px 2px 0 2px;
  box-sizing: border-box;
`


export const ButtonBlue = styled.button`
  background: #553AC1;
  border-radius: 2px;
  padding: 12px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: inherit;
  border: none;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  margin: 10px;
`

export const ButtonWhite = styled.button`
  background: whitesmoke;
  border-radius: 2px;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: inherit;
  border: none;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
`

export const InputTextMini = styled.input`
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  border: none;
  height: 45px;
  padding-left: 1em;
`

export const InputBankSms = styled.input`
  width: 55%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  border: none;
  height: 45px;
  padding-left: 1em;
  text-align: center;
  :focus{
    border: none;
    outline: none;
  }
  :active{
    border: none;
  }
`

export const ButtonImage = styled.button`
  background: none;
  color: inherit;
  border: none;
  width: 36px;
  height: 100%;
`
export const SVGLittle = styled.img`
  width: 24px;
`
export const BlockForInputBank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 50%;
  border-radius: 5px;
  margin: 10px;
`
export const Select = styled.select`
  width: 100%;
  background-color: white;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  margin: 10px 0 10px 0;
`
export const Option = styled.option`
  width: 100%
`

export const MainTable = styled.div`
  border: solid black 1px;
  margin-top: 1em;
`

export const Table = styled.div`
  text-align: right;
  border-collapse: collapse;
`

export const TableMain = styled.div`
  display: inline-block;
  font-weight: bold;
  text-align: center;
  border: 1px solid black;
  width: 150px;
  height: 30px;
`
export const TableIndex = styled.div`
  text-align: center;
  border: 1px solid black;
  width: 150px;
  height: 25px;
`
export const TableTelephone = styled.div`
  justify-content: center;
  text-align: center;
  border: 1px solid black;
  width: 150px;
  height: 25px;
`
export const TableInl = styled.div`
  display: inline-block;

`
export const ButtonInput = styled.button`
  background: #553AC1;
  border-radius: 2px;
  margin-top: 3px;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: inherit;
  border: none;
`
export const ButtonSelect = styled.select`
  background: #553AC1;
  border-radius: 2px;
  margin-top: 3px;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: inherit;
  border: none;
`
export const BlockSelect = styled.select`
  background: #553AC1;
  border-radius: 2px;
  margin-top: 3px;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: inherit;
  border: none;
`
/* ------------------------------------------------------------------- */
export const DivBlock = styled.div`
  
`
export const DefaultBlockFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const DivBlockBank = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
`
export const OpenModal = styled.button`
  border: 1px solid black;
`
export const InputTextOnWhite = styled.input`
  border-radius: 5px;
  width: 95%;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: inherit;
`
export const AboutInput = styled.h3`

`
export const AboutInstruction = styled.p<{color: string}>`
  padding-bottom: 3px;
  font-weight: bold;
  color: ${(props) => props.color};
`
export const DefaultLabelInCore = styled.label`
  background: #553AC1;
  color: inherit;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 3px 0 0 0;
  box-sizing: border-box;
  :hover{
    cursor: pointer;
  }
`
export const InputFile = styled.input`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
`

export const SVGinButton = styled.img`
  background: whitesmoke;
  color: white;
  border-radius: 50%;
  margin: 10px;
  width: 36px;
  height: 36px;
`

export const FlexBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TableData = styled.table`
  border: 1px solid black;
  border-radius: 5px;
  tbody{
    tr{
      border-top: 1px solid black;
      font-size: 14px;
      display: flex;
      justify-content: center;
      text-align: center;
      td{
        span{
          font-weight: 500;
        }
        tr{
          overflow: hidden;
          white-space: nowrap;
        }
        :nth-of-type(2n){
          background: gainsboro;
        }
      }
    }
  }
`

export const AboutProfile = styled.div`
    
`

export const AboutProfileUl = styled.ul`
  justify-content: center;
  text-align: center;
  margin: 0;
  position: absolute;
  top: calc(100% + 5px);
  min-width: 100%;
  width: max-content;
  display: grid;
  padding: 0;
  box-shadow: 0 0 1px #6e6e6e;
  list-style: none;
  z-index: 1;
`

export const NavMenu = styled.ul<{ height: string, background: any}>`
  display: block;
  right: 0;
  top: 0;
  padding: 105px 35px 35px 35px;
  transition: ease 1.5s;
  overflow: hidden;
  max-height: ${(props: any) => props.height};
  position: absolute;
  background-color: ${(props) => props.background};
  backdrop-filter: blur(20px);
  z-index: 1000;
  width: 400px;
  @media only screen and (max-width: 1024px) {
    top: 0;
    right: 0;
    width: 200px;
    transition: ease 0.5s;
    display: block;
    overflow: hidden;
    max-height: ${(props: any) => props.height};
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }
  @media (max-width: 430px) {
    max-width: 50%;
    width: 100%;
  }
`

export const AboutProfileLi = styled.li<{color: string, colorHover: string}>`
  display: flex;
  color: ${(props) => props.color};
  text-align: justify;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 0 0 #949494;
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: ${(props) => props.colorHover};
  }
`
export const AboutProfileNameLi = styled.li<{color: string}>`
  display: flex;
  color: ${(props) => props.color};
  text-align: justify;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  font-size: 20px;
  font-weight: 500;
  box-shadow: 0 0 0 #949494;
`
export const AboutProfileLine = styled.div<{border: string}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
  height: 0;
  border: 1px solid ${(props) => props.border};
`
export const AboutProfileLiMobile = styled.li`
  background-color: rgba(0, 0, 0, 0.5);
  text-align: justify;
  align-items: center;
  padding: 12px 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  box-shadow: 0 0 0 #949494;
  display: none;
  @media(max-width: 740px) {
    display: block;
  }
  &:hover {
    background-color: #e0dfdf;
    color: rgba(0, 0, 0, 0.98);
  }
`

export const ProfileName = styled.h4`
  color: white;
  margin-right: 5px;
`

export const LinkRefMobile = styled(Link)`
  color: inherit;
  text-decoration: none;
  margin: 0 0 0 10px;
  display: none;
  @media(max-width: 740px) {
    display: block;
  }
`

export const DebugWindow = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  max-width: 700px;
  height: 400px;
  color: white;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  h5 {
    margin: 3px;
    padding: 2px;
  }
`

export const PreviewImage = styled.img<{height: string}>`
  max-height: ${(props: any) => props.height};
  overflow: hidden;
  transition: ease 0.5s;
  margin: 5px;
`

export const PreviewBlock = styled.span<{index: number}>`
  position: absolute;
  left: ${(props: any) => props.index % 2 === 0 ? 0 : 'auto'};
  right: ${(props: any) => props.index % 2 === 0 ? 'auto' : 0};
  margin-left: ${(props: any) => props.index % 2 === 0 ? "30px" : 0};
  margin-right: ${(props: any) => props.index % 2 === 0 ? 0 : "30px"};
`

export const TableNumbers = styled.table`
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  scroll-behavior: revert;
  margin-right: 10px;
  border-spacing: 10px 3px;
  max-height: 60vh;
  tbody{
    border-collapse: separate;
    border-spacing: 4px;
    background: #FFFFFF;
    tr {
      position: static;
      th{
        margin-top: 10px;
        min-width: 84px;
        max-width: 186px;
        text-align: center;
        height: 42px;
        width: auto;
        color: white;
        white-space: nowrap;
        background: #553AC1;
        font-size: 0.9em;
        padding: 0 10px 3px 10px;
        border: 1px solid #553AC1;
        border-radius: 6px;
        position: sticky;
        top: 0;
        z-index: 1000;
        :hover{
          cursor: pointer;
        }
      }
     td{
       color: white !important;
       text-align: center;
       font-weight: 600;
       min-width: 80px;
       font-size: 0.9em;
       padding: 3px;
       background: #553AC1;
       line-height: 18px;
       height: 30px;
       :nth-of-type(:nth-child(1)){
         border-top-left-radius: 6px;
         color: red;
         border-top-right-radius: 6px;
       }
     }
    }
  }
`

export const TDNumber = styled.td<{color: any}>`
  background: ${( props) => props.color} !important;
`