import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { _baseUrlAPI } from "./index";

export const numbersAPI = createApi({
    reducerPath: 'numbersAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrlAPI,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['Number'],
    endpoints: (builder) => ({
        fetchAllNumbers: builder.query({
            query: () => ({
                url: `phonemaps/`,
                method: 'GET',
            }),
        }),
        fetchNumber: builder.query({
            query: (id) => ({
                url: `phonemaps/${id}/`,
                method: 'GET',
            }),
        }),
        deleteNumber: builder.mutation({
            query: (id) => ({
                url: `phonemaps/${id}/`,
                method: 'DELETE',
            }),
        }),
        putAllNumbers: builder.mutation<any, any>({
            query: ({data, id}) => ({
                url: `phonemaps/${id}/`,
                method: 'PUT',
                body: data
            }),
        }),
        createNumbers: builder.mutation<any, any>({
            query: (data) => ({
                url: `phonemaps/`,
                method: 'POST',
                body: data
            }),
        }),
    }),
})