import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery} from "@reduxjs/toolkit/query";
import {_baseUrlAPI} from "./index";

export const algorithmAPI = createApi({
    reducerPath: 'algorithmAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrlAPI,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['Algorithm'],
    endpoints: (builder) => ({
        fetchAllAlgorithms: builder.query({
            query: () => ({
                url: `algorithms/`,
                method: 'GET',
            }),
        }),
        getAlgorithm: builder.query<any, any>({
            // note: an optional `queryFn` may be used in place of `query`
            query: (id) => ({
                url: `algorithms/${id}/`,
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Algorithm', id }],
            // Pick out data and prevent nested properties in a hook or selector
        }),
        createAlgorithm: builder.mutation<any, any>({
            query: (data) => ({
                url: `algorithms/`,
                method: 'POST',
                body: data,
            }),
        }),
        deleteAlgorithm: builder.mutation<any, any>({
            query: (id) => ({
                url: `/algorithms/${id}/soft_delete/`,
                method: 'DELETE',
            }),
        }),
        updateAlgorithm: builder.mutation<any, any>({
            query: ({data, id}) => ({
                url: `/algorithms/${id}/`,
                method: 'PATCH',
                body: data,
            }),
        }),
        executeAlgorithm: builder.query<any, any>({
            query: ({id, currentComp, data}) => ({
                url: `/algorithms/${id}/execute/1/remote/${currentComp}/`,
                method: 'POST',
                body: {data}
            }),
        }),
        importAlgorithm: builder.mutation<any, any>({
            query: (data) => ({
                url: `/algorithms/import_data/`,
                method: `POST`,
                body: data.data,
                headers: {
                    'content-disposition': "attachment; filename=\"example.txt\"",
                    'content-type': "application/json"
                },
            })
        }),
        updateDataAlgorithm: builder.mutation<any, any>({
            query: (data) => ({
                url: `/algorithms/${data.id}/put_data/`,
                method: `PUT`,
                body: data.data,
                headers: {
                    'content-disposition': "attachment; filename=\"example.txt\"",
                    'content-type': "application/json"
                },
            })
        }),
        exportAlgorithm: builder.query<any, any>({
            query: (id) => ({
                url: `/algorithms/${id}/export_data/`,
                method: `GET`,
                responseHandler: (response: any) => response.blob()
            }),

        }),
        statusAlgorithm: builder.mutation<any, any>({
            query: (data) => ({
                url: `/algorithms/${data.id}/set_runtime_statuses/`,
                method: 'POST',
                body: data
            })
        }),
        uploadXslx: builder.mutation<any, any>({
            query: (data) => ({
                url: `/algorithms/fetch_xlsx/`,
                method: `POST`,
                body: data
            })
        }),
        // takeScreenshotAlgorithm: builder.mutation<any, any>({
        //     query: (data) => ({
        //         url: `/algorithms/${data.id}/set_screenshot_status/`,
        //         method: 'POST',
        //         body: data.stop_status
        //     })
        // })
    }),
})