import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import {_baseUrl } from "./index";

export const simBankApi = createApi({
    reducerPath: 'simbankAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['SimBank'],
    endpoints: (builder) => ({
        getSmsBank: builder.query({
            query: () => ({
                url: `stash/wa/phone_number/`,
                method: 'GET'
            })
        }),
        postSmsBank: builder.mutation({
            query: (data) => ({
                url: `stash/wa/phone_number/upload_phonemap/`,
                method: 'POST',
                body: data
            })
        })
    })
})