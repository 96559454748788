import React from 'react';
import * as MUI from "./style"
import {useTranslation} from "react-i18next";

export const Instruction = () => {
   const { t } = useTranslation();
   return (
       <div style={{width: "100%"}}>
          <h1 style={{textAlign: "center", margin: "5px"}}>
             {t("InstructionCreateAlg")}
          </h1>
          <br/>
          <h3 style={{marginLeft: "45px"}}>{t("4GroupsButtons")}</h3>
          <br/>
          <ul>
             <div style={{display: "flex", alignItems: "center"}}>
                <img src="../images/click.svg" alt="click"/>
                <span style={{marginLeft: "5px", fontSize: "1.2em", fontWeight: "bolder"}}>{t("Click")}</span>
             </div>
             <MUI.Ol>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("ClickToPath")}</span>
                   {t("ClickToPathDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("ClickToImage")}</span>
                   {t("ClickToImageDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Coords")}</span>
                   {t("CoordsDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Unnecessary")} </span>
                   {t("UnnecessaryDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("AntiCaptcha")}</span>
                   {t("AntiCaptchaDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("UnnecessaryClickImage")}</span>
                   {t("UnnecessaryClickImageDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Checker")}</span>
                   {t("CheckerDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("GoTo")}</span>
                   {t("GoToDesc")}
                </li>
             </MUI.Ol>
             <hr/>
             <div style={{display: "flex", alignItems: "center"}}>
                <img src="../images/text.svg" alt="text"/>
                <span style={{marginLeft: "5px", fontSize: "1.2em", fontWeight: "bolder"}}>{t("Text")}</span>
             </div>
             <MUI.Ol>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Input")}</span>
                   {t("InputDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("SMS")} </span>
                   {t("SMSDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Neuro")}</span>
                   {t("NeuroDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("CleanField")}</span>
                   {t("CleanFieldDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("PutCode")}</span>
                   {t("PutCodeDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("CopyData")}</span>
                   {t("CopyDataDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("PasteData")}</span>
                   {t("PasteDataDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("SMSService")}</span>
                   {t("SMSServiceDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("SMSServiceCode")}</span>
                   {t("SMSServiceCodeDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("OpenLink")}</span>
                   {t("OpenLinkDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("SavePhone")}</span>
                   {t("SavePhoneDesc")}
                </li>
             </MUI.Ol>
             <hr/>
             <div style={{display: "flex", alignItems: "center"}}>
                <img src="../images/system.svg" alt="system"/>
                <span style={{marginLeft: "5px", fontSize: "1.2em", fontWeight: "bolder"}}>{t("System")}</span>
             </div>
             <MUI.Ol>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Home")}</span>
                   {t("HomeDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("OkEnter")}</span>
                   {t("OkEnterDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Sleep")}</span>
                   {t("SleepDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Reboot")}</span>
                   {t("RebootDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("SwipeUp")}</span>
                   {t("SwipeUpDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("SwipeDown")}</span>
                   {t("SwipeDownDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("CollapseKeyboard")}</span>
                   {t("CollapseKeyboardDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("ResetAll")}</span>
                   {t("ResetAllDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Macros")}</span>
                   {t("MacrosDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("DeletePhoneBook")}</span>
                   {t("DeletePhoneBookDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("TransferImages")}</span>
                   {t("TransferImagesDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("Template")}</span>
                   {t("TemplateDesc")}
                </li>
             </MUI.Ol>
             <hr/>
             <div style={{display: "flex", alignItems: "center"}}>
                <img src="../images/apps.svg" alt="apps"/>
                <span style={{marginLeft: "5px", fontSize: "1.2em", fontWeight: "bolder"}}>{t("Apps")}</span>
             </div>
             <MUI.Ol>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("OpenApp")}</span>
                   {t("OpenAppDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("CloseApps")}</span>
                   {t("CloseAppsDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("CloseApp")}</span>
                   {t("CloseAppDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("DeleteApp")}</span>
                   {t("DeleteAppDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("EnableVPN")}</span>
                   {t("EnableVPNDesc")}
                </li>
                <li>
                   <span style={{fontWeight: "bolder"}}>{t("DisableVPN")}</span>
                   {t("DisableVPNDesc")}
                </li>
             </MUI.Ol>
          </ul>
       </div>
   )
}