import styled from "styled-components";

const colorGreen: string = '#29C570';
const constLight: string = '#F9F9FC';
const colorDark: string = '#202125';

export const Body = styled.div<{background: string, color: string}>`
  width: 100%;
  background: ${(props) => props.background} !important;
  color: ${(props) => props.color} !important;
  transition: ease 1s;
  :visited{
    color: ${(props) => props.color} !important;
  }
  min-height: 88vh;
`
export const DefaultBlock = styled.div`
  width: 100% !important;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;
`
export const RegisterLoginBlock = styled.div`

`
export const ContainerMain = styled.div`
  max-width: 1440px;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background: inherit;
  transition: ease 1s;
  color: inherit;
`
export const MainBlock = styled.div`
  flex: none;
  order: 0;
  flex-grow: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DefaultContainer = styled.div`
  width: 1256px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media(max-width: 1266px) {
    width: 100%;
    max-width: 1256px;
  }
`

export const DefaultAlgorithm = styled.div`
  width: 1056px;
  display: flex;
  flex-direction: column;
  @media(max-width: 1066px) {
    width: 100%;
    max-width: 1056px;
  }
`
export const HeaderTransparent = styled.div`
  height: 12vh;
  padding-left: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 7%;
  box-sizing: border-box;
`
export const HeaderTransparentMain = styled.div<{background: string}>`
  background-color: ${(props) => props.background};
  transition: ease 1s;
  height: 12vh;
  padding-left: 7%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 7%;
  box-sizing: border-box;
  @keyframes gradient {
    0% {
      background-position: 0 25%;
    }
    25% {
      background-position: 25% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`
export const LogoBlack = styled.h2`
  color: #000000;
  @media(max-width: 535px) {
    font-size: 1em;
  }
  @media(max-width: 390px) {
    font-size: 1em;
  }
`

export const Logo = styled.h2<{color: string}>`
  color: ${(props) => props.color};
  @media(max-width: 535px) {
    font-size: 1em;
  }
  @media(max-width: 390px) {
    font-size: 1em;
  }
`

export const Profile = styled.div`

`

export const ProfileButtonsNotSign = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
`

export const ProfileButtonsListNotSignNoAccent = styled.li<{color: string}>`
  list-style-type: none;
  color: ${(props) => props.color};
  margin-right: 3rem;
  display: flex;
  justify-content: center;
  align-self: center;
  @media(max-width: 535px) {
    margin-right: 2rem;
    font-size: 0.9em;
  }
  @media(max-width: 390px) {
    margin-right: 1rem;
  }
`
export const ProfileButtonsListNotSignWithAccent = styled.li`
  list-style-type: none;
  color: inherit;
  background: ${colorGreen};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-self: center;
`

export const Link = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  padding: 13px;
  @media(max-width: 535px) {
    font-size: 1em;
    padding: 10px 16px;
  }
  @media(max-width: 390px) {
    font-size: 0.8em;
    padding: 8px 10px;
  }
  color: ${(props) => props.color} !important;
`
export const FlexBlock = styled.div`
  position: relative;
  height: 88vh;
  display: flex;
  @media(max-width: 1150px) {
    background-image: url("/images/AnimationMain.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
`
export const FlexBlockColumnMobile = styled.div`
  position: relative;
  height: 88vh;
  display: flex;
  @media(max-width: 1150px) {
    background-image: url("/images/AnimationMain.svg");
    background-repeat: no-repeat;
    background-position: center;
    flex-direction: column;
  }
`
export const LeftBlockMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: self-end;
  width: 28%;
  padding-left: 7%;
  padding-bottom: 7%;
  @media(max-width: 1150px) {
    width: 60%;
  }
  @media(max-width: 700px) {
    align-self: center;
    width: 80%;
  }
`


export const H1= styled.h1`
  color: inherit;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
`

export const DefaultButton = styled.button<{width: string, height: string, color: string, background: string}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  line-height: 22px;
  border: 1px solid ${colorGreen};
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  transition: ease 1s;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  :hover{
    cursor: pointer;
    background-color: ${colorGreen} !important;
    color: white;
  }
`
export const P = styled.p`
  color: inherit;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 24px;
  text-align: start;
`

export const RightBlockMainContent = styled.div`
  width: 65%;
  @media(max-width: 1150px) {
    display: none;
  }
`

export const AnimationSVG= styled.img`
  width: 100%;
  height: 100%;
`


export const AboutBlockCenter = styled.h1`
  margin-top: 3em;
  color: inherit;
  text-align: center;
`

export const LeftBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: self-start;
  width: 28%;
  padding-left: 7%;
  padding-bottom: 7%;
  @media(max-width: 1150px) {
    width: 60%;
  }
  @media(max-width: 700px) {
    align-self: center;
    width: 80%;
  }
`
export const RightBlockContent = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  align-self: center;
  height: 100%;
`
export const AboutMiniPart = styled.h3`
  text-align: start;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`

export const Frame = styled.iframe`
  width: 70%;
  height: 70vh;
  @media(max-width: 1150px) {
    width: 100%;
    height: 50vh;
  }
  @media(max-width: 850px) {
    width: 100%;
    height: 35vh;
  }
  @media(max-width: 450px) {
    width: 100%;
    height: auto;
  }
`

export const InformationPageLogin = styled.div`
  width: 50%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
`

export const InformationLogin = styled.div`
  text-decoration: none;
`

export const Cards = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  max-width: 1256px;
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const Card = styled.div<{background: string}>`
  background: ${(props) => props.background};
  width: 280px;
  margin: 12px 12px 12px 12px;
  box-shadow: 0 10px 24px -3px rgba(54, 51, 87, 0.06);
  border-radius: 14px;
  border: 1px solid rgba(54, 51, 87, 0.06);
  @media (max-width: 1180px) {
    min-width: 250px;
    max-width: 300px;
  }
  @media(max-width: 705px) {
    max-width: 250px;
  }
  @media(max-width: 650px) {
    max-width: 300px;
  }
  @media(max-width: 350px) {
    max-width: 300px;
    min-width: 200px;
  }
  @media(max-width: 300px) {
    width: 98%;
  }
`
export const CardItem = styled.div`
  box-sizing: border-box;
  padding: 15px;
`
export const CardBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`
export const CardBlockStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 5px 0;
`
export const DefaultSpan = styled.span`
  display: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  padding: 3px;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  :hover{
    cursor: pointer
  }
`
export const CardButtonButtonSVG = styled.img`
  margin: 5px;
  width: 36px;
  :hover{
    cursor: pointer;
  }
`

export const CardButtonSVGMini = styled.img`
  width: 24px;
`
export const CardScreenshotBlockImage = styled.img`
  max-height: 300px;
`
export const CardCreate = styled.div`
  padding: 10px;
  width: 100%;
  margin: 5px;
  border-radius: 50px;
  max-width: 420px;
  display: flex;
  flex-direction: row;
  ${DefaultButton} {
    margin-right: 10px;
  }
`

export const Footer = styled.div`
  margin-top: 2em;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: inherit;
  @media(max-width: 720px) {
    height: 100px;
  }
`

export const Tags = styled.div`
  margin: 0 10px;
`
export const ImageForButton = styled.img<{bg: string, padding: string}>`
  transition: ease 0.5s;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  padding:  ${(props) => props.bg === 'none' ? '3px' : props.padding};
  margin: 5px;
  z-index: 1001;
  :first-child{
    margin-left: 0 !important;
  }
  background: ${(props) => props.bg === 'none' ? 'inherit' : 'none'};
  :hover{
    cursor: pointer;
    border: 2px solid ${colorGreen};
  }
`

export const ProfileButton = styled.button`
  border: none;
  background: none;
  width: 24px;
  height: 24px;
  margin: 0 10px;
  z-index: 1001;
  :hover{
    cursor: pointer;
  }
`

export const UserName = styled.h5<{color: string}>`
  color: ${(props) => props.color};
  z-index: 1001;
`

export const TopBlockAlgorithmTopPart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;
  @media (max-width: 740px) {
    flex-wrap: wrap;
  }
`

export const TopBlockAlgorithmSettings = styled.div`
  //change on MAX_WIDTH
  width: 100%;
  @media(max-width: 840px) {
    max-width: 95%;
  }
`

export const DefaultLabel = styled.label`
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  :hover{
    cursor: pointer;
  }
`

export const InputStep = styled.input<{color: string}>`
  width: 100px;
  background: inherit;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.color};
  border-radius: 4px;
  border: 1px solid gray;
  height: 45px;
  margin: 3px 2px 0 2px;
  box-sizing: border-box;
  text-align: start;
  padding-left: 15px;
  outline: 0.1px solid lightgray;
  transition: ease 1s;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder{
    color: ${(props) => props.color};
  }
  :focus{
    outline: 1px solid ${colorGreen};
    background: inherit;
  }
`

export const TextAreaStep = styled.textarea`
  width: 100px;
  background: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  border-radius: 4px;
  border: 1px solid gray;
  height: 45px;
  margin: 3px 2px 0 2px;
  box-sizing: border-box;
  text-align: start;
  padding-left: 15px;
  outline: 0.1px solid lightgray;
  transition: ease 1s;
  :focus{
    outline: 1px solid ${colorGreen};
    background: whitesmoke;
  }
`
export const RemoteListBlock = styled.div`
  margin-top: 15px;
  max-width: 90%;
`

export const AboutBlock = styled.span`
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  font-family: 'Inter', sans-serif;
`
export const InstructionLabel = styled.a<{background: string}>`
  border: solid ${colorGreen} 1px;
  color: black;
  background: ${(props) => props.background};
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-decoration: none;
`


// CORE

export const FormAlgorithm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  max-width: 1256px;
  width: 1056px;
  @media(max-width: 1066px) {
    width: 100%;
  }
`

export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const BlockCores = styled.div`
  display: flex;
  white-space: normal;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const SpecificallyUl = styled.ul`

`
export const SpecificallyLi = styled.li`
  margin: 5px;
`
export const StructureAlgorithmBlock = styled.div`
  max-width: 1056px;
  margin-bottom: 2em;
  flex-wrap: wrap;
`

export const UlTemplates = styled.div`
  width: 200px !important;
  max-height: 300px !important;
  -webkit-box-shadow: 4px 4px 8px 7px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 8px 7px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 8px 7px rgba(34, 60, 80, 0.2);
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-left: 6rem;
`

export const TemplateLi = styled.li`
  max-width: 200px;
  height: 40px;
`

export const BlockNumber = styled.div`
  display: flex;
  width: 812px;
  justify-content: space-around;
  :nth-child(odd) {
    background: #E9EAF9
  }
  :nth-child(even) {
    background: #DFE0F2
  }
  h4{
    font-weight: 600;
    line-height: 22px;
  }
  :nth-child(-n + 1){
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  :last-child{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  @media(max-width: 840px) {
    width: 90%;
  }
`

export const BlockNumberStatus = styled.div`
  max-width: 146px;
  width: 100%;
`
export const NumberStatus = styled.h5<{color: string}>`
  background: ${(props: any) => props.color} !important;
  color: white;
  text-transform: capitalize;
  padding: 5px 20px;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 50px;
  line-height: 14px;
  position: absolute;
`