import React, {useState} from 'react';
import * as UI from "../style"
import {useNavigate} from "react-router-dom";
import {getTheme} from "../../index";
import {useTranslation} from "react-i18next";
import {ImportAlgorithm} from "../Modal/modal";

export const CreateAlgorithm = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [activeImport, setActiveImport] = useState<boolean>(false)
    return (
        <UI.CardCreate>
            <UI.DefaultButton color={getTheme().greenLight}
                              background={getTheme().darkGreen} type="button"
                              onClick={() => navigate('/algorithms/create')} width="280px" height="48px">
                {t("NewAlg")}
            </UI.DefaultButton>
            <ImportAlgorithm activeImport={activeImport} setActiveImport={setActiveImport} id={0}/>
            <UI.DefaultButton color={getTheme().greenLight}
                              background={getTheme().darkGreen} type="button"
                              onClick={() => setActiveImport(!activeImport)} width="280px" height="48px">
                {t("ImportData")}
            </UI.DefaultButton>
        </UI.CardCreate>
    )
}