import styled from "styled-components";
import {Link} from "react-router-dom";

export const LoginPage = styled.div`
  width: 100%;
  height: 100%;
  background: #F4F5F9;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InputDataBlock = styled.div`
  display: flex;
  text-align: center;
  margin: 10px 0 10px 0;
  justify-content: center;
  width: 800px;
  background: linear-gradient(50.49deg, 
    rgba(62, 51, 255, 0.03) 6.11%, rgba(148, 33, 255, 0.03) 28%,
    rgba(254, 31, 207, 0.03) 51.16%, rgba(255, 51, 51, 0.03) 70.95%,
    rgba(255, 174, 33, 0.03) 86.96%);
  border: 24px;
  border-radius: 4px;
  backdrop-filter: blur(10px);
  @media(max-width: 400px) {
    max-width: 350px;
  }n
`
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  width: 384px;
  max-height: 444px;

`
export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px 0 10px 0;
  justify-content: center;
`

export const InputBlockCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  margin: 10px 0 10px 0;
`
export const LabelItem = styled.label`
  margin-left: 5px;
  color: inherit;
  font-weight: 300;
  text-align: start;
`
export const InputItem = styled.input`
  border: none;
  height: 1.5rem;
  font-size: 18px;
  padding: 5px;
  margin: 5px;
  ::placeholder{
    font-size: 0.7em;
  }
`

export const ButtonItem = styled.button`
  background: #553AC1;
  font-size: 24px;
  color: inherit;
  border: none;
  border-radius: 2px;
`

export const RegisterItem = styled.div`
  
`

export const ItemLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  :visited {
    color: blue;
  }
`

export const InfoPasswordGreen = styled.div`
  color: green;
  text-align: left;
  margin-left: 5px;
`

export const InfoPasswordGrey = styled.div`
  color: grey;
  text-align: left;
  margin-left: 5px;
`
