import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery} from "@reduxjs/toolkit/query";
import {_baseUrlAPI} from "./index";

export const macroAPI = createApi({
    reducerPath: 'macroAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrlAPI,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['Macro'],
    endpoints: (builder) => ({
        fetchAllMacros: builder.query({
            query: () => ({
                url: `macroses/`,
                method: 'GET',
            }),
        }),
        getMacro: builder.query<any, any>({
            query: (id) => ({
                url: `macroses/${id}/`,
                method: 'GET',
            }),
        }),
        createMacro: builder.mutation<any, any>({
            query: (data) => ({
                url: `macroses/`,
                method: 'POST',
                body: data,
            }),
        }),
        deleteMacro: builder.mutation<any, any>({
            query: (id) => ({
                url: `macroses/${id}/`,
                method: 'DELETE',
            }),
        }),
        updateMacro: builder.mutation<any, any>({
            query: ({data, id}) => ({
                url: `macroses/${id}/`,
                method: 'PUT',
                body: data,
            }),
        }),
        importMacro: builder.mutation<any, any>({
            query: (data) => ({
                url: `/macroses/${data.id}/import_subcore/`,
                method: `POST`,
                body: data.secret,
            })
        }),
        exportMacro: builder.query<any, any>({
            query: (id) => ({
                url: `/macroses/${id}/export_subcore/`,
                method: `GET`,
                responseHandler: (response: any) => response.blob()
            }),

        })
    }),
})