import React, {ChangeEvent, useEffect} from 'react';
import * as GUI from "../styles";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {numbersAPI} from "../../services/SimcardsService";
import {useFieldArray, useForm} from "react-hook-form";
import {ButtonAdd, ButtonTrash} from "../Blocks/Buttons";
import {useTranslation} from "react-i18next";
import * as UI from "../style"
import {getTheme} from "../../index";
import {useParams} from "react-router-dom";
import {simBankApi} from "../../services/SimBankService";
export const Phonemaps = ({namePhone, tagPhone, commentPhone}: any) => {
    const { t } = useTranslation();
    const [updateNumbers] = numbersAPI.usePutAllNumbersMutation()
    const [phonmap] = simBankApi.usePostSmsBankMutation()
    const {id} = useParams()
    const { phonemaps } = numbersAPI.useFetchNumberQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: false,
        selectFromResult: ({ data }) => ({
            phonemaps: data ? data : {
                id: 0,
                name: 'name',
                create_dt: '0000-00-00',
                comment: 'comment',
                tag: 'tag',
                mapping: []
            }
        })
    })
    const { mapping } = phonemaps || []
    const updateSimbank = async (formData: any) => {
        const { mapping } = formData;
        try {
            await updateNumbers({
                id,
                data: {
                    name: namePhone,
                    comment: commentPhone,
                    tag: tagPhone,
                    mapping: mapping.map((item: any) => ({ number: item.number }))
                }
            })
            SuccessNotify('Успешно!')
        } catch {
            ErrorNotify('Произошла ошибка!')
        }
    }
    // const inputCheckNumb = (event: ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = event.target.value;
    //     const filteredValue = inputValue.replace(/\D/g, '');
    //     if (filteredValue !== inputValue) {
    //         event.target.value = filteredValue;
    //     }
    // }
    const {handleSubmit, register, reset, control} =
        useForm({defaultValues: {mapping: mapping}});
    const {fields, append, remove} = useFieldArray({control, name: "mapping"})
    const importSimb = async () => {
        const data = {
            "phonemap": phonemaps?.mapping
        }
        await phonmap(data)
    }
    useEffect(() => {
        reset({mapping});
    }, [reset, mapping, phonemaps]);
    return (
        <GUI.ContainerMainBlock>
            <GUI.MainBlockNumbers>
                <UI.FormAlgorithm onSubmit={handleSubmit(updateSimbank)}>
                    <GUI.BlockNumbers>
                        {fields && fields.map((item: any, index: any) => {
                            return (
                                <GUI.StructureNumber key={index}>
                                    <GUI.StructureCoreTopBlock>
                                        <GUI.StructureCoreTopBlockBlocks>
                                            <GUI.NumberBlock>
                                                <UI.DefaultSpan style={{marginRight: '10px'}}>{index + 1}</UI.DefaultSpan>
                                                <GUI.StructureCoreInputText
                                                    placeholder={"9*********"}
                                                    // maxLength={10}
                                                    {...register(`mapping.${index}.number`, {
                                                        minLength: 10
                                                    })}
                                                    // onInput={(e:any) => inputCheckNumb(e)}
                                                />
                                                <GUI.BlockAlgorithmButton
                                                    type="button"
                                                    onClick={() => append(index)}>
                                                    <ButtonAdd/>
                                                </GUI.BlockAlgorithmButton>
                                                <GUI.BlockAlgorithmButton
                                                    type="button"
                                                    onClick={() => {
                                                        if (fields.length > 1) {
                                                            remove(index)
                                                        } else {
                                                            ErrorNotify('The phonemaps cannot be less than 1 number')
                                                        }
                                                    }}>
                                                    <ButtonTrash/>
                                                </GUI.BlockAlgorithmButton>
                                            </GUI.NumberBlock>
                                        </GUI.StructureCoreTopBlockBlocks>
                                    </GUI.StructureCoreTopBlock>
                                </GUI.StructureNumber>
                            )
                        })}
                    </GUI.BlockNumbers>
                    <UI.TopBlockAlgorithmTopPart>
                        <UI.DefaultButton width="200px" height="48px"
                                          color={getTheme().greenLight}
                                          background={getTheme().darkGreen}
                                          type="submit"> {t("Update")}
                        </UI.DefaultButton>
                        <UI.DefaultButton width="200px" height="48px"
                                          color={getTheme().greenLight}
                                          background={getTheme().darkGreen}
                                          type="button" onClick={() => importSimb()}> {t("ImportData")}
                        </UI.DefaultButton>
                    </UI.TopBlockAlgorithmTopPart>
                </UI.FormAlgorithm>
            </GUI.MainBlockNumbers>
        </GUI.ContainerMainBlock>
    )
}