import {_webSocketURL, _webSocketDebugURL} from "../services";

export const socketList: [] = []

export const listConnections: string[] = []
export const startWebsocket = (computer: any) => {
    // @ts-ignore
    socketList.push(() => {
        listConnections.push(_webSocketURL + computer + '/')
        const socket = new WebSocket(_webSocketURL + computer + '/')
        socket.binaryType = "arraybuffer"
        return socket
    })
    return socketList
}

export const socketDebugList: [] = []

export const startDebugWebsocket = (computer: any) => {
    // @ts-ignore
    socketDebugList.push(() => {
        listConnections.push(_webSocketDebugURL + computer + '/')
        const socket = new WebSocket(_webSocketDebugURL + computer + '/')
        socket.binaryType = "arraybuffer"
        return socket
    })
    return socketDebugList
}