import React, { useEffect } from 'react';
import * as GUI from "../styles"
import {listConnections, socketDebugList, startDebugWebsocket} from "../../api/WebSocket";
import {useParams} from "react-router-dom";
import {_webSocketDebugURL} from "../../services";
export const DebugMode = ({currentComp, messages, setMessages }: any) => {
    let countComp = true
    const { id }: any = useParams()
    localStorage.setItem("id", id)
    const fieldRef = React.useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (currentComp !== undefined && currentComp !== null) {
            if (countComp){
                countComp = false;
                if (!listConnections.includes(_webSocketDebugURL + currentComp + '/')) {
                    startDebugWebsocket(currentComp)
                    if (socketDebugList) {
                        const debugConnection = socketDebugList[socketDebugList.length - 1]
                        // @ts-ignore
                        const connectDebug = debugConnection()
                        connectDebug.onmessage = (event: any) => {
                            const arrayBuffer = event.data;
                            const data = JSON.parse(arrayBuffer)

                            switch (data?.type) {
                                case 'log':
                                    const {time, cycle_indx, step, event_name, event_value, runtime_status} = data.message
                                    const result = `${time}, cycle: ${cycle_indx}, step: ${step}, name: ${event_name}, value: ${event_value}. Status: ${runtime_status}`
                                    if (data.message) {
                                        setMessages((prevMessages: string[]) => [...prevMessages, result])
                                    }
                                    break
                                case 'step_data':
                                    if (data.message) {
                                        setMessages((prevMessages: string[]) => [...prevMessages, data?.message])
                                    }
                            }
                        }
                    }
                }
            }
        }
        countComp = true
    }, [currentComp])

    useEffect(() => {
        if (fieldRef.current) {
            fieldRef.current.scrollTop = fieldRef.current.scrollHeight;
        }
    }, [messages])

    useEffect(() => {
        countComp = true
    }, [currentComp])
    return (
        <GUI.DebugWindow ref={fieldRef}>
            {messages && messages.map((item: any, index: any) => {
                return (
                    <h5 key={index}>
                        {item}
                    </h5>
                )
            })}
        </GUI.DebugWindow>
    )
}