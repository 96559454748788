import React  from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import * as GUI from "../styles";
import {macroAPI} from "../../services/MacroService";
import {useFieldArray, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Core} from "../Core";
import * as UI from "../style"
import {getTheme} from "../../index";
import {DragDropContext} from '@hello-pangea/dnd';
import {templatesAPI} from "../../services/LayoutsService";
export const TemplateCore = ({nameMacr}:any) => {
    const { t } = useTranslation();
    const { id } = useParams()
    const navigate = useNavigate()
    const [createTemplate] = templatesAPI.useCreateTemplateMutation()
    const { template } = templatesAPI.useGetTemplateQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: false,
        selectFromResult: ({ data }) => ({
            template: data ? data : {
                id: 0,
                name: 'Name',
                create_dt: '0',
                sub_core: {},
                user_id: 1
            }
        })
    })
    const { user_id, sub_core } = template
    const [updateMacr] = templatesAPI.useUpdateTemplateMutation()
    const updateTemplate = async (newData: object) => {
        const data = {name : nameMacr, user_id: Number(user_id), sub_core: newData}
        try {
            await updateMacr({data, id})
            SuccessNotify('Updated!')
            navigate(`/templates/${id}/`)
        } catch {
            ErrorNotify('TemplateCore has not updated!')
        }
    }
    const duplicateMacros = async () => {
        const data = {
            id: 0,
            name: `${template.name} duplicate`,
            user_id: 1,
            sub_core: template.sub_core
        }
        try {
            await createTemplate(data)
            SuccessNotify('Macros created!')
            navigate('/templates/')
        } catch (error) {
            ErrorNotify(`${error}`)
            navigate('/templates/')
        }
    }
    const {handleSubmit, register, control, setValue} =
        useForm({defaultValues: {list: Object.assign(sub_core)['list']}})
    const {fields, insert, swap, remove, update, move} =
        useFieldArray({control, name: "list"})
    const handleDrag = ({ source, destination }:any) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <UI.FormAlgorithm onSubmit={handleSubmit(updateTemplate)}>
            <UI.StructureAlgorithmBlock>
                <GUI.AboutBlock> {t("Structure")} </GUI.AboutBlock>
                <DragDropContext onDragEnd={handleDrag}>
                    <Core
                        fields={fields} insert={insert}
                        swap={swap} remove={remove}
                        register={register} control={control}
                        setValue={setValue} update={update} macro={template}/>
                </DragDropContext>
                <UI.CardBlockStart>
                    <UI.DefaultButton width="200px" height="48px"
                                      style={{marginRight: "10px"}}
                                      color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                                      type="button" onClick={() => duplicateMacros()}> {t("CreateDupl")}
                    </UI.DefaultButton>
                    <UI.DefaultButton width="200px" height="48px"
                                      color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                                      type="submit"> {t("Update")}
                    </UI.DefaultButton>
                </UI.CardBlockStart>
            </UI.StructureAlgorithmBlock>
        </UI.FormAlgorithm>
    )
}