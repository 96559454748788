import React, {ChangeEvent, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'
import * as UI from '../LoginPage/style'
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useForm} from "react-hook-form";
import * as GUI from "../style";
import {useTranslation} from "react-i18next";

export const ResetPassword = () => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('')
    const [passwordMatch, setPasswordMatch] = useState('')
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [passwordError, setPasswordError] = useState('Password can not be empty!')
    const [numbPass, setNumbPass] = useState('At least one number')
    const [upperPass, setUpperPass] = useState('One uppercase letter')
    const [lowerPass, setLowerPass] = useState('One lowercase letter')
    const [minPass, setMinPass] = useState('Minimum 8 characters')
    const [confirmDirty, setConfirmDirty] = useState(false)
    const [confirmError, setConfirmError] = useState('Confirm password can not be empty!')
    const [formValid, setFormValid] = useState(false)
    const { handleSubmit } = useForm()
    const navigate = useNavigate()
    const passwordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
        const min1numb = /(?=.*[0-9])/g
        const minLowLet = /(?=.*[a-z])/g
        const minUppLet = /(?=.*[A-Z])/g
        event.target.value.length === 0 ? setPasswordError('Password can not be empty!')
            : setPasswordError(''); setPasswordDirty(true)

        event.target.value.length < 8 ? setMinPass('Minimum 8 characters')
            : setMinPass(''); setPasswordDirty(true)

        !min1numb.test(String(event.target.value)) ? setNumbPass('At least one number')
            : setNumbPass(''); setPasswordDirty(true)

        !minLowLet.test(String(event.target.value)) ? setLowerPass('One lowercase letter')
            : setLowerPass(''); setPasswordDirty(true)

        !minUppLet.test(String(event.target.value)) ? setUpperPass('One uppercase letter')
            : setUpperPass(''); setPasswordDirty(true)
    }
    const blurHandler = (e:any) => {
        switch (e.target.name) {
            case "confirmPassword" :
                setConfirmDirty(true)
                break
            case 'password':
                setPasswordDirty(true)
                break
        }
    }
    const passwordMatchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordMatch(event.target.value)
        event.target.value.length === 0 ? setConfirmError('Confirm password can not be empty!') : setConfirmError('')
    }
    const submitForm = () => {
        if (password !== passwordMatch) {
            ErrorNotify("Passwords doesn't match")
        } else {
            SuccessNotify('Passwords changed!')
            navigate('/login')
        }
    }
    useEffect(() => {
        if (passwordError || upperPass || lowerPass || numbPass || minPass || confirmError) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }, [password, passwordError, upperPass, lowerPass, numbPass, minPass, confirmError])

    return (
        <GUI.DefaultBlock>
            <GUI.RegisterLoginBlock>
                <GUI.AboutBlockCenter>{t("ResetPassword")}</GUI.AboutBlockCenter>
                <UI.LoginPage>
                    <UI.InputDataBlock>
                        <UI.LoginForm onSubmit={handleSubmit(submitForm)}>
                            <h4>{t("CreateNewPassword")}</h4>
                            {t("NewPasswordNotMatch")}
                            <UI.InputBlock>
                                <UI.LabelItem>{t("NewPassword")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={password}
                                              name="password" required
                                              onChange={(e) => passwordChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                {(passwordDirty && passwordError) && <div style={{color: 'red'}}>{passwordError}</div>}
                                <UI.LabelItem>{t("ConfirmPassword")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={passwordMatch}
                                              name="confirmPassword" required
                                              onChange={(e) => passwordMatchChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                {(confirmDirty && confirmError) && <div style={{color: 'red'}}>{confirmError}</div>}
                            </UI.InputBlock>
                            <UI.InputBlock>
                                {(!passwordDirty || numbPass) && <UI.InfoPasswordGrey>✖ {numbPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !numbPass) && <UI.InfoPasswordGreen>✔ At least one number</UI.InfoPasswordGreen>}
                                {(!passwordDirty || upperPass) && <UI.InfoPasswordGrey>✖ {upperPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !upperPass) && <UI.InfoPasswordGreen>✔ One uppercase letter</UI.InfoPasswordGreen>}
                                {(!passwordDirty || lowerPass) && <UI.InfoPasswordGrey>✖ {lowerPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !lowerPass) && <UI.InfoPasswordGreen>✔ One lowercase letter</UI.InfoPasswordGreen>}
                                {(!passwordDirty || minPass) && <UI.InfoPasswordGrey>✖ {minPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !minPass) && <UI.InfoPasswordGreen>✔ Minimum 8 characters</UI.InfoPasswordGreen>}
                            </UI.InputBlock>
                            <UI.ButtonItem disabled={!formValid} type="submit">{t("Change")}</UI.ButtonItem>
                        </UI.LoginForm>
                    </UI.InputDataBlock>
                </UI.LoginPage>
            </GUI.RegisterLoginBlock>
        </GUI.DefaultBlock>
    )
}