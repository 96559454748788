import styled from "styled-components";

export const HeaderBlock = styled.div`
  font-family: 'Inter', sans-serif;
  background: #553AC1;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
`

export const Menu = styled.div`
    justify-content: center;
    box-sizing: border-box;
    @media(max-width: 840px) {
      display: none;
    }
`
