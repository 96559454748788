import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {IUser, IUsernameRequest, IUsernameResponse} from "../interfaces/User";
import {_baseUrl} from "./index";

export interface IToken {
    access: string,
    refresh: string
}
export const userAPI = createApi({
    reducerPath: 'userAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrl,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token')
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        createUser: builder.mutation<IUser, IUser>({
            query: (user) => ({
                url: `auth/register/`,
                method: 'POST',
                body: user
            }),
        }),
        setUser: builder.mutation<any, any>({
            query: (user) => ({
                url: `token/`,
                method: 'POST',
                body: user
            }),
            transformResponse: (response: {access: string, refresh: string}) => {
                localStorage.setItem('token', response.access)
                localStorage.setItem('tokenRefresh', response.refresh)
            },
            invalidatesTags: ['User'],
            // onQueryStarted is useful for optimistic updates
        }),
        getProfile: builder.query<IUsernameResponse, any> ({
            query: () => ({
                url: `auth/profile/obtain/`,
                method: 'GET'
            })
        }),
        updateProfile: builder.mutation<any, IUsernameRequest> ({
            query: (data) => ({
                url: `auth/profile/${data.id}/refresh/`,
                method: 'PUT',
                body: data
            })
        }),
        changePassword: builder.mutation<any, any>({
            query: (user) => ({
                url: 'auth/change_pass/',
                method: 'PUT',
                body: user
            })
        }),
        emailActivate: builder.query<any, any>({
            query: () => ({
                url: `auth/profile/email/activate/`,
                method: 'GET'
            })
        }),
        restorePassword: builder.mutation<any, any>({
            query: (data) => ({
                url: `auth/profile/email/restore/`,
                method: 'POST',
                body: data
            })
        }),
        refreshToken: builder.mutation<any, any>({
            query: (data) => ({
                url: `token/refresh/`,
                method: 'POST',
                body: data
            })
        })
    })
})

export const { useCreateUserMutation, useSetUserMutation, useChangePasswordMutation } = userAPI