import { createApi } from '@reduxjs/toolkit/query/react';
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {IText} from "../interfaces/Text";
import { _baseUrlAPI } from "./index";

export const textAPI = createApi({
    reducerPath: 'textAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrlAPI,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['Text'],
    endpoints: builder => ({
        getTextes: builder.query({
            query: () => ({
                url: '/textes',
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }),
            providesTags: result => ['Text']
        }),
        createText: builder.mutation<IText, IText>({
            query: (text) => ({
                url: '/textes/',
                method: 'POST',
                body: text
            }),
            invalidatesTags: ['Text']
        })
    })
})

export const { useGetTextesQuery } = textAPI