import React, {ChangeEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SuccessNotify, ErrorNotify} from "../../Notifies/Notifications";
import * as GUI from "../styles";
import {TopAlgorithmSettings} from "../Blocks/Buttons";
import {useTranslation} from "react-i18next";
import {useFieldArray, useForm} from "react-hook-form";
import { macroAPI } from "../../services/MacroService";
import {Core} from "../Core";
import * as UI from "../style"
import {DragDropContext} from "@hello-pangea/dnd";
export const CreateMacro = () => {
    const { t } = useTranslation();
    const [name, setName] = useState<string>('default Name')
    const {control, handleSubmit, register, setValue} = useForm({
        defaultValues: {
            list: [
                {home: 'Home'},
                {click_to_path: 'Click to path'},
                {input_text: 'Input text'},
            ]
        }
    })
    const {fields, insert, remove, swap, update, move} = useFieldArray({control, name: "list"})
    const navigate = useNavigate()
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.length === 15){
            ErrorNotify("Algorithm shouldn't exceed 15 characters")
        }
        setName(event.target.value)
    }
    const [createMacro] = macroAPI.useCreateMacroMutation()
    const getCreateMacro = async (newData: object) => {
        const data = {
            "name": name,
            "user_id": 1,
            "sub_core": newData
        }
        try {
            await createMacro(data)
            SuccessNotify('Macros created! Gratz')
            navigate('/macroses')
        } catch (error) {
            ErrorNotify(`${error}`)
            navigate('/macroses')
        }
    }
    const {data: macro} = macroAPI.useFetchAllMacrosQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const handleDrag = ({ source, destination }:any) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };
    return (
        <GUI.ContainerMainBlock>
            <UI.FormAlgorithm onSubmit={handleSubmit(getCreateMacro)}>
                <GUI.TopBlockAlgorithm>
                    <GUI.DefaultBlock>
                        <TopAlgorithmSettings />
                        <GUI.SecondBlockAlgorithmMainInfo>
                            <GUI.SecondBlockAlgorithmInputName
                                type="text" placeholder="Name"
                                name="name"
                                onChange={nameChange}
                                maxLength={25}/>
                        </GUI.SecondBlockAlgorithmMainInfo>
                    </GUI.DefaultBlock>
                </GUI.TopBlockAlgorithm>
                <GUI.DefaultBlock>
                    <UI.StructureAlgorithmBlock>
                        <GUI.AboutBlock>
                            {t("Structure")}
                        </GUI.AboutBlock>
                        <DragDropContext onDragEnd={handleDrag}>
                            <Core
                                fields={fields} insert={insert} remove={remove}
                                register={register} control={control}
                                setValue={setValue} update={update} macro={macro}/>
                        </DragDropContext>
                    </UI.StructureAlgorithmBlock>
                </GUI.DefaultBlock>
            </UI.FormAlgorithm>
        </GUI.ContainerMainBlock>
    )
}
