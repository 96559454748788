import React, {useState} from 'react';
import { userAPI } from "../../services/UserService";
import { Spinner } from "../Spinner";
import * as MUI from "../style"
import * as UI from "./style";
import { useForm } from "react-hook-form";
import {CustomNotify, ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {checkResponse} from "../../Validators/checkResponse";

export const Profile = ({username}: any) => {
    const itemCapitalize = (element: string) => {
        return element.charAt(0).toUpperCase() + element.slice(1)
    }
    const {data: profile, isLoading, refetch } = userAPI.useGetProfileQuery(undefined, {
        refetchOnMountOrArgChange: true,
    })

    const [ updateProfile ] = userAPI.useUpdateProfileMutation()
    const [ emailActivate ] = userAPI.useLazyEmailActivateQuery()
    const beautyPhoneNumber = (number: string) => {
        return `${number.slice(0, 5)} 
                ${number.slice(5, 8)} 
                ${number.slice(8, 10)}
                ${number.slice(10)}`
    }

    const emailConfirm = async() => {
        // @ts-ignore
        await emailActivate().then(() => SuccessNotify('Message send on email. Please check.'))
    }
    const [ editProfile, setEditProfile ] = useState<boolean>(false)
    const { handleSubmit, register } = useForm()

    if (isLoading) return <Spinner/>
    if (!profile) return <h1>Error</h1>

    const update = async (data: any) => {
        data.id = profile.id
        data.user = profile.user
        try {
            await updateProfile(data)
                .then((response: any) => checkResponse(response))
            refetch()
            setEditProfile(!editProfile)
        } catch (error) {
            ErrorNotify(`${error}`)
        }
    }
    return (
        <MUI.DefaultBlock>
            {!editProfile ?
                <UI.TopBlockProfile>
                    <UI.FlexBlock>
                        <UI.BlockProfilePhoto>
                            <UI.ProfilePhoto src="../images/AnimationMain.svg"
                                             alt="Profile photo"/>
                        </UI.BlockProfilePhoto>
                        <UI.ProfileUserData>
                            <h1>{username}</h1>
                            <h2>{profile.name ? ` ${itemCapitalize(profile.name)}` : ''}
                                {profile.surname ? ` ${itemCapitalize(profile.surname)}` : ''}</h2>
                            <h4>Subscription until 22.02.2023</h4>
                        </UI.ProfileUserData>
                    </UI.FlexBlock>
                    <UI.FlexBlock>
                        <UI.ProfileUserData>
                            <h3>Email: {profile.email ? profile.email : ''}</h3>
                            <h3>Status: {profile.email_confirm ? 'confirmed' : 'not confirmed'}</h3>
                            {!profile.email_confirm ?
                                <UI.ButtonLight
                                    onClick={() => emailConfirm()}>
                                    Confirm
                                </UI.ButtonLight>
                                :
                                ''}
                            <h3>Phone: {profile.phone ? beautyPhoneNumber(profile.phone) : ''}</h3>
                            <h3>Country: {profile.country ? `${itemCapitalize(profile.country)}` : ''}</h3>
                            <UI.ButtonLight onClick={() => refetch()}>
                                Reload
                            </UI.ButtonLight>
                            <UI.ButtonLight onClick={() => setEditProfile(!editProfile)}>
                                Edit
                            </UI.ButtonLight>
                        </UI.ProfileUserData>
                    </UI.FlexBlock>
                </UI.TopBlockProfile>
                :
                <UI.DefaultForm onSubmit={handleSubmit(update)}>
                    <UI.TopBlockProfile>
                        <UI.FlexBlock>
                            <UI.BlockProfilePhoto>
                                <UI.ProfilePhoto src="../images/AnimationMain.svg"
                                                 alt="Profile photo"/>
                            </UI.BlockProfilePhoto>
                            <UI.ProfileUserData>
                                <h1>{username}</h1>
                                <br/>
                                <UI.Label>name</UI.Label><br/>
                                <UI.InputInProfile {...register("name")}
                                                   defaultValue={profile.name}/><br/>
                                <UI.Label>surname</UI.Label><br/>
                                <UI.InputInProfile {...register("surname")}
                                                   defaultValue={profile.surname}/><br/>
                                <h4>Subscription until 22.02.2023</h4>
                            </UI.ProfileUserData>
                        </UI.FlexBlock>
                        <UI.FlexBlock>
                            <UI.ProfileUserData>
                                <br/>
                                <UI.Label>email</UI.Label><br/>
                                <UI.InputInProfile {...register("email")}
                                                   defaultValue={profile.email}/><br/>
                                <UI.Label>phone</UI.Label><br/>
                                <UI.InputInProfile {...register("phone")}
                                                   defaultValue={profile.phone}/><br/>
                                <UI.Label>country</UI.Label><br/>
                                <UI.InputInProfile {...register("country")}
                                                   defaultValue={profile.country}/><br/>
                                <UI.ButtonLight type="submit">
                                    Update
                                </UI.ButtonLight>
                                <UI.ButtonLight type="button" onClick={() => setEditProfile(!editProfile)}>
                                    Cancel
                                </UI.ButtonLight>
                            </UI.ProfileUserData>
                        </UI.FlexBlock>
                    </UI.TopBlockProfile>
                </UI.DefaultForm>
            }
        </MUI.DefaultBlock>
    )
}