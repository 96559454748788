import React, {ChangeEvent, useEffect, useState} from 'react';
import * as GUI from "../styles";
import {useParams} from "react-router-dom";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useNavigate} from "react-router-dom";
import {ModalRemove} from "../Modal/modal";
import * as UI from "../AlgorithmList/style";
import {AboutBlock} from "../Blocks/Blocks";
import {useTranslation} from "react-i18next";
import * as MUI from "../style"
import {getTheme} from "../../index";
import {numbersAPI} from "../../services/SimcardsService";
import {Phonemaps} from "../SimCards";

export const PhonemapsCard = () => {
    const { t } = useTranslation();
    const { id }: any = useParams()
    const navigate = useNavigate()
    const [removePhone] = numbersAPI.useDeleteNumberMutation()
    const { data: phonemaps, isFetching } = numbersAPI.useFetchNumberQuery(id, {
        refetchOnMountOrArgChange: true
    })
    const [modalActive, setModalActive] = useState<boolean>(false)
    const [namePhone, setNamePhone] = useState<string>(phonemaps?.name)
    const [tagPhone, setTagPhone] = useState<string>(phonemaps?.tag)
    const [commentPhone, setCommentPhone] = useState<string>(phonemaps?.comment)
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => setNamePhone(event.target.value)
    const tagChange = (event: ChangeEvent<HTMLInputElement>) => setTagPhone(event.target.value)
    const commentChange = (event: ChangeEvent<HTMLInputElement>) => setCommentPhone(event.target.value)
    const removePhonemaps = async () => {
        try {
            await removePhone(id)
            SuccessNotify('Phonemaps has deleted')
            navigate('/phonemaps')
        } catch (error) {
            ErrorNotify(`Phonemaps has not deleted! Reason: ${error}`)
        }
    }
    const showModal = () => {
        setModalActive(true)
    }
    const ModalItem = () => {
        return  <ModalRemove active={modalActive} setActive={setModalActive}>
            <UI.CardAboutName>
                <MUI.DefaultSpan style={{color: getTheme().colorFont}}>
                    {t("areYouSure")}
                </MUI.DefaultSpan>
            </UI.CardAboutName>
            <GUI.DefaultBlockFlex>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => removePhonemaps()}> {t("delete")}
                </MUI.DefaultButton>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => setModalActive(!modalActive)}> {t("cancel")}
                </MUI.DefaultButton>
            </GUI.DefaultBlockFlex>
        </ModalRemove>
    }

    useEffect(() => {
        if(!isFetching){
            setNamePhone(phonemaps?.name)
            setTagPhone(phonemaps?.tag)
            setCommentPhone(phonemaps?.comment)
        }
    }, [phonemaps, isFetching])

    return (
        <MUI.MainBlock>
            <ModalItem/>
            <MUI.DefaultAlgorithm>
                <GUI.TopBlockAlgorithm>
                    <GUI.DefaultBlock>
                        <MUI.TopBlockAlgorithmSettings>
                            <MUI.TopBlockAlgorithmTopPart>
                                <AboutBlock name='Settings'/>
                                <GUI.TopBlockAlgorithmButtons>
                                    <GUI.BlockAlgorithmButton
                                        type="button"
                                        onClick={() => showModal()}>
                                        <MUI.CardButtonButtonSVG
                                            src="/images/Remove.svg"
                                            alt="Remove Algorithm"/>
                                    </GUI.BlockAlgorithmButton>
                                </GUI.TopBlockAlgorithmButtons>
                            </MUI.TopBlockAlgorithmTopPart>
                        </MUI.TopBlockAlgorithmSettings>
                        <GUI.SecondBlockAlgorithmMainInfo style={{margin: 0}}>
                            <MUI.InputStep color={getTheme().inputColor}
                                           style={{width: "200px", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                           type="text" placeholder='Name' maxLength={15}
                                           value={namePhone} onChange={nameChange}
                            />
                        </GUI.SecondBlockAlgorithmMainInfo>
                        <GUI.SecondBlockAlgorithmMainInfo style={{margin: 0}}>
                            <MUI.InputStep color={getTheme().inputColor}
                                           style={{width: "200px", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                           type="text" placeholder='Tag' maxLength={100}
                                           value={tagPhone} onChange={tagChange}
                            />
                        </GUI.SecondBlockAlgorithmMainInfo>
                        <GUI.SecondBlockAlgorithmMainInfo style={{margin: 0}}>
                            <MUI.InputStep color={getTheme().inputColor}
                                           style={{width: "200px", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                           type="text" placeholder='Comment' maxLength={100}
                                           value={commentPhone} onChange={commentChange}
                            />
                        </GUI.SecondBlockAlgorithmMainInfo>
                    </GUI.DefaultBlock>
                </GUI.TopBlockAlgorithm>
            </MUI.DefaultAlgorithm>
            <Phonemaps namePhone={namePhone} tagPhone={tagPhone} commentPhone={commentPhone}/>
        </MUI.MainBlock>
    )
}