import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery} from "@reduxjs/toolkit/query";
import {_baseUrl} from "./index";

export const notAuthorizedAPI = createApi({
    reducerPath: "notAuthorizedAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrl
    }),
    tagTypes: ['NotAuthorized'],
    endpoints: (builder) => ({
        showImage: builder.query({
            query: (data) => ({
                url: `/media/users_folder/${data.username}/${data.fileName}`,
                method: 'GET',
            })
        })
    })
})