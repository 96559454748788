import React from 'react';
import * as UI from "../style"
import {useNavigate} from "react-router-dom";
import {getTheme} from "../../index";
import {useTranslation} from "react-i18next";
export const ButtonNewSimCard = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    return (
        <UI.CardCreate>
            <UI.DefaultButton color={getTheme().greenLight}
                              background={getTheme().darkGreen}
                              type="button" onClick={() => navigate('/phonemaps/create')} width="280px" height="48px">
                {t("CreatePhonemaps")}
            </UI.DefaultButton>
        </UI.CardCreate>
    )
}