import React, {useState, useEffect} from 'react';
import * as UI from './style';
import * as GUI from '../styles';
import * as MUI from '../style';
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useNavigate} from "react-router-dom";
import {algorithmAPI} from "../../services/AlgorithmService";
import {Spinner} from "../Spinner";
import {Modal} from "../Modal/modal";
import {RemoteList} from "../RemoteList";
import {useTranslation} from "react-i18next";
import {CreateAlgorithm} from "./create";
import {getTheme} from "../../index";

export const AlgorithmList = () => {
    const { t } = useTranslation();
    const [currentComp, setCurrentComp] = useState()
    const [modalIndex, setModalIndex] = useState<number>(0)
    const [modalId, setModalId] = useState<number>(0)
    const [modalActive, setModalActive] = useState(false)
    const navigate = useNavigate()
    const {data: algorithms, isLoading} =
        algorithmAPI.useFetchAllAlgorithmsQuery(undefined, {
            refetchOnMountOrArgChange: true
        })
    const [ executeAlgorithms ] = algorithmAPI.useLazyExecuteAlgorithmQuery()
    useEffect(() => {}, [algorithms, currentComp, setCurrentComp])
    if (isLoading) return <Spinner/>
    const showModal = (index: number, id: number) => {
        setModalActive(true)
        setModalIndex(index)
        setModalId(id)
    }

    const ModalItem = () => {
        return  <Modal active={modalActive} setActive={setModalActive}>
            <UI.CardAboutName>
                <MUI.DefaultSpan>
                    {algorithms[modalIndex].name}
                </MUI.DefaultSpan>
            </UI.CardAboutName>
            <div>{t("Status")}
                <UI.CardTopButtonAboutBlockStatus>
                    {algorithms[modalIndex]?.status ? algorithms[modalIndex]?.status : 'None'}
                </UI.CardTopButtonAboutBlockStatus>
            </div>
            <UI.RemoteListInModal>
                <RemoteList currentComp={currentComp} setCurrentComp={setCurrentComp}/>
            </UI.RemoteListInModal>
            <UI.ModalButtonCancelButton
                type="button"
                onClick={() => setModalActive(false)}>
                {t("Cancel")}
            </UI.ModalButtonCancelButton>
            <UI.ModalButtonSubmitButton
                type="button"
                onClick={() => execute(modalId)}>
                {t("Run")}
            </UI.ModalButtonSubmitButton>
        </Modal>
    }
    const execute = async (id:any, path: any = currentComp) => {
        const data = {
            id: modalId,
            path : currentComp
        }
        await executeAlgorithms(data)
        if (path === null || path === undefined) {
            ErrorNotify(`Please, select remote from remote list!`)
        } else {
            ErrorNotify(`Algorithm not run`)
        }
    }
    const goNavigate = (id: any) => {
        navigate(`/algorithms/${id}`)
    }
    const goToAlg = (id: any) => {
        goNavigate(id)
    }
    return (
        <MUI.DefaultBlock>
            <MUI.DefaultContainer>
                <CreateAlgorithm/>
                <MUI.Cards>
                    {algorithms && algorithms.map((card: any, index: number) => {
                        const { id, name } = card
                        let active : string;
                        if (card.status === 'correct') {
                            active = '#8BCC00'
                        } else if (card.status === 'incorrect') {
                            active = '#FF5963'
                        } else {
                            active = '#9394AA'
                        }
                        return (
                            <MUI.Card key={id} background={getTheme().lightStyleBackground}>
                                <ModalItem/>
                                <MUI.CardItem>
                                    <MUI.DefaultSpan>#{id}</MUI.DefaultSpan>
                                    <MUI.CardBlockStart>
                                        <MUI.CardScreenshotBlockImage
                                            src="/images/Mobile.svg"
                                            alt="Screenshot"/>
                                    </MUI.CardBlockStart>
                                    <MUI.CardBlock>
                                        <MUI.DefaultSpan>
                                            {name}
                                        </MUI.DefaultSpan>
                                        <UI.CardTopButtonAboutBlockStatus style={{background : active}}>
                                            {card.status ? card.status : 'Unknown'}
                                        </UI.CardTopButtonAboutBlockStatus>
                                    </MUI.CardBlock>
                                    <MUI.CardBlock>
                                        <MUI.CardButtonButtonSVG
                                            onClick={() => showModal(index, id)}
                                            src="/images/Play.svg"
                                            alt="Run Algorithm"/>
                                        <MUI.DefaultButton width="100px" height="32px"
                                                           color={getTheme().greenLight}
                                                           background={getTheme().darkGreen}
                                                           style={{margin: 0}}
                                            type="button"
                                            onClick={() => goToAlg(id)}>
                                            {t("Settings")}
                                        </MUI.DefaultButton>
                                    </MUI.CardBlock>
                                    <MUI.CardBlock>
                                        <UI.CardDescriptionText>
                                            {t("Description")}
                                        </UI.CardDescriptionText>
                                        <UI.CardDescriptionDropdown
                                            src="/images/Down.svg"
                                            alt="Dropdown"/>
                                    </MUI.CardBlock>
                                </MUI.CardItem>
                            </MUI.Card>
                        )
                    })}
                </MUI.Cards>
            </MUI.DefaultContainer>
        </MUI.DefaultBlock>
    )
}