import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {ButtonNewMacro} from "./create";
import * as UI from "../AlgorithmList/style";
import * as GUI from "../styles";
import {macroAPI} from "../../services/MacroService";
import {Spinner} from "../Spinner";
import {useTranslation} from "react-i18next";
import * as MUI from "../style";
import {getTheme} from "../../index";

export const MacroList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {data: macro, isLoading} = macroAPI.useFetchAllMacrosQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const goToMacro = (id: any) => {
        navigate(`/macroses/${id}`)
    }
    useEffect(() => {}, [macro])
    if (isLoading) return <Spinner/>

    return (
        <MUI.DefaultBlock>
            <MUI.DefaultContainer>
                <ButtonNewMacro/>
                <MUI.Cards>
                {macro && macro.map((card: any, index: number) => {
                    const { id, name } = card
                    return (
                        <MUI.Card key={index} background={getTheme().lightStyleBackground}>
                            <MUI.CardItem>
                                <MUI.DefaultSpan>#{id}</MUI.DefaultSpan>
                                <MUI.CardBlockStart>
                                    <MUI.CardScreenshotBlockImage
                                        style={{border: "1px solid lightgray", borderRadius: "5px"}}
                                        src="/images/Macro.svg"
                                        alt="Screenshot"/>
                                </MUI.CardBlockStart>
                                <MUI.CardBlock>
                                    <MUI.DefaultSpan>
                                        {name}
                                    </MUI.DefaultSpan>
                                </MUI.CardBlock>
                                <MUI.CardBlock>
                                    <MUI.DefaultButton width="100px" height="32px"
                                                       color={getTheme().greenLight}
                                                       background={getTheme().darkGreen}
                                                       style={{margin: 0}}
                                                       type="button"
                                                       onClick={() => goToMacro(id)}>
                                        {t("Settings")}
                                    </MUI.DefaultButton>
                                </MUI.CardBlock>
                            </MUI.CardItem>
                        </MUI.Card>
                    )
                })}
            </MUI.Cards>
            </MUI.DefaultContainer>
        </MUI.DefaultBlock>
    )
}