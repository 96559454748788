import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {textAPI} from "../services/TextService";
import {algorithmAPI} from "../services/AlgorithmService";
import {remoteAPI} from "../services/RemoteService";
import {userAPI} from "../services/UserService";
import {numbersAPI} from "../services/SimcardsService";
import {macroAPI} from "../services/MacroService";
import {filesAPI} from "../services/FileService";
import {notAuthorizedAPI} from "../services/NotAuthorized";
import {templatesAPI} from "../services/LayoutsService";
import {simBankApi} from "../services/SimBankService";

const rootReducer = combineReducers({
  [numbersAPI.reducerPath]: numbersAPI.reducer,
  [textAPI.reducerPath]: textAPI.reducer,
  [algorithmAPI.reducerPath]: algorithmAPI.reducer,
  [remoteAPI.reducerPath]: remoteAPI.reducer,
  [userAPI.reducerPath]: userAPI.reducer,
  [macroAPI.reducerPath]: macroAPI.reducer,
  [filesAPI.reducerPath]: filesAPI.reducer,
  [notAuthorizedAPI.reducerPath]: notAuthorizedAPI.reducer,
  [templatesAPI.reducerPath]: templatesAPI.reducer,
  [simBankApi.reducerPath]: simBankApi.reducer,
})
export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false
    })
        .concat(remoteAPI.middleware)
        .concat(algorithmAPI.middleware)
        .concat(numbersAPI.middleware)
        .concat(textAPI.middleware)
        .concat(userAPI.middleware)
        .concat(macroAPI.middleware)
        .concat(filesAPI.middleware)
        .concat(notAuthorizedAPI.middleware)
        .concat(templatesAPI.middleware)
        .concat(simBankApi.middleware)
})}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']