import React from 'react';
import { useNavigate } from 'react-router-dom'
import * as UI from '../LoginPage/style'
import {useForm} from "react-hook-form";
import * as GUI from "../style";
import {useTranslation} from "react-i18next";
import {getTheme} from "../../index";
import {userAPI} from "../../services/UserService";
import {checkResponse} from "../../Validators/checkResponse";
import {SuccessNotify} from "../../Notifies/Notifications";

export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [ sendCodeOnEmail ] = userAPI.useRestorePasswordMutation()
    const { register, watch } = useForm()
    const navigate = useNavigate()

    const resetPassword = async () => {
        const data = {
            email: watch("email")
        }
        await sendCodeOnEmail(data)
            .then((response) => checkResponse(response))
            .then(() => navigate('/login'))
    }
    return (
        <GUI.DefaultBlock>
            <GUI.RegisterLoginBlock>
                <GUI.AboutBlockCenter>{t("ResetPassword")}</GUI.AboutBlockCenter>
                <UI.LoginPage>
                    <UI.InputDataBlock>
                        <UI.LoginForm>
                            <UI.InputBlock>
                                <UI.LabelItem style={{color: "black"}}>{t("Email")}</UI.LabelItem>
                                <UI.InputItem type="email" placeholder="Enter your email"
                                              {...register("email")}
                                              required />
                            </UI.InputBlock>
                            <GUI.DefaultButton width="385px" height="32px" style={{marginRight: "10px"}}
                                               color={getTheme().lightStyleBackground}
                                               background={getTheme().darkGreen}
                                               onClick={() => resetPassword()}
                                               type="button"> {t("Reset")}
                            </GUI.DefaultButton>
                        </UI.LoginForm>
                    </UI.InputDataBlock>
                </UI.LoginPage>
            </GUI.RegisterLoginBlock>
        </GUI.DefaultBlock>
    )
}