import React from 'react';
import {templatesAPI} from "../../services/LayoutsService";
import {Spinner} from "../Spinner";
import * as MUI from "../style";
import {getTheme} from "../../index";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ButtonNewTemplate} from "./create";

export const TemplatesList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { data: templates, isLoading, isError } = templatesAPI.useGetAllTemplatesQuery(undefined, {
        refetchOnMountOrArgChange: true,
        skip: false
    })

    if (isLoading) return <Spinner/>
    if (isError) return <span>Sorry, error. Try again later.</span>

    return (
        <MUI.DefaultBlock>
            <MUI.DefaultContainer>
                <ButtonNewTemplate/>
                <MUI.Cards>
                    {templates && templates.map((card: any, index: number) => {
                        const { id, name } = card
                        return (
                            <MUI.Card key={index} background={getTheme().lightStyleBackground}>
                                <MUI.CardItem>
                                    <MUI.DefaultSpan>#{id}</MUI.DefaultSpan>
                                    <MUI.CardBlockStart>
                                        <MUI.CardScreenshotBlockImage
                                            style={{border: "1px solid lightgray", borderRadius: "5px"}}
                                            src="/images/Macro.svg"
                                            alt="Screenshot"/>
                                    </MUI.CardBlockStart>
                                    <MUI.CardBlock>
                                        <MUI.DefaultSpan>
                                            {name}
                                        </MUI.DefaultSpan>
                                    </MUI.CardBlock>
                                    <MUI.CardBlock>
                                        <MUI.DefaultButton width="100px" height="32px"
                                                           color={getTheme().greenLight}
                                                           background={getTheme().darkGreen}
                                                           style={{margin: 0}}
                                                           type="button"
                                                           onClick={() => navigate(`/templates/${id}`)}>
                                            {t("Settings")}
                                        </MUI.DefaultButton>
                                    </MUI.CardBlock>
                                </MUI.CardItem>
                            </MUI.Card>
                        )
                    })}
                </MUI.Cards>
            </MUI.DefaultContainer>
        </MUI.DefaultBlock>
    )
}