import React from 'react';
import * as UI from '../style'
export const Updates = () => {
    return (
        <UI.DefaultContainer>
            <div>
                <UI.AboutBlock>Updates</UI.AboutBlock>
                <ul>
                    01.03.2022
                    <li>
                        Add 2 buttons. Copy data and Paste data. "Copy data" can copy code for paste him later with button "Paste data".<br/>
                        In copy data need add key. Format: $key. Example: $bankTransferNumber.<br/>
                        For paste him later, in step "Paste data" need add this key.
                    </li>
                </ul>
            </div>
        </UI.DefaultContainer>
    )
}