const userName = localStorage.getItem("username")
const id = localStorage.getItem("id")


const myLocation = (ws: string) => {
    if (ws === 'ws') {
        if (window.location.hostname === 'localhost') {
            return `ws://${window.location.hostname}`
        } else {
            return `wss://${window.location.hostname}`
        }
    }
    else return `${window.location.protocol}//${window.location.hostname}`
}

export const _baseUrl = myLocation('def')
export const _baseUrlAPI = `${myLocation('def')}/api/`
export const _webSocketURL = `${myLocation('ws')}/ws/stream/${userName?.trim()}/${id}/`
export const _webSocketDebugURL = `${myLocation('ws')}/ws/state/${userName?.trim()}/${id}/`