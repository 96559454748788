import React, {useEffect, useRef, useState} from 'react';
import * as GUI from "../styles"
import {useParams} from "react-router-dom";
import {socketList, startWebsocket, listConnections} from "../../api/WebSocket";
import { _webSocketURL} from "../../services";

let count = true
export const AlgorithmStream =
    ({currentComp, streamWidth, setStreamWidth, streamHeight, setStreamHeight}: any) => {
    const { id }: any = useParams()
    localStorage.setItem("id", id)
    const refContainer = useRef<any>()
    const [imageLink, setImageLink] = useState('')
    if (currentComp !== undefined && currentComp !== null) {
        if (count){
            count = false;
            if (!listConnections.includes(_webSocketURL + currentComp + '/')) {
                startWebsocket(currentComp)
                if (socketList) {
                    const connection = socketList[socketList.length - 1]
                    // @ts-ignore
                    const connect = connection()
                    connect.onmessage = (event: any) => {
                        const arrayBuffer = event.data;
                        let binary = ''
                        let bytes = new Uint8Array (arrayBuffer)
                        for (let i = 0; i < bytes.byteLength; i++) binary += String.fromCharCode(bytes[i])
                        setImageLink(`data:image/webp;base64, ${btoa(binary)}`)
                    }
                }
            }
        }
    }
    useEffect(() => {
        if (refContainer.current !== null) {
            if (refContainer.current !== undefined) {
                setStreamWidth(refContainer.current.naturalWidth)
                setStreamHeight(refContainer.current.naturalHeight)
            }
        }
    }, [refContainer, setStreamWidth, streamWidth, imageLink, streamHeight, setStreamHeight])

    useEffect(() => {
        count = true
    }, [currentComp])
    return (
        <GUI.RefBlock>
            {streamWidth > streamHeight ?
                <GUI.RightBlockAlgorithmVideo ref={refContainer}
                src={imageLink ? imageLink : '/images/Mobile.svg'}
                alt="Video"/>
            :
                <GUI.RightBlockAlgorithmVideoRow ref={refContainer}
                    src={imageLink ? imageLink : '/images/Mobile.svg'}
                    alt="Video"/>}
        </GUI.RefBlock>
    )
}