import React, {ChangeEvent, useState} from 'react';
import {SuccessNotify, ErrorNotify} from "../../Notifies/Notifications";
import * as GUI from "../styles";
import {textAPI} from "../../services/TextService";
import {IText} from "../../interfaces/Text";
import {useNavigate} from "react-router-dom";
import * as UI from "../style"
export const CreateText = () => {
    const navigate = useNavigate()
    const [algorithmId, setAlgorithmId] = useState<number | any>()
    const [cycle_id, setCycle_id] = useState<number | any>()
    const [text, setText] = useState<string | any>()
    const [queue_num, setQueue_num] = useState<number | any>()
    const algorithmIdChoice = (event: ChangeEvent<HTMLInputElement>) => setAlgorithmId(event.target.value)
    const cycle_idChange = (event: ChangeEvent<HTMLInputElement>) => setCycle_id(event.target.value)
    const text_Change = (event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)
    const queue_numChange = (event: ChangeEvent<HTMLInputElement>) => setQueue_num(event.target.value)
    const [createText] = textAPI.useCreateTextMutation()
    const data = {
        "algorithm": algorithmId,
        "body": {queue_num: queue_num,
            cycle_id: cycle_id,
            text: text}
    }
    function toNavigate() {
        navigate('/textes')
    }
    const createNewText = async () => {
        try {
            await createText(data as IText)
            SuccessNotify('Text created!')
            setTimeout(toNavigate, 500)
        } catch (error) {
            ErrorNotify('Text not created:( Try again later, please.')
            setTimeout(toNavigate, 500)
        }
    }

    return (
        <GUI.ContainerMainList>
            <GUI.AboutBlock>
                Create Text
            </GUI.AboutBlock>
            <UI.FormAlgorithm>
                <GUI.CreateMain>
                    <GUI.CreateMini>
                        <GUI.InputNumber
                            type="number" placeholder="Algorithm ID"
                            name={algorithmId} onChange={algorithmIdChoice}>
                        </GUI.InputNumber>
                        <GUI.InputNumber
                            type="number" placeholder="Queue num"
                            name={queue_num} onChange={queue_numChange}>
                        </GUI.InputNumber>
                        <GUI.InputNumber
                            type="number" placeholder="Cycle Id"
                            name={cycle_id} onChange={cycle_idChange}>
                        </GUI.InputNumber>
                    </GUI.CreateMini>
                </GUI.CreateMain>
                <GUI.InputTextMini
                    type="text" placeholder="Text"
                    name={text} onChange={text_Change}>
                </GUI.InputTextMini>
                <GUI.ButtonBlue
                    type="button"
                    onClick={createNewText}>
                    Create
                </GUI.ButtonBlue>
            </UI.FormAlgorithm>
        </GUI.ContainerMainList>
    )
}