import {createApi} from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery} from "@reduxjs/toolkit/query";
import {_baseUrl} from "./index";

export const filesAPI = createApi({
    reducerPath: 'filesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrl,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['File'],
    endpoints: (builder) => ({
        uploadFile: builder.mutation<any, any>({
            query: (data) => ({
                url: `/files/file/multiple_upload/`,
                method: `POST`,
                body: data
            })
        }),
        uploadScreenshot: builder.query<any, any>({
            query: (data) => ({
                url: `/files/file/download_screenshot/${data.username}/${data.alg_id}/${data.remote}/`,
                method: 'GET',
                responseHandler: (response: Response) => response.blob()
            }),
        }),
        showPreview: builder.query<any, any>({
            query: (filename) => ({
                url: `files/file/preview/${filename}/`,
                method: 'GET',
                responseHandler: (response: Response) => response.blob()
            })
        })
    }),
})