import { createApi } from "@reduxjs/toolkit/query/react";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { _baseUrlAPI } from "./index";

export const remoteAPI = createApi({
    reducerPath: 'remoteAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: _baseUrlAPI,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${localStorage.getItem('token')}`)

            return headers
        }
    }),
    tagTypes: ['Remote'],
    endpoints: (builder) => ({
        fetchAllRemotes: builder.query({
            query: () => ({
                url: `remotes/`,
                method: 'GET',
            })
        }),
        statusAlgorithm: builder.mutation<any, any>({
            query: ({data, currentComp}: any) => ({
                url: `/remotes/set_runtime_statuses/${currentComp}/`,
                method: 'POST',
                body: data
            })
        }),
        // takeScreenshotAlgorithm: builder.mutation<any, any>({
        //     query: (data) => ({
        //         url: `/algorithms/${data.id}/set_screenshot_status/`,
        //         method: 'POST',
        //         body: data.stop_status
        //     })
        // })
        getRemoteStatus: builder.query({
            query: (remote) => ({
                url: `remotes/get_runtime_statuses/${remote}/`,
                method: 'GET'
            })
        })
    }),
})
export const { useFetchAllRemotesQuery } = remoteAPI