import React, {Fragment, useState, useEffect, Suspense} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Header} from "./components/Header";
import {AlgorithmList} from "./components/AlgorithmList";
import {CreateAlgorithm} from "./components/CreateAlgorithm";
import {AlgorithmCard} from "./components/AlgorithmCard";
import {TextList} from "./components/TextList";
import {CreateText} from "./components/CreateText";
import {LoginPage} from "./components/LoginPage";
import {ProtectedRoute} from "./components/Protected";
import {RegisterUser} from "./components/RegisterUser";
import {MacroList} from "./components/MacroList";
import {CreateMacro} from "./components/CreateMacro";
import {MacroCard} from "./components/MacroCard";
import {MainPage} from "./components/MainPage";
import {ForgotPassword} from "./components/ForgotPassword";
import {ConfirmReset} from "./components/ForgotPassword/confirm";
import {ResetPassword} from "./components/ForgotPassword/reset";
import {ChangePassword} from "./components/ChangePassword";
import {Profile} from "./components/Profile";
import {getTheme} from "./index";
import * as UI from ".//components/style"
import {Updates} from "./components/Profile/Updates";
import {Instruction} from "./components/Others/Instruction";
import {PhonemapsList} from "./components/SimCardsList";
import {CreatePhonemaps} from "./components/CreateSimCard";
import {PhonemapsCard} from "./components/SimCardsCard";
import {TemplatesList} from "./components/Templates/TemplatesList";
import {TemplateCreate} from "./components/Templates/TemplateCreate";
import {TemplateCard} from "./components/Templates/TemplateCard";
import {SimBank} from "./components/SimBank";
const App = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isAuth, setIsAuth] = useState<boolean>(true)
    const themeState: string = localStorage.getItem('theme') || "Light"
    const [ theme, setTheme ] = useState(themeState)
    const username: string = localStorage.getItem('username') || 'none'
    const changeTheme = () => {
        if(theme === 'Light') {
            localStorage.setItem("theme", "Dark")
            setTheme("Dark")
            getTheme()
        } else {
            localStorage.setItem("theme", "Light")
            setTheme("Light")
            getTheme()
        }
    }

    useEffect(() => {
        token ? setIsAuth(true) : setIsAuth(false)
    }, [token, theme])
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Fragment>
                    <Header isAuth={isAuth}
                            setToken={setToken}
                            getTheme={getTheme}
                            changeTheme={changeTheme}
                            theme={theme}
                    />
                    <UI.Body background={getTheme().background} color={getTheme().colorFont}>
                        <Routes>
                            <Route path="/login" element={<LoginPage isAuth={isAuth} setToken={setToken}/>}/>
                            <Route path="/reset" element={<ForgotPassword/>}/>
                            <Route path="/reset/confirm" element={<ConfirmReset/>}/>
                            <Route path="/reset/create" element={<ResetPassword/>}/>
                            <Route path="/register" element={<RegisterUser token={token}/>}/>
                            <Route path="/" element={<MainPage/>}/>
                            <Route path="/" element={<ProtectedRoute isAuth={isAuth}/>}>
                                <Route path="/algorithms" element={<AlgorithmList/>}/>
                                <Route path="/algorithms/create" element={<CreateAlgorithm/>}/>
                                <Route path="/algorithms/:id" element={<AlgorithmCard setToken={setToken}/>}/>
                                <Route path="/textes/" element={<TextList/>}/>
                                <Route path="/textes/create" element={<CreateText/>}/>
                                <Route path="/phonemaps/:id" element={<PhonemapsCard/>}/>
                                <Route path="/phonemaps" element={<PhonemapsList/>}/>
                                <Route path="/phonemaps/create" element={<CreatePhonemaps/>}/>
                                <Route path="/macroses" element={<MacroList/>}/>
                                <Route path="/macroses/create" element={<CreateMacro/>}/>
                                <Route path="/macroses/:id" element={<MacroCard/>}/>

                                <Route path="/templates" element={<TemplatesList/>}/>
                                <Route path="/template/create" element={<TemplateCreate/>}/>
                                <Route path="/templates/:id" element={<TemplateCard/>}/>
                                <Route path="/profile/" element={<Profile username={username}/>}/>
                                <Route path="/profile/change-password/" element={<ChangePassword/>}/>
                                <Route path="/updates/" element={<Updates/>}/>
                                <Route path="/instruction/" element={<Instruction/>}/>

                                <Route path="/simbank" element={<SimBank/>}/>
                            </Route>
                        </Routes>
                    </UI.Body>
                </Fragment>
            </Router>

        </Suspense>
    );
}
export default App;
