import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'
import * as UI from './style'
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {userAPI} from "../../services/UserService";
import {IAuth} from "../Header";
import {Spinner} from "../Spinner";
import {useForm} from "react-hook-form";
import {numbersAPI} from "../../services/SimcardsService";
import * as GUI from "../style";
import {useTranslation} from "react-i18next";
import {getTheme} from "../../index";

export const LoginPage = ({isAuth, setToken} : IAuth) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [setUser] = userAPI.useSetUserMutation({fixedCacheKey: 'shared-auth-data'})
    const { register, handleSubmit, watch } = useForm()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const username = watch('username')
    const password = watch('password')
    const submitForm = () => {
            setIsLoading(true)
            setUser({ username, password }).then((data) => {
                setToken(localStorage.getItem('token'))
                localStorage.setItem("username", username)
                setIsLoading(false)
                if (data.hasOwnProperty('error')) {
                    ErrorNotify('Произошла ошибка! Возможно логин или пароль введены некорректно, либо вы не заполнили поля!')
                } else {
                    SuccessNotify('Добро пожаловать!')
                }
            })
    }
    useEffect(() => {
        if (isAuth) {
            navigate('/algorithms')
        }
    }, [isAuth, navigate])

    return (
        <>
            <GUI.DefaultBlock>
                {isLoading ?
                    <Spinner/>
                    :
                    <>
                        <GUI.RegisterLoginBlock>
                            <GUI.AboutBlockCenter>{t("Login")}</GUI.AboutBlockCenter>
                            <UI.LoginPage>
                                <UI.InputDataBlock>
                                    <UI.LoginForm onSubmit={handleSubmit(submitForm)}>
                                        <UI.InputBlock>
                                            <UI.LabelItem style={{color: "black"}}>{t("LogIn")}</UI.LabelItem>
                                            <UI.InputItem type="text" placeholder="Me"
                                                          required {...register('username')}/>
                                            <UI.LabelItem style={{color: "black"}}>{t("Password")}</UI.LabelItem>
                                            <UI.InputItem type="password" placeholder="*****"
                                                          required {...register('password')}/>
                                        </UI.InputBlock>
                                        <GUI.DefaultButton width="385px" height="32px" style={{marginRight: "10px"}}
                                                          color={getTheme().lightStyleBackground}
                                                          background={getTheme().darkGreen}
                                                          type="submit"> {t("SignIn")}
                                        </GUI.DefaultButton>
                                        <hr/>
                                        <UI.ItemLink style={{color: "#29C570"}} to="/reset">{t("ForgotPassword")}</UI.ItemLink>
                                        <hr/>
                                        <GUI.InformationLogin style={{color: "black"}}>
                                        {t("NoAccount")}
                                        <UI.ItemLink style={{color: "#29C570"}} to="/register"> {t("CreateOne")}</UI.ItemLink>
                                        </GUI.InformationLogin>
                                    </UI.LoginForm>
                                </UI.InputDataBlock>
                            </UI.LoginPage>
                        </GUI.RegisterLoginBlock>
                    </>
                }
            </GUI.DefaultBlock>
            <GUI.InformationPageLogin>
                <a href="tel:+79990012255">8-999-001-22-55</a>
                <a href="mailto:somemail@gmail.com">somemail@gmail.com</a>
                <GUI.InformationLogin>Some address</GUI.InformationLogin>
            </GUI.InformationPageLogin>
        </>
    )
}