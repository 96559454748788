import React from 'react';
import * as GUI from "../styles"

export const CreateText = () => {
    return (
        <GUI.LinkBlue
            to="/textes/create">
            Create Text
        </GUI.LinkBlue>
    )
}