import styled from "styled-components";
import {Link} from "react-router-dom";

// сделать для телефонов центрирование карточек.




export const CardTopButtonPlayButton = styled.button`
  background: none;
  border: none;
`

export const CardTopButtonSettingsButton = styled.button`
  background: #553AC1;
  border-radius: 2px;
  padding: 8px 14px;
  color: inherit;
  border: none;
  cursor: pointer;
`

export const ModalButtonSubmitButton = styled.button`
  font-size: medium;
  height: 40px;
  width: 140px;
  background: #553AC1;
  border-radius: 2px;
  padding: 8px 14px;
  color: inherit;
  border: solid black 0.1px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
`

export const ModalButtonCancelButton = styled.button`
  font-size: medium;
  height: 40px;
  width: 140px;
  background: white;
  border-radius: 2px;
  padding: 8px 14px;
  color: inherit;;
  border: solid black 0.1px;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
`

export const RemoteListInModal = styled.div`
  margin-top: 1.5em;
`
export const CardAboutName = styled.div`
  margin-bottom: 20px;
  text-align: center;
`


// Name & Status

export const CardTopButtonAboutBlock = styled.div`
  text-transform: capitalize;
  display: flex; 
  box-sizing: border-box;
  
`

export const CardTopButtonAboutBlockStatus = styled.span`

  color: inherit;
  background: #FF5963;
  border-radius: 70px;
  padding: 6px 10px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
`



export const CardDescriptionText = styled.span`
  color: inherit;
`

export const CardDescriptionDropdown = styled.img`
    
`

export const CardItemNewAlgorithmLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  text-decoration: none;
`
export const CardItemNewAlgorithmText = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 24.2px;
  color: inherit;
  margin-top: 1em;
`
