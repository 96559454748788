import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {ButtonNewSimCard} from "./create";
import {Spinner} from "../Spinner";
import {useTranslation} from "react-i18next";
import * as MUI from "../style";
import {getTheme} from "../../index";
import {numbersAPI} from "../../services/SimcardsService";
export const PhonemapsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {data: phonemaps, isLoading} = numbersAPI.useFetchAllNumbersQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const goToMacro = (id: any) => {
        navigate(`/phonemaps/${id}`)
    }
    useEffect(() => {}, [phonemaps])
    if (isLoading) return <Spinner/>
    return (
        <MUI.DefaultBlock>
            <MUI.DefaultContainer>
                <ButtonNewSimCard/>
                <MUI.Cards>
                    {phonemaps && phonemaps.map((card: any, index: number) => {
                        const { id, name } = card
                        return (
                            <MUI.Card key={index} background={getTheme().lightStyleBackground}>
                                <MUI.CardItem>
                                    <MUI.DefaultSpan>#{id}</MUI.DefaultSpan>
                                    <MUI.CardBlock>
                                        <MUI.DefaultSpan>
                                            {name}
                                        </MUI.DefaultSpan>
                                    </MUI.CardBlock>
                                    <MUI.CardBlock>
                                        <MUI.DefaultButton width="100px" height="32px"
                                                           color={getTheme().greenLight}
                                                           background={getTheme().darkGreen}
                                                           style={{margin: 0}}
                                                           type="button"
                                                           onClick={() => goToMacro(id)}>
                                            {t("Settings")}
                                        </MUI.DefaultButton>
                                    </MUI.CardBlock>
                                </MUI.CardItem>
                            </MUI.Card>
                        )
                    })}
                </MUI.Cards>
            </MUI.DefaultContainer>
        </MUI.DefaultBlock>
    )
}