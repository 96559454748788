import React, {ChangeEvent, useEffect, useState} from 'react';
import * as GUI from "../styles";
import {useParams} from "react-router-dom";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../Spinner";
import {ImportMacros,ModalRemove} from "../Modal/modal";
import * as UI from "../AlgorithmList/style";
import {AboutBlock} from "../Blocks/Blocks";
import {useForm} from "react-hook-form";
import {macroAPI} from "../../services/MacroService";
import {MacroCore} from "../MacroCore";
import {useTranslation} from "react-i18next";
import * as MUI from "../style"
import {getTheme} from "../../index";

export const MacroCard = () => {
    const [ activeImport, setActiveImport ] = useState<boolean>(false)
    const { t } = useTranslation();
    const [modalActive, setModalActive] = useState(false)
    const { id }: any = useParams()
    const navigate = useNavigate()
    const {data: macro, isLoading, isFetching} =
        macroAPI.useGetMacroQuery(id, {
            refetchOnMountOrArgChange: true,
            pollingInterval: 10000,
            skip: false})
    const [deleteMacr] = macroAPI.useDeleteMacroMutation()
    const file = macroAPI.useExportMacroQuery(id, {
        refetchOnMountOrArgChange: true})
    const getAlgorithmData = () => {
        const data = new Blob([file.data], {
            type: 'application/json'
        })
        const binURL = window.URL.createObjectURL(data)
        const tempLink = document.createElement('a')
        tempLink.href = binURL;
        tempLink.setAttribute('download', `${macro.name.replace(/ /ig, '_')}_core_${id}.json`)
        tempLink.click()
    }
    const removeMacro = async () => {
        try {
            await deleteMacr(id)
            SuccessNotify('MacroCore has deleted')
            navigate('/macroses')
        } catch (error) {
            ErrorNotify(`Macro has not deleted! Reason: ${error}`)
        }
    }
    const { register } = useForm()
    const [nameMacr, setNameMacr] = useState(macro?.name)
    useEffect(() => {
        if(!isFetching){
            setNameMacr(macro?.name)
        }
    }, [macro, isFetching])
    if (isLoading) return <Spinner/>
    if (!macro) return <div>Error</div>
    const showModal = () => {
        setModalActive(true)
    }
    const ModalItem = () => {
        return  <ModalRemove active={modalActive} setActive={setModalActive}>
            <UI.CardAboutName>
                <MUI.DefaultSpan style={{color: getTheme().colorFont}}>
                    {t("areYouSure")}
                </MUI.DefaultSpan>
            </UI.CardAboutName>
            <GUI.DefaultBlockFlex>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => removeMacro()}> {t("delete")}
                </MUI.DefaultButton>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => setModalActive(!modalActive)}> {t("cancel")}
                </MUI.DefaultButton>
            </GUI.DefaultBlockFlex>
        </ModalRemove>
    }
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => setNameMacr(event.target.value)

    return (
        <MUI.MainBlock>
                <ModalItem/>
                <ImportMacros activeImport={activeImport}
                              setActiveImport={setActiveImport} id={id}/>
                <MUI.DefaultAlgorithm>
                    <GUI.TopBlockAlgorithm>
                        <GUI.DefaultBlock>
                            <MUI.TopBlockAlgorithmSettings>
                                <MUI.TopBlockAlgorithmTopPart>
                                    <AboutBlock name='Settings'/>
                                    <GUI.TopBlockAlgorithmButtons>
                                        <GUI.BlockAlgorithmButton
                                            type="button"
                                            onClick={() => getAlgorithmData()}>
                                            <MUI.CardButtonButtonSVG
                                                src="/images/Export.svg"
                                                title="Export core"
                                                alt="Reload Algorithm"/>
                                        </GUI.BlockAlgorithmButton>
                                        <GUI.BlockAlgorithmButton
                                            type="button"
                                            onClick={() => setActiveImport(true)}>
                                            <MUI.CardButtonButtonSVG
                                                src="/images/Import.svg"
                                                title="Import core"
                                                alt="Reload Algorithm"/>
                                        </GUI.BlockAlgorithmButton>
                                        <GUI.BlockAlgorithmButton
                                            type="button"
                                            onClick={() => showModal()}>
                                            <MUI.CardButtonButtonSVG
                                                src="/images/Remove.svg"
                                                alt="Remove Algorithm"/>
                                        </GUI.BlockAlgorithmButton>
                                    </GUI.TopBlockAlgorithmButtons>
                                </MUI.TopBlockAlgorithmTopPart>
                            </MUI.TopBlockAlgorithmSettings>
                            <GUI.SecondBlockAlgorithmMainInfo>
                                <MUI.InputStep color={getTheme().inputColor}
                                               style={{width: "200px", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                    type="text" placeholder={macro.name} maxLength={15}
                                    {...register('macro.name', {
                                        value: macro.name,
                                        onChange: nameChange
                                    })}
                                />
                            </GUI.SecondBlockAlgorithmMainInfo>
                        </GUI.DefaultBlock>
                    </GUI.TopBlockAlgorithm>
                </MUI.DefaultAlgorithm>
                <MacroCore nameMacr={nameMacr}/>
        </MUI.MainBlock>
    )
}