import React, {useState, ChangeEvent, useEffect} from 'react';
import * as GUI from '../styles';
import { useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import {algorithmAPI} from "../../services/AlgorithmService";
import { ErrorNotify, SuccessNotify } from "../../Notifies/Notifications";
import "./button-styles.css";
import {ModalGetGoogleSheetsData} from "../Modal/modal";
import {macroAPI} from "../../services/MacroService";
import {useTranslation} from "react-i18next";
import {Core} from "../Core";
import * as UI from "../style"
import {getTheme} from "../../index";
import {DragDropContext} from '@hello-pangea/dnd';
import * as MUI from "../style";
import {updateForAdd} from "../../Validators/updaterForCheckerAndGoto";

export const AlgorithmCore = ({nameAlg, descAlg, setCore}: any) => {
    const { t } = useTranslation();
    const { id } = useParams()
    const navigate = useNavigate()
    const [active, setActive] = useState(false)
    const [createMacro] = macroAPI.useCreateMacroMutation()
    const { algorithm } = algorithmAPI.useGetAlgorithmQuery(id, {
        refetchOnMountOrArgChange: true,
        skip: false,
        selectFromResult: ({ data }) => ({
            algorithm: data ? data : {
                id: 0,
                name: 'Name',
                description: 'Description',
                user_id: 1,
                create_dt: '0',
                steps_count: 0,
                success_steps_count: 0,
                core: {}
            }
        })
    })
    const {data: macro} = macroAPI.useFetchAllMacrosQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const [updateAlg] = algorithmAPI.useUpdateAlgorithmMutation()
    const [createAlg] = algorithmAPI.useCreateAlgorithmMutation()
    const { user_id, core } = algorithm
    const updateAlgorithm = async (newData: any) => {
        let cannotUpdate = false
        // const dataList = newData.list
        // for (let field of dataList) {
        //     try {
        //         if (field.macros) {
        //             field.macros = JSON.parse(field.macros)
        //         }
        //     } catch (e) {
        //         ErrorNotify(`Error update: ${e}`)
        //     }
        //     setCore(newData.list)
        // }
        const data = {name : nameAlg, description: descAlg, user_id: Number(user_id), success_steps_count: 0, core: newData}
        try {
            for (let item of newData['list']) {
                if (item.checker) {
                    const splitChecker = item?.checker.split('')
                    const indexSlash = splitChecker.indexOf('/')
                    const numberOfChecker = splitChecker.slice(indexSlash + 1).join('')
                    console.log(typeof numberOfChecker)
                    if (numberOfChecker === '-1') {
                        cannotUpdate = true
                    break
                    }
                } else if (item.go_to === '-1') {
                    cannotUpdate = true
                    break
                } else {
                    cannotUpdate = false
                }
            }
            if (cannotUpdate) ErrorNotify('You need update checker or Go to!')
            else {
                await updateAlg({data, id})
                SuccessNotify('Updated!')
                navigate(`/algorithms/${id}/`)
            }
        } catch {
            ErrorNotify('Algorithm has not updated!')
        }
    }
    const duplicateAlgorithm = async () => {
        const data = {
            id: 0,
            name: `${algorithm.name} duplicate`,
            description: algorithm.description,
            user_id: 1,
            create_dt: '0',
            steps_count: 0,
            success_steps_count: 0,
            core: algorithm.core
        }
        try {
            await createAlg(data)
            SuccessNotify('Algorithm created! Gratz')
            navigate('/algorithms')
        } catch {
            ErrorNotify('Sorry, algorithm not created :( please, try later')
            navigate('/algorithms')
        }
    }

    const createMacros = () => {
        const data = {
            name: name,
            sub_core: {
                list: algorithm.core.list.slice(Number(start), Number(end)+1)
            }
        }
        try {
            createMacro(data).then(() => setActive(false))
            SuccessNotify('Created')
        } catch (error) {
            ErrorNotify(`Error: ${error}`)
        }
    }
    const {handleSubmit, register, control, setValue } =
        useForm({defaultValues: {list: Object.assign(core)['list']}})
    const {fields, insert, remove, update, move, append} =
        useFieldArray({control, name: "list"})
    const [name, setName] = useState<string>('Name')
    const [start, setStart] = useState<any>(0)
    const [end, setEnd] = useState<any>(algorithm.core.list.length)
    const nameChange = (event: ChangeEvent<HTMLInputElement>)  => setName(event.target.value)
    const startChange = (event: ChangeEvent<HTMLInputElement>)  => setStart(event.target.value)
    const endChange = (event: ChangeEvent<HTMLInputElement>)  => setEnd(Number(event.target.value))
    const handleDrag = ({ source, destination }:any) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };


    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        setCore(fields)
    }, [fields, setCore, updateForAdd])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])
    return (
        <UI.FormAlgorithm  onSubmit={handleSubmit(updateAlgorithm)}>
            <ModalGetGoogleSheetsData active={active} setActive={setActive}>
                <GUI.DivBlockBank >
                    <GUI.StructureCoreAboutText style={{color: getTheme().colorFont, marginLeft: "0"}}>
                        {t("MacrosName")}
                    </GUI.StructureCoreAboutText>
                    <GUI.InputTextOnWhite maxLength={25} type="text" placeholder={name} onChange={nameChange}/>
                    <GUI.DefaultBlockFlex>
                        <GUI.BlockForInput>
                            <GUI.BlockNumbers style={{color: getTheme().colorFont}}>{t("Start")}</GUI.BlockNumbers>
                            <UI.InputStep color={getTheme().colorFont}
                                          style={{textAlign: "center", padding: "0", width: "60px"}}
                                type="number"
                                min="0"
                                max={algorithm.core.list.length}
                                defaultValue={start}
                                onChange={startChange}/>
                        </GUI.BlockForInput>
                        <GUI.BlockForInput>
                            <GUI.BlockNumbers style={{color: getTheme().colorFont}}>{t("End")}</GUI.BlockNumbers>
                            <UI.InputStep color={getTheme().colorFont}
                                          style={{textAlign: "center", padding: "0", width: "60px"}}
                                type="number"
                                min={Number(start)}
                                max={algorithm.core.list.length}
                                defaultValue={start}
                                onChange={endChange}/>
                        </GUI.BlockForInput>
                    </GUI.DefaultBlockFlex>
                    <MUI.DefaultButton width="140px" height="40px"
                                       color={getTheme().greenLight}
                                       background={getTheme().darkGreen}
                                       type="button" onClick={() => createMacros()}> {t("Create")}
                    </MUI.DefaultButton>
                </GUI.DivBlockBank>
            </ModalGetGoogleSheetsData>
            <UI.StructureAlgorithmBlock>
                <UI.AboutBlock> {t("Structure")} </UI.AboutBlock>
                <UI.CardBlockStart>
                    <UI.DefaultButton width="200px" height="48px"
                                      style={{marginRight: '10px',
                                          position: scrollPosition > 1000 ? 'fixed' : 'relative',
                                            bottom: scrollPosition > 1000 ? '0' : '',
                                            right: scrollPosition > 1000 ? '0' : ''}}
                                      color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                                      type="submit"> {t("Update")}
                    </UI.DefaultButton>
                    <UI.DefaultButton width="200px" height="48px" style={{marginRight: "10px"}}
                                      color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                                      type="button" onClick={() => setActive(true)}> {t("CreateMacros")}
                    </UI.DefaultButton>
                    <UI.DefaultButton width="200px" height="48px" style={{marginRight: "10px"}}
                                      color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                                      type="button" onClick={() => duplicateAlgorithm()}> {t("CreateDupl")}
                    </UI.DefaultButton>
                </UI.CardBlockStart>
                <DragDropContext onDragEnd={handleDrag}>
                    <Core
                        fields={fields} insert={insert} remove={remove}
                        register={register} control={control}
                        setValue={setValue} update={update} macro={macro}/>
                </DragDropContext>
            </UI.StructureAlgorithmBlock>
        </UI.FormAlgorithm>
    )
}