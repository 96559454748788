import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { setupStore } from './app/store';
import App from './App';
import './index.css';

const store = setupStore()
const container = document.getElementById('root')!;
const root = createRoot(container);

const colorGreen: string = '#29C570';
const colorLight: string = '#F9F9FC';
const colorDark: string = '#202125';
const colorWarn: string = '#FF5963';
const lightGreen: string = '#8BCC00';
const lightDark: string = '#36373B';
export const getTheme = (theme: any = localStorage.getItem("theme")) => {
    return {
        background: theme === 'Dark' ? colorDark : colorLight,
        backgroundTheme: theme === 'Dark' ? 'none' : '#E9EAF9',
        backgroundLang: theme === 'Dark' ? 'none' : 'rgba(0, 0, 0, 0)',
        backgroundProfile: theme === 'Dark' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)',
        profileLine: theme === 'Dark' ? 'rgba(255, 255, 255, 0.14)' : 'rgba(0, 0, 0, 0.14)',
        colorFontProfile: theme === 'Dark' ? 'rgba(255, 255, 255, 0.98)' : 'rgba(0, 0, 0, 0.98)',
        colorFont: theme === 'Dark' ? 'white' : colorDark,
        colorFontModal: theme === 'Dark' ? colorDark : 'white',
        colorFontDark: theme === 'Dark' ? colorDark : colorDark,
        colorFontWhite: theme === 'Dark' ? 'white' : 'white',
        lightStyleBackground: theme === 'Dark' ? lightDark : colorLight,
        inputColor: theme === 'Dark' ? colorLight : colorGreen,
        inputBackground: theme === 'Dark' ? lightDark : '#E9EAF9',
        darkGreen: theme === 'Dark' ? 'none' : colorGreen,
        greenLight: theme === 'Dark' ? colorLight : colorLight
    }
}
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
