import {IAlgorithm} from "../../interfaces/Algorithm";
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {_baseUrlAPI} from "../../services";

export interface IDataGetExecute {
    step?: number
    // google sheet JSON
    gs_json?: any | null,
}

export interface ISMSData {
    message?: string,
    algorithm: number
}
class AlgorithmAPIold {
    _apiBase: string = _baseUrlAPI;

    getResource = async(url: string) => {
        const res = await fetch(`${this._apiBase}${url}`)
            .then(response => response.json())
        return await res
    }

    setResource = async(data: any, url: string) => {
        const res = await fetch(`${this._apiBase}${url}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`

            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        })
        return await res.json();
    }
    setResourceInput = async(data: any, url: string) => {
        const res = await fetch(`${this._apiBase}${url}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/octet-stream',
                Authorization: `Bearer ${localStorage.getItem('token')}`

            },
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        })
        return await res.json();
    }
    putResource = async(data: any, url: string) => {
        try {
            const res = await fetch(`${this._apiBase}${url}`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(data)
            })
            return await res.json();
        } catch (err: any) {
            ErrorNotify(`Error: ${err}`)
        }
    }

    deleteResource = async(url: string) => {
        await fetch(`${this._apiBase}${url}`, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            referrerPolicy: 'no-referrer',
        })
    }

    getExecuteResource = async(url: string, dataAlg: any) => {
        const res = await fetch(`${this._apiBase}${url}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(dataAlg)
        })
            .then(response => response.json())
        return await res
    }

    getExecuteAlgorithm = async (id: number, path: string, data: any) => {
        if (path !== 'null') {
            try {
                const algorithm = await this.setResource(data, `algorithms/${id}/execute/1/remote/${path}/`)
                SuccessNotify('Algorithm Run!')
                return this._algorithm(algorithm)
            } catch (error) {
                ErrorNotify(`Error, sorry. Reason: ${error}`)
            } } else {
            ErrorNotify(`Please, select device from "Remote List"!`)
        }
    }

    getAllAlgorithms = async () => {
        const res = await this.getResource('/algorithms/')
        return res.map(this._algorithm)
    }
    postSendBankCode = async (data: ISMSData) => {
        try {
            SuccessNotify(`Success`)
            return await this.setResource(data, `bankcodes/`,)
        } catch (e: any) {
            ErrorNotify(`Error: ${e}`)
        }
    }
    getAlgorithm = async (id: any) => {
        const algorithm = await this.getResource(`/algorithms/${id}/`);
        return this._algorithm(algorithm)
    }

    updateAlgorithm = async (data: any, id?: string) => {
        const algorithm = await this.putResource(data,`/algorithms/${id}/`)
        SuccessNotify('Algorithm has updated!')
        return this._algorithm(algorithm)
    }

    deleteAlgorithm = async (id: string | undefined) => {
        await this.deleteResource(`/algorithms/${id}/`)
    }
    // _extractId = (item: any) => {
    //     const idRegExp = /\/([0-9]*)\/$/;
    //     return item.url.match(idRegExp)[0];
    // }
    setGoogleSheet = async (data: any) => {
        try {
            SuccessNotify(`Try get data!`)
            return await this.setResource(data, `algorithms/google_sheet/`).then(r => r)
        } catch (error) {
            ErrorNotify(`Error: ${error}`)
        }
    }
    _algorithm = (algorithm: IAlgorithm) => {
        return {
            id: algorithm.id,
            name: algorithm.name,
            user_id: algorithm.user_id,
            create_dt: algorithm.create_dt,
            steps_count: algorithm.steps_count,
            success_steps_count: algorithm.success_steps_count,
            core: algorithm.core,
        }
    }
}

export default AlgorithmAPIold;