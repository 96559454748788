import React, {ChangeEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm, useFieldArray} from "react-hook-form";
import * as GUI from "../styles";
import {SuccessNotify, ErrorNotify} from "../../Notifies/Notifications";
import "../AlgorithmCore/button-styles.css"
import { CreateMainInfo, TopAlgorithmSettings} from "../Blocks/Buttons";
import {algorithmAPI} from "../../services/AlgorithmService";
import {useTranslation} from "react-i18next";
import {Core} from "../Core";
import {macroAPI} from "../../services/MacroService";
import * as UI from "../style";
import {DragDropContext} from "@hello-pangea/dnd";
import * as MUI from "../style";
import {getTheme} from "../../index";

export const CreateAlgorithm = () => {
    const { t } = useTranslation();
    const [name, setName] = useState<string>('default Name')
    const [description, setDescription] = useState<string>('default Description')
    const {control, handleSubmit, register, setValue} = useForm({
        defaultValues: {
            list: [
                {home: 'Home'},
                {click_to_path: 'Click to path'},
                {input_text: 'Input text'},
            ]
        }
    })
    const {fields, insert, remove, swap, update, move} = useFieldArray({control, name: "list"})
    const navigate = useNavigate()
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.value.length === 25){
            ErrorNotify("Algorithm shouldn't exceed 25 characters")
          }
        setName(event.target.value)
    }
    const descriptionChange = (event: ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)
    const [createAlg] = algorithmAPI.useCreateAlgorithmMutation()
    const {data: macro} = macroAPI.useFetchAllMacrosQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const createAlgorithm = async (newData: object) => {
        const data = {
            "name": name,
            "description": description,
            "user_id": 1,
            "success_steps_count": 0,
            "core": newData
        }
        try {
            await createAlg(data)
            SuccessNotify('Algorithm created! Gratz')
            navigate('/algorithms')
        } catch {
            ErrorNotify('Sorry, algorithm not created :( please, try later')
            navigate('/algorithms')
        }
    }
    const handleDrag = ({ source, destination }:any) => {
        if (destination) {
            move(source.index, destination.index);
        }
    };

    return (
        <GUI.ContainerMainBlock>
            <UI.FormAlgorithm onSubmit={handleSubmit(createAlgorithm)}>
                <GUI.TopBlockAlgorithm>
                    <UI.DefaultBlock style={{marginBottom: '25px', position: 'relative', alignItems: 'flex-start'}}>
                        <TopAlgorithmSettings />
                        <MUI.DefaultSpan
                            style={{fontWeight: "400", color: 'gray', marginTop: "5px"}}>
                            {t("Name")}
                        </MUI.DefaultSpan>
                        <MUI.InputStep color={getTheme().colorFont}
                                       style={{width: "50%", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                       type="text" placeholder="Name" maxLength={15}
                                       onChange={nameChange}
                        />
                        <MUI.DefaultSpan
                            style={{fontWeight: "400", color: 'gray', marginTop: "15px"}}>
                            {t("Description")}
                        </MUI.DefaultSpan>
                        <MUI.InputStep color={getTheme().colorFont}
                                       style={{width: "50%", maxWidth: "100%", color: getTheme().colorFont, background: getTheme().inputBackground}}
                                       type="text" placeholder="Description"
                                       onChange={descriptionChange}
                        />
                    </UI.DefaultBlock>
                </GUI.TopBlockAlgorithm>
                    <GUI.DefaultBlock>
                    <UI.StructureAlgorithmBlock>
                        <GUI.AboutBlock>
                            {t("Structure")}
                        </GUI.AboutBlock>
                        <DragDropContext onDragEnd={handleDrag}>
                            <Core
                                fields={fields} insert={insert} remove={remove}
                                register={register} control={control}
                                setValue={setValue} update={update} macro={macro}/>
                        </DragDropContext>
                    </UI.StructureAlgorithmBlock>
                    </GUI.DefaultBlock>
            </UI.FormAlgorithm>
        </GUI.ContainerMainBlock>
    )
}
