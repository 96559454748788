import Notify from "simple-notify";
import 'simple-notify/dist/simple-notify.min.css'


export function SuccessNotify(text: string, autoTimeout = 2500) {
    new Notify({
        status: 'success',
        title: 'Yahoo!',
        text: text,
        effect: 'fade',
        speed: 300,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: autoTimeout,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'x-center bottom'
    })
}

export function CustomNotify(text: string, status: any) {
    new Notify({
        status: status || 'info',
        title: status || 'Notify',
        text: text,
        effect: 'fade',
        speed: 300,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 5000,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'x-center bottom'
    })
}
export function ErrorNotify(text: string, timeOut: number = 2500) {
    new Notify({
        status: 'error',
        title: 'Sad:(',
        text: text,
        effect: 'fade',
        speed: 300,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: timeOut,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'x-center bottom'
    })
}

export function DefaultNotify(text: string, timeOut: number = 2500) {
    new Notify({
        status: 'warning',
        title: '',
        text: text,
        effect: 'fade',
        speed: 300,
        showIcon: true,
        showCloseButton: true,
        autoclose: false,
        gap: 20,
        distance: 20,
        type: 1,
        position: 'x-center bottom'
    })
}