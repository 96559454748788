import React, {ChangeEvent, useEffect, useState} from 'react';
import * as GUI from "../styles";
import {useParams} from "react-router-dom";
import {CustomNotify, ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {useNavigate} from "react-router-dom";
import {algorithmAPI} from "../../services/AlgorithmService";
import {AlgorithmGoogleData} from "../AlgorithmTags";
import {AlgorithmCore} from "../AlgorithmCore";
import algorithmAPIold, {IDataGetExecute, ISMSData} from '../../api/AlgorithmAPI/AlgorithmAPI'
import {Spinner} from "../Spinner";
import {RemoteList} from "../RemoteList";
import {AlgorithmStream} from "../AlgorithmStream";
import {ImportAlgorithm, ModalBankCode, ModalRemove} from "../Modal/modal";
import * as UI from "../AlgorithmList/style";
import {ExecuteButton, StatusAlgorithmButton} from '../Blocks/Buttons';
import {AboutBlock, Tags} from "../Blocks/Blocks";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {coreValidation} from "../../Validators/coreValidation";
import * as MUI from "../style"
import {checkResponse} from "../../Validators/checkResponse";
import {getTheme} from "../../index";
import {DebugMode} from "./DebugMode";
import {remoteAPI} from "../../services/RemoteService";

export const AlgorithmCard = ({setToken}: any) => {
    const {t} = useTranslation();
    const [currentComp, setCurrentComp] = useState<string>()
    const [modalActive, setModalActive] = useState<boolean>(false)
    const [activeImport, setActiveImport] = useState<boolean>(false)
    const [remoteStatus, setRemoteStatus] = useState<string>("active");
    const [modalBankActive, setModalBankActive] = useState<boolean>(false)
    const [typeBankText, setTypeBankText] = useState<string>("password")
    const [stepAlg, setStepAlg] = useState<number>(0)
    const [smsCode, setSmsCode] = useState<string | number>()
    const [googleDataApi, setGoogleDataApi] = useState<any>()
    const [excelData, setExcelData] = useState<any>(null)
    const [core, setCore] = useState<any>()
    const [streamWidth, setStreamWidth] = useState<number>(0)
    const [streamHeight, setStreamHeight] = useState<number>(0)
    const [googleSheetAuth, setGoogleSheetAuth] = useState<any>(null)
    const [pollingTime, setPollingTime] = useState<number>(0)
    const [disabledBlock, setDisabledBlock] = useState<boolean>(false)
    const {id} = useParams()
    const {register, watch} = useForm();
    const navigate = useNavigate()
    const apiService = new algorithmAPIold()
    const [ messages, setMessages ] = useState<string[]>([])
    const checkSms = watch("checkSms")
    const [ getRemoteData ] = remoteAPI.useLazyGetRemoteStatusQuery()
    const [ remoteData, setRemoteData ] = useState<any>()
    const [debugMode, setDebugMode] = useState<boolean>(false)

    const [errorList, setErrorList] = useState<any>()
    useEffect(() => {

        const googleApiDataList: string[] = []
        if (typeof googleDataApi === "object") {
            for (const item in googleDataApi) {
                googleApiDataList.push(item)
            }
        }
        setErrorList(coreValidation(core, googleApiDataList))
    }, [googleDataApi, core])
    const [getRemoveAlgorithm] = algorithmAPI.useDeleteAlgorithmMutation()
    const [getExecuteAlgorithm] = algorithmAPI.useLazyExecuteAlgorithmQuery()
    const [setStatusAlgorithm] = remoteAPI.useStatusAlgorithmMutation()
    const {data: algorithm, isLoading, isFetching, refetch} =
        algorithmAPI.useGetAlgorithmQuery(id, {
            pollingInterval: pollingTime,
            refetchOnMountOrArgChange: true
        })
    const [nameAlg, setNameAlg] = useState<string>(algorithm?.name)
    const [descAlg, setDescAlg] = useState<string>(algorithm?.description)
    localStorage.setItem("hostname", "null")
    const removeAlgorithm = async () => {
        try {
            await getRemoveAlgorithm(id)
                .then(() => SuccessNotify('Algorithm deleted'))
                .then(() => navigate('/algorithms'))
        } catch (error) {
            ErrorNotify(`Algorithm not deleted! Reason: ${error}`)
        }
    }
    const execute = async () => {

        const data: any = {
            step: Number(stepAlg),
            gs_json: googleSheetAuth,
            xlsx_json: excelData
        }
        try {
            await getExecuteAlgorithm({id, currentComp, data})
                .then(response => checkResponse(response))
                .then(() => setRemoteStatus('busy'))
                .then(() => refetch())
                .then(() => getRemoteData(currentComp).then(r => setRemoteData(r.data)))
                .then(() => setPollingTime(1000))
        } catch (error) {
            ErrorNotify(`Failed run! Reason: ${error}`)
        }
    }
    const changeAlgorithmStatus = async () => {
        try {
            const data = {
                stop_runtime_status: !remoteData.stop_runtime_status,
            }
            await setStatusAlgorithm({data, currentComp})
                .then(response => checkResponse(response))
                .then(() => getRemoteData(currentComp).then(r => setRemoteData(r.data)))
                .then(() => refetch())
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
    }, [streamWidth, setStreamWidth])
    useEffect(() => {
        if (!isFetching) {
            setNameAlg(algorithm?.name)
            setDescAlg(algorithm?.description)
        }
        if (remoteData?.bank_code_status === true) {
            setModalBankActive(true)
        }
        if (remoteStatus === 'busy' && !remoteData?.stop_runtime_status) setPollingTime(5000)
        else setPollingTime(0);
    }, [algorithm, currentComp, setCurrentComp, remoteStatus, isFetching, remoteData?.bank_code_status])
    useEffect(() => {
        if (currentComp) {
            try {
                getRemoteData(currentComp).then(r => setRemoteData(r.data))
            } catch (e) {
                console.log('im try')
            }
        }
    }, [currentComp])
    if (isLoading) return <Spinner/>
    if (!algorithm) return <div>Error</div>
    const ModalItem = () => {
        return <ModalRemove active={modalActive} setActive={setModalActive}>
            <UI.CardAboutName>
                <MUI.DefaultSpan style={{color: getTheme().colorFont}}>
                    {t("areYouSure")}
                </MUI.DefaultSpan>
            </UI.CardAboutName>
            <GUI.DefaultBlockFlex>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => removeAlgorithm()}> {t("delete")}
                </MUI.DefaultButton>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => setModalActive(!modalActive)}> {t("cancel")}
                </MUI.DefaultButton>
            </GUI.DefaultBlockFlex>
        </ModalRemove>
    }
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => setNameAlg(event.target.value)
    const descriptionChange = (event: ChangeEvent<HTMLInputElement>) => setDescAlg(event.target.value)
    const stepChange = (event: ChangeEvent<HTMLInputElement>) => setStepAlg(Number(event.target.value))

    const sendSms = () => {
        const data: ISMSData = {
            "message": String(checkSms),
            "algorithm": Number(id)
        }
        try {
            apiService.postSendBankCode(data)
                .then(response => checkResponse(response))
                .then(() => refetch())
                .then(() => setModalBankActive(false))
        } catch (e: any) {
            ErrorNotify(`Error: ${e}`)
        }
    }
    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            const data: ISMSData = {
                "message": String(checkSms),
                "algorithm": Number(id)
            }
            try {
                apiService.postSendBankCode(data)
                    .then(response => checkResponse(response))
                    .then(() => refetch())
                    .then(() => setModalBankActive(false))
            } catch (e: any) {
                ErrorNotify(`Error: ${e}`)
            }
        }
    }
    return (
        <MUI.MainBlock>
            <ModalItem/>
            <ImportAlgorithm activeImport={activeImport} setActiveImport={setActiveImport} id={id}/>
            <ModalBankCode modalBankActive={modalBankActive} setModalBankActive={setModalBankActive}>
                <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <GUI.AboutBlock style={{color: getTheme().colorFont}}>Enter sms code</GUI.AboutBlock>
                    <GUI.BlockForInputBank style={{border: `1px solid ${getTheme().colorFont}`}}>
                        <GUI.InputBankSms
                            style={{background: getTheme().background}}
                            {...register("checkSms")}
                            onKeyPress={(event) => handleKeyPress(event)}
                            type={typeBankText} id="bank_sms" placeholder="******"
                        />
                        <GUI.ButtonImage
                            type="button"
                            onClick={() => setTypeBankText(typeBankText === 'password' ? 'text' : 'password')}>
                            <GUI.SVGLittle
                                src={typeBankText === 'password' ? "/images/eyeHide.svg" : "/images/eyeShow.svg"}
                                alt="Show"/>
                        </GUI.ButtonImage>
                    </GUI.BlockForInputBank>
                    <MUI.DefaultButton color={getTheme().colorFont} width="180px" height="40px"
                                       background={getTheme().background}
                                       type="button"
                                       onClick={() => sendSms()}>
                        {t("send")}
                    </MUI.DefaultButton>
                </div>
            </ModalBankCode>
            <MUI.DefaultAlgorithm>
                <GUI.TopBlockAlgorithm style={streamWidth <= streamHeight ?
                    {flexDirection: 'row', justifyContent: 'center'}
                    :
                    {flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {!debugMode ?
                        <GUI.DefaultBlock>
                            <MUI.TopBlockAlgorithmSettings>
                                <MUI.TopBlockAlgorithmTopPart>
                                    <AboutBlock name='Settings'/>
                                    <GUI.TopBlockAlgorithmButtons style={!disabledBlock ?
                                        {pointerEvents: 'auto'} : {pointerEvents: 'none'}}>
                                        <MUI.ProfileButton onClick={() => setDebugMode(!debugMode)}>
                                            {debugMode ? 'On' : 'Off'}
                                        </MUI.ProfileButton>
                                        <ExecuteButton
                                            remoteStatus={remoteStatus}
                                            id={id}
                                            algorithm={algorithm}
                                            refetch={refetch}
                                            currentComp={currentComp}
                                            remoteData={remoteData}
                                            execute={execute} setDisabledBlock={setDisabledBlock}/>
                                        <Tags id={id} setActiveImport={setActiveImport}
                                              algorithm={algorithm}
                                              setStatusAlgorithm={setStatusAlgorithm}
                                              remoteData={remoteData}
                                              currentComp={currentComp}
                                              refetch={refetch}/>
                                        <MUI.DefaultSpan
                                            style={{color: "#FF5963"}}
                                            onClick={() => setModalActive(!modalActive)}>
                                            {t("delete")}
                                        </MUI.DefaultSpan>
                                        <StatusAlgorithmButton
                                            remoteData={remoteData}
                                            remoteStatus={remoteStatus}
                                            changeAlgorithmStatus={changeAlgorithmStatus}/>
                                    </GUI.TopBlockAlgorithmButtons>
                                </MUI.TopBlockAlgorithmTopPart>
                                <MUI.DefaultSpan
                                    style={{fontWeight: "400", color: 'gray'}}>
                                    {t("StepForStart")}
                                </MUI.DefaultSpan>
                                <MUI.InputStep color={getTheme().inputColor} style={{
                                    color: getTheme().colorFont,
                                    background: getTheme().inputBackground
                                }}
                                               type="number"
                                               min="0"
                                               max={algorithm.core.list.length}
                                               defaultValue={stepAlg}
                                               onChange={stepChange}/>
                            </MUI.TopBlockAlgorithmSettings>
                            <MUI.DefaultSpan
                                style={{fontWeight: "400", color: 'gray', marginTop: "15px"}}>
                                {t("Name")}
                            </MUI.DefaultSpan>
                            <MUI.InputStep color={getTheme().inputColor}
                                           style={{
                                               width: "90%",
                                               maxWidth: "100%",
                                               color: getTheme().colorFont,
                                               background: getTheme().inputBackground
                                           }}
                                           type="text" placeholder={algorithm.name} maxLength={15}
                                           {...register('algorithm.name', {
                                               value: algorithm.name,
                                               onChange: nameChange
                                           })}
                            />
                            <MUI.DefaultSpan
                                style={{fontWeight: "400", color: 'gray', marginTop: "15px"}}>
                                {t("Description")}
                            </MUI.DefaultSpan>
                            <MUI.TextAreaStep style={{
                                width: "90%",
                                maxWidth: "100%",
                                height: "60px",
                                color: getTheme().colorFont,
                                background: getTheme().inputBackground
                            }}
                                              placeholder={algorithm.description}
                                              {...register('algorithm.description', {
                                                  value: algorithm.description,
                                                  onChange: descriptionChange
                                              })}
                            />
                            <RemoteList
                                currentComp={currentComp}
                                setCurrentComp={setCurrentComp}
                                setRemoteStatus={setRemoteStatus}/>
                            <AlgorithmGoogleData googleDataApi={googleDataApi}
                                                 setExcelData={setExcelData}
                                                 excelData={excelData}
                                                 setToken={setToken}
                                                 setGoogleDataApi={setGoogleDataApi}
                                                 setGoogleSheetAuth={setGoogleSheetAuth}/>
                        </GUI.DefaultBlock>

                        :

                        <GUI.DefaultBlock>
                            <MUI.TopBlockAlgorithmTopPart>
                                <AboutBlock name='Settings'/>
                                <GUI.TopBlockAlgorithmButtons style={!disabledBlock ?
                                    {pointerEvents: 'auto'} : {pointerEvents: 'none'}}>
                                    <MUI.ProfileButton onClick={() => setDebugMode(!debugMode)}>
                                        {debugMode ? 'On' : 'Off'}
                                    </MUI.ProfileButton>
                                    <ExecuteButton
                                        remoteStatus={remoteStatus}
                                        id={id}
                                        algorithm={algorithm}
                                        refetch={refetch}
                                        currentComp={currentComp}
                                        remoteData={remoteData}
                                        execute={execute} setDisabledBlock={setDisabledBlock}/>
                                    <Tags id={id} setActiveImport={setActiveImport}
                                          algorithm={algorithm}
                                          setStatusAlgorithm={setStatusAlgorithm}
                                          remoteData={remoteData}
                                          currentComp={currentComp}
                                          refetch={refetch}/>
                                    <MUI.DefaultSpan
                                        style={{color: "#FF5963"}}
                                        onClick={() => setModalActive(!modalActive)}>
                                        {t("delete")}
                                    </MUI.DefaultSpan>
                                    <StatusAlgorithmButton
                                        remoteData={remoteData}
                                        remoteStatus={remoteStatus}
                                        changeAlgorithmStatus={changeAlgorithmStatus}/>
                                </GUI.TopBlockAlgorithmButtons>
                            </MUI.TopBlockAlgorithmTopPart>
                            <RemoteList
                                currentComp={currentComp}
                                setCurrentComp={setCurrentComp}
                                setRemoteStatus={setRemoteStatus}/>
                            <DebugMode currentComp={currentComp} messages={messages}
                            setMessages={setMessages}/>
                        </GUI.DefaultBlock>
                    }
                    <AlgorithmStream currentComp={currentComp}
                                     streamWidth={streamWidth} setStreamWidth={setStreamWidth}
                                     streamHeight={streamHeight} setStreamHeight={setStreamHeight}/>
                </GUI.TopBlockAlgorithm>
            </MUI.DefaultAlgorithm>
            <MUI.DefaultContainer>
                <AlgorithmCore nameAlg={nameAlg} descAlg={descAlg} setCore={setCore}/>
            </MUI.DefaultContainer>
        </MUI.MainBlock>
    )
}