import React, {useEffect, useRef, useState} from 'react';
import * as UI from "../styles";
import { Menu } from './style';
import {SuccessNotify} from "../../Notifies/Notifications";
import {RouteProps, useLocation, useNavigate} from "react-router-dom"
import * as GUI from "../style";
import { useTranslation } from 'react-i18next';
import "../../app/i18next";
export interface IAuth extends RouteProps{
    isAuth: boolean
    setToken?: any
    getTheme?: any
    changeTheme?: any
    theme?: any
}

export const Header = ({isAuth, setToken, getTheme, changeTheme, theme}: IAuth) => {
    const { t, i18n } = useTranslation();
    const langState: string = localStorage.getItem("lang") || 'RU'
    const [ lang, setLang ] = useState(langState)
    const [open, setOpen] = useState<boolean>(false);
    const [heightMenu, setHeightMenu] = useState<string>('10px')
    const menuRef = useRef<any>(null)
    const profileRef = useRef<any>(null)
    const navigate = useNavigate()
    window.addEventListener('click', (e:any) => {
        if (e.target !== menuRef.current) {
            setOpen(false);
        }
    })
    const params = useLocation()
    const changeLanguage = () => {
        if (localStorage.getItem('lang') === 'RU') {
            i18n.changeLanguage("en")
                .then(() => setLang('EN'))
                .then(() => localStorage.setItem("lang", 'EN'))
        } else {
            i18n.changeLanguage("ru")
                .then(() => localStorage.setItem("lang", 'RU'))
                .then(() => setLang('RU'));
        }

    };
    const changeMenuStatus = () => {
        setOpen(!open);
        setHeightMenu(!open ? '100%' : '0')
    }
    const logout = () => {
        setOpen(false)
        localStorage.clear()
        setToken(null)
        SuccessNotify('До встречи!')
        navigate('/login')
    }
    const change = () => {
        setOpen(false)
        navigate('/profile/change-password/')
    }
    const userName = localStorage.getItem('username')
    useEffect(() => {
        if(!localStorage.getItem("lang")) {
            i18n.changeLanguage("ru")
                .then(() => localStorage.setItem("lang", "RU"))
        }
    }, [i18n, lang])
    if (!isAuth) return (
        <GUI.HeaderTransparentMain background={getTheme().background}>
            <GUI.Link href="/">
                <GUI.Logo color={getTheme().colorFont}>.algorithms</GUI.Logo>
            </GUI.Link>
            <GUI.Profile>
                <GUI.ProfileButtonsNotSign>
                    <GUI.ImageForButton bg={getTheme().backgroundTheme} padding="3px"
                        onClick={() => changeTheme()}
                        src={theme === 'Light' ? '/images/LightTheme.svg' : '/images/BlackTheme.svg'}>
                    </GUI.ImageForButton>
                    <GUI.ImageForButton bg={getTheme().backgroundLang} padding="3px"
                        onClick={() => changeLanguage()}
                        src={lang === 'RU' ? '/images/GreatRussia.svg' : '/images/Britain.svg'}>
                    </GUI.ImageForButton>
                    <GUI.ProfileButtonsListNotSignNoAccent color={getTheme().colorFont}>
                        <GUI.Link color={getTheme().colorFont} href="/login">{t("Login")}</GUI.Link>
                    </GUI.ProfileButtonsListNotSignNoAccent>
                    <GUI.ProfileButtonsListNotSignWithAccent>
                        <GUI.Link color={getTheme().colorFontWhite} href="/register">{t("SignUp")}</GUI.Link>
                    </GUI.ProfileButtonsListNotSignWithAccent>
                </GUI.ProfileButtonsNotSign>
            </GUI.Profile>
        </GUI.HeaderTransparentMain>
    )
    return (
        <GUI.HeaderTransparentMain background={getTheme().background}>
            <GUI.Link href="/" color={getTheme().colorFont}>
                <GUI.Logo color={getTheme().colorFont}>.algorithms</GUI.Logo>
            </GUI.Link>
            <Menu>
                <GUI.Link
                    style={{fontWeight: params.pathname === '/algorithms' ? "600" : "400"}}
                    href="/algorithms" color={getTheme().colorFont}>{t("textAlg")}</GUI.Link>
                <GUI.Link
                    style={{fontWeight: params.pathname === '/macroses' ? "600" : "400"}}
                    href="/macroses" color={getTheme().colorFont}>{t("textMacro")}</GUI.Link>
                <GUI.Link
                    style={{fontWeight: params.pathname === '/phonemaps' ? "600" : "400"}}
                    href="/phonemaps" color={getTheme().colorFont}>{t("textPhone")}</GUI.Link>
                <GUI.Link
                    style={{fontWeight: params.pathname === '/templates' ? "600" : "400"}}
                    href="/templates" color={getTheme().colorFont}>{t("Templates")}</GUI.Link>
                <GUI.Link
                    style={{fontWeight: params.pathname === '/simbank' ? "600" : "400"}}
                    href="/simbank" color={getTheme().colorFont}>{t("SimBank")}</GUI.Link>
            </Menu>
            <GUI.Profile>
                <GUI.ProfileButtonsNotSign>
                    <GUI.ImageForButton bg={getTheme().backgroundTheme} padding="3px"
                                        onClick={() => changeTheme()}
                                        src={theme !== 'Light' ? '/images/LightTheme.svg' : '/images/BlackTheme.svg'}>
                    </GUI.ImageForButton>
                    <GUI.ImageForButton bg={getTheme().backgroundLang} padding="3px"
                                        onClick={() => changeLanguage()}
                                        src={lang === 'RU' ? '/images/GreatRussia.svg' : '/images/Britain.svg'}>
                    </GUI.ImageForButton>
                    <GUI.ProfileButton
                        ref={menuRef}
                        onClick={() => changeMenuStatus()}
                        style={{backgroundImage: theme === 'Light' ? "url('/images/ProfileDark.svg')" : "url('/images/ProfileLight.svg')",backgroundSize:"cover", width:"24px", height:"24px"}}>
                    </GUI.ProfileButton>
                    <GUI.UserName color={getTheme().colorFont}>{userName}</GUI.UserName>
                    {open &&
                        <UI.AboutProfile ref={profileRef}>
                            <UI.NavMenu background={getTheme().backgroundProfile} height={heightMenu}>
                                <UI.AboutProfileNameLi color={getTheme().colorFont}>
                                    {t("Hello")}, {userName}!
                                </UI.AboutProfileNameLi>
                                <UI.AboutProfileLine border={getTheme().profileLine}/>
                                <UI.AboutProfileLi colorHover={getTheme().colorFontProfile}
                                                   color={getTheme().colorFont} onClick={() => navigate('/profile/')}>
                                    {t("profile")}
                                </UI.AboutProfileLi>
                                <UI.AboutProfileLi colorHover={getTheme().colorFontProfile}
                                                   color={getTheme().colorFont} onClick={() => change()}>
                                    {t("changePassword")}
                                </UI.AboutProfileLi>
                                <UI.AboutProfileLi colorHover={getTheme().colorFontProfile}
                                                   color={getTheme().colorFont} onClick={() => logout()}>
                                    {t("logOut")}
                                </UI.AboutProfileLi>
                                <UI.AboutProfileLi colorHover={getTheme().colorFontProfile}
                                                   color={getTheme().colorFont} onClick={() => navigate('/updates/')}>
                                    {t("Updates")}
                                </UI.AboutProfileLi>
                                <UI.AboutProfileLi colorHover={getTheme().colorFontProfile}
                                                   color={getTheme().colorFont} onClick={() => navigate('/instruction/')}>
                                    {t("Instruction")}
                                </UI.AboutProfileLi>
                                <UI.AboutProfileLiMobile>
                                    <UI.LinkRefMobile to="/algorithms">{t("textAlg")}</UI.LinkRefMobile>
                                </UI.AboutProfileLiMobile>
                                <UI.AboutProfileLiMobile>
                                    <UI.LinkRefMobile to="/macroses">{t("textMacro")}</UI.LinkRefMobile>
                                </UI.AboutProfileLiMobile>
                                <UI.AboutProfileLiMobile>
                                    <UI.LinkRefMobile to="/simcards">{t("textPhone")}</UI.LinkRefMobile>
                                </UI.AboutProfileLiMobile>
                                <UI.AboutProfileLiMobile>
                                    <UI.LinkRefMobile to="/templates">{t("Templates")}</UI.LinkRefMobile>
                                </UI.AboutProfileLiMobile>
                                <UI.AboutProfileLiMobile>
                                    <UI.LinkRefMobile to="/simbank">{t("SimBank")}</UI.LinkRefMobile>
                                </UI.AboutProfileLiMobile>
                            </UI.NavMenu>
                        </UI.AboutProfile>
                    }
                </GUI.ProfileButtonsNotSign>
            </GUI.Profile>

        </GUI.HeaderTransparentMain>
    )
}

