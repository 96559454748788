import styled from "styled-components";

export const TopBlockProfile = styled.div`
  margin-top: 3em;
  width: 1240px;
  height: 300px;
  border-radius: 15px;
  background: rgba(12, 10, 10, 0.95);
  color: white;
  padding: 3px;
  flex-wrap: wrap;
  background: linear-gradient(-90deg, rgba(42, 0, 56, 0.9), #000C1F, rgba(14, 11, 15, 0.9));
  background-size: 400% 400%;
  animation: gradient 7s ease infinite;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @keyframes gradient {
    0% {
      background-position: 0 25%;
    }
    25% {
      background-position: 25% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @media(max-width: 1260px) {
    max-width: 90%;
    max-height: 100%;
    height: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }  
`

export const BlockProfilePhoto = styled.div`
  margin: 10px;
  position: relative;
  width: 270px;
  height: 270px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  border: 3px solid white;
  @media(max-width: 300px) {
    width: auto;
  }
`

export const ProfilePhoto = styled.img`
  display: flex;
  justify-content: center;
  width: 150%;
  height: 150%;
  border-radius: 25px;
  object-fit: cover;
  @media(max-width: 300px) {
    max-width: 270px;
  }
`

export const ProfileUserData = styled.div`
  overflow: hidden;
  @media(max-width: 892px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const FlexBlock = styled.div`
  display: flex;
  margin: 0 5px;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

export const ButtonLight = styled.button`
  cursor: pointer;
  border: 2px solid white;
  border-radius: 15px;
  font-size: 1.2em;
  text-align: center;
  margin: 5px 10px 5px 0;
  background: none;
  color: white;
  font-weight: 600;
  :hover{
    background: rgba(255, 255, 255, 0.9);
    color: black;
    transition: 0.5s;
  }
`

export const InputInProfile = styled.input`
  height: 25px;
  background: none;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  margin: 10px 5px;
  font-size: 1.2em;
  background: rgba(0, 0 ,0 , 0.2);
  font-weight: 600;
  padding-left: 2px;
  max-width: 300px;
  :focus, ::placeholder{
    color: white;
    background: rgba(0, 0 ,0 , 0.2);
    border: none;
    border-radius: 0;
    font-weight: 600;
    padding-left: 2px;
  }
`

export const Label = styled.label`
  margin-left: 3px;
`

export const DefaultForm = styled.form`
  width: 1240px;
  height: 300px;
  color: white;
  padding: 3px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 1260px) {
    max-width: 90%;
    max-height: 100%;
    height: auto;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
`