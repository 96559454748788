import React, {useEffect} from 'react';
import * as GUI from "../styles";
import {CreateText} from "./create";
import {useGetTextesQuery} from "../../services/TextService";

export const TextList = () => {
    const {data: textes, isLoading, error} = useGetTextesQuery(10)
    useEffect(() => {}, [textes])
    if(isLoading) return (<span>Loading Textes...</span>)
    if(error) return (<span>Error</span>)
    const test = Object.assign(textes)

    return (
        <GUI.ContainerMainList>
            <h1>Textes List</h1>
            <CreateText/>
            <GUI.BlockForLists>
                <GUI.TableList>
                    <tbody>
                    <GUI.TableTR>
                        <GUI.TableTHID>Algorithm ID</GUI.TableTHID>
                        <GUI.TableTHText>Text</GUI.TableTHText>
                    </GUI.TableTR>
                    {test.map((text: any) => {
                        return (
                            <tr key={text['id']}>
                                <GUI.TableTD>
                                    <GUI.TableLink
                                    to={`/algorithms/${text['algorithm']}`}>
                                        {text['algorithm']}
                                    </GUI.TableLink>
                                </GUI.TableTD>
                                <GUI.TableTD>{text['body']['list'][0]['text']}</GUI.TableTD>
                            </tr>
                        )
                    })}
                    </tbody>
                </GUI.TableList>
            </GUI.BlockForLists>
        </GUI.ContainerMainList>
    )
}