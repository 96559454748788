import React, {ChangeEvent, useState} from "react";
import {SuccessNotify, ErrorNotify} from "../../Notifies/Notifications";
import * as GUI from "../styles";
import {ButtonAdd, ButtonTrash, TopAlgorithmSettings} from "../Blocks/Buttons";
import {useTranslation} from "react-i18next";
import {useFieldArray, useForm} from "react-hook-form";
import * as UI from "../style"
import {numbersAPI} from "../../services/SimcardsService";
import {useNavigate} from "react-router-dom";
export const CreatePhonemaps = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [createNumbers] = numbersAPI.useCreateNumbersMutation()
    const {control, handleSubmit, register} = useForm({
        defaultValues: {
            mapping: [
                {number: '9123456789'}
            ]
        }
    })
    const {fields, remove, append} = useFieldArray({control, name: "mapping"})
    const [name, setName] = useState<string>('Name of phonemaps')
    const [tag, setTag] = useState<string>('Tag of phonemaps')
    const [comment, setComment] = useState<string>('Comment of phonemaps')
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }
    const tagChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTag(event.target.value)
    }
    const commentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value)
    }
    const inputCheckNumb = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const filteredValue = inputValue.replace(/\D/g, '');
        if (filteredValue !== inputValue) {
            event.target.value = filteredValue;
        }
    }
    const createNumb = async (NewData:any) => {
        const { mapping } = NewData;
        const data = {
            "name": name,
            "comment": comment,
            "tag": tag,
            "mapping": mapping.map((item: any) => ({ number: item.number }))
        }
        try {
            await createNumbers(data)
            SuccessNotify('Success!')
            navigate(`/phonemaps`)
        } catch {
            ErrorNotify('Error!')
        }
    }
    return (
        <GUI.ContainerMainBlock>
            <UI.FormAlgorithm onSubmit={handleSubmit(createNumb)}>
                <GUI.TopBlockAlgorithm>
                    <GUI.DefaultBlock>
                        <TopAlgorithmSettings />
                        <GUI.SecondBlockAlgorithmMainInfo>
                            <GUI.SecondBlockAlgorithmInputName
                                style={{marginBottom: '10px'}}
                                type="text" placeholder="Name"
                                name="name" value={name}
                                onChange={nameChange}
                                maxLength={25}/>
                            <GUI.SecondBlockAlgorithmInputName
                                style={{marginBottom: '10px'}}
                                type="text" placeholder="Tag"
                                name="tag" value={tag}
                                onChange={tagChange}
                                maxLength={25}/>
                            <GUI.SecondBlockAlgorithmInputName
                                style={{marginBottom: '10px'}}
                                type="text" placeholder="Comment"
                                name="comment" value={comment}
                                onChange={commentChange}
                                maxLength={25}/>
                        </GUI.SecondBlockAlgorithmMainInfo>
                    </GUI.DefaultBlock>
                </GUI.TopBlockAlgorithm>
                <GUI.DefaultBlock>
                    <UI.StructureAlgorithmBlock>
                        <GUI.AboutBlock>
                            {t("Structure")}
                        </GUI.AboutBlock>
                        <GUI.MainBlockNumbers>
                                <GUI.BlockNumbers>
                                    {fields && fields.map((item: any, index: any) => {
                                        return (
                                            <GUI.StructureNumber key={index}>
                                                <GUI.StructureCoreTopBlock>
                                                    <GUI.StructureCoreTopBlockBlocks>
                                                        <GUI.NumberBlock>
                                                            <UI.DefaultSpan style={{marginRight: '10px'}}>{index + 1}</UI.DefaultSpan>
                                                            <GUI.StructureCoreInputText
                                                                placeholder={"9*********"}
                                                                maxLength={10}
                                                                {...register(`mapping.${index}.number`,
                                                                    { minLength: 10, pattern: /^[0-9]+$/ })}
                                                                onInput={(e:any) => inputCheckNumb(e)}/>
                                                            <GUI.BlockAlgorithmButton
                                                                type="button"
                                                                onClick={() => append(index)}>
                                                                <ButtonAdd/>
                                                            </GUI.BlockAlgorithmButton>
                                                            <GUI.BlockAlgorithmButton
                                                                type="button"
                                                                onClick={() => {
                                                                    if (fields.length > 1) {
                                                                        remove(index)
                                                                    } else {
                                                                        ErrorNotify('The phonemaps cannot be less than 1 number')
                                                                    }
                                                                }}>
                                                                <ButtonTrash/>
                                                            </GUI.BlockAlgorithmButton>
                                                        </GUI.NumberBlock>
                                                    </GUI.StructureCoreTopBlockBlocks>
                                                </GUI.StructureCoreTopBlock>
                                            </GUI.StructureNumber>
                                        )
                                    })}
                                </GUI.BlockNumbers>
                        </GUI.MainBlockNumbers>
                    </UI.StructureAlgorithmBlock>
                </GUI.DefaultBlock>
            </UI.FormAlgorithm>
        </GUI.ContainerMainBlock>
    )
}
