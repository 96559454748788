import React, {useEffect, useState} from 'react';
import * as GUI from "../styles";
import {Controller} from "react-hook-form";
import {DataJson} from "../../types/AlgorithmCore";
import {useTranslation} from "react-i18next";
import {filesAPI} from "../../services/FileService";
import {ModalRemove} from "../Modal/modal";
import * as UI from "../AlgorithmList/style";
import {algorithmAPI} from "../../services/AlgorithmService";
import * as MUI from "../style"
import {getTheme} from "../../index";
import {SuccessNotify} from "../../Notifies/Notifications";
import {useNavigate} from "react-router-dom";
import {userAPI} from "../../services/UserService";
import {notAuthorizedAPI} from "../../services/NotAuthorized";
import {updateForAdd} from "../../Validators/updaterForCheckerAndGoto";
import {LittleSpinner, Spinner} from "../Spinner";
import {remoteAPI} from "../../services/RemoteService";
import {templatesAPI} from "../../services/LayoutsService";
export const ButtonTrash = () => {
    return (
        <MUI.CardButtonSVGMini
            src="/images/TrashRed.svg"
            alt="Remove 1 element core"/>
    )
}

export const StructureItem = ({index, name, doubleStep}: any) => {

    return (
        <>

        </>
    )
}
export const ButtonAddInList = ({type}: any) => {
    const src = `/images/${type.type}.svg`
    return (
        <MUI.CardButtonSVGMini
            src={src}
            alt={'click'}/>
    )
}
export const ButtonAdd = () => {
    return (
        <MUI.CardButtonSVGMini
            src="/images/AddBlue.svg"
            alt="Add"/>
    )

}
export const ButtonsDropdown = ({index, insert, type, fields, setValue}: any) => {

    // Templates List
    const { data: templates, isLoading, isError } = templatesAPI.useGetAllTemplatesQuery(undefined, {
        refetchOnMountOrArgChange: true,
        skip: false
    })
    const [ showTemplates, setShowTemplates ] = useState<boolean>(false)
    if (isLoading) return <Spinner/>
    if (isError) return <span>Sorry, error. Try again later.</span>
    const TemplatesListModal = ({id}: any) => {
        const addTemplateInCore = (name: string) => {
            const selectedTemplate = templates.find((e: any) => e.name === name)
            console.log(selectedTemplate?.sub_core['list'])
            insert(Number(id) + 1, selectedTemplate?.sub_core['list'])
        }
        if (showTemplates){
            return (
                <MUI.UlTemplates>
                    {templates && templates.map((item: any, index: number) => {
                        return (
                            <MUI.TemplateLi key={index}
                                onClick={() => addTemplateInCore(item?.name)}>
                                {item?.name}
                            </MUI.TemplateLi>
                        )
                    })}
                </MUI.UlTemplates>
            )
        } else {
            return <span></span>
        }
    }
    switch (type.type) {
        case 'click':
            return (
                <ul style={{backgroundColor: getTheme().background}}>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1, { click_to_path: "Click to path"});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Click to path</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1, { click_to_image: "Click to image"});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Click Image</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{click_coords: 'x= / y= '});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Coords</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{click_unnecessary: 'Unnecessary'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Unnecessary</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{anti_captcha: 'Anti Captcha'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Anti Captcha</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{click_to_image_unnecessary: 'Unnecessary Click Image'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Unnecessary click image</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{checker: '0'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Checker</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{go_to: '0'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Go to</MUI.SpecificallyLi>
                </ul>
            )
        case 'text':
            return (
                <ul style={{backgroundColor: getTheme().background}}>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ input_text: "Input text"});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Input text</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ put_sms: "Put sms"});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>SMS</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{neuro_input_text: 'Neuro input text'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Neuro</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{clean_field: 'Clean Field'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Clean Field</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{put_bankcode: 'Put code'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Put code</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{neuro_put_code: 'Neuro put code'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Neuro put code</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{copy_data: 'Copy data'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Copy data</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{paste_data: 'Paste data'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Paste data</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{sms_service_number: 'SMS Service number'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>SMS Service number</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{sms_service_code: 'SMS Service code'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>SMS Service code</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{open_link: 'Link'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Open link</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{save_phone: 'Save phone'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Save phone</MUI.SpecificallyLi>
                </ul>
            )
        case 'system':
            return (
                <ul style={{backgroundColor: getTheme().background}}>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ home: 'Home'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Home</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ok_enter: 'Ok/Enter'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Ok/Enter</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ sleep: "1"});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Sleep</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ reboot: "Reboot"});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Reboot</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ swipe_up: 'Swipe Up'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Swipe Up</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{ swipe_down: 'Swipe Down'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Swipe Down</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{collapse_keyboard: 'Collapse keyboard'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Collapse keyboard</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{reset_all: 'Reset All'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Reset All</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{macros: 'Macros'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Macros</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{delete_phone_book: 'Delete phone book'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Delete phone book</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{transfer_images: 'Transfer images'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Transfer images</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => setShowTemplates(!showTemplates)}>
                        <ButtonAdd/>Templates
                        <TemplatesListModal id={index}/>
                    </MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{set_proxy: 'Set proxy'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Set proxy</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{disable_proxy: 'Disable proxy'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Disable proxy</MUI.SpecificallyLi>
                </ul>
            )
        case 'apps':
            return (
                <ul style={{backgroundColor: getTheme().background}}>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{open_app: 'Open App'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Open App</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{close_app: 'Close App'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Close App</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{close_apps: 'Close Apps'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Close Apps</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{delete_app: 'Delete App'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Delete App</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{enable_vpn: 'Enable VPN'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Enable VPN</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{disable_vpn: 'Disable VPN'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Disable VPN</MUI.SpecificallyLi>
                </ul>
            )
        case 'whatsapp':
            return (
                <ul style={{backgroundColor: getTheme().background}}>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{take_warmed_number: 'Take warmed number'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Take warmed number</MUI.SpecificallyLi>
                    <MUI.SpecificallyLi style={{color: getTheme().colorFont, background: getTheme().inputBackground}} onClick={() => {insert(index + 1,{take_free_number: '1'});
                        updateForAdd(fields, setValue, index)}}>
                        <ButtonAdd/>Take free number</MUI.SpecificallyLi>
                </ul>
            )
        default:
            return (<div>Load</div>)

    }
}

export const InItemStructureCore = ({index, remove, ButtonSelect, swap, fields, name}: any) => {
    return (
        <GUI.StructureCoreTopBlock>
            <GUI.StructureCoreTopBlockBlocks>
                <StructureItem index={index} name={name}/>
            </GUI.StructureCoreTopBlockBlocks>
            <GUI.StructureCoreTopBlockBlocks>
                <GUI.BlockAlgorithmButton type="button">
                    <ButtonSelect/>
                </GUI.BlockAlgorithmButton>
                <GUI.BlockAlgorithmButton
                    type="button"
                    onClick={() => remove(index)}>
                    <ButtonTrash/>
                </GUI.BlockAlgorithmButton>
            </GUI.StructureCoreTopBlockBlocks>
        </GUI.StructureCoreTopBlock>
    )
}

export const CreateMainInfo = ({name, nameChange, description, descriptionChange}: any) => {
    return (
        <GUI.SecondBlockAlgorithmMainInfo>
            <GUI.SecondBlockAlgorithmInputName
                type="text" placeholder="Name"
                name={name} onChange={nameChange}
                maxLength={15}/>
            <GUI.SecondBlockAlgorithmInputName
                type="text" placeholder="Description"
                name={description} onChange={descriptionChange}>
            </GUI.SecondBlockAlgorithmInputName>
        </GUI.SecondBlockAlgorithmMainInfo>
    )
}
export const ExecuteButton = ({remoteStatus, execute, id, algorithm, refetch, setDisabledBlock, currentComp, remoteData}: any) => {
    const navigate = useNavigate()
    const [ setStatusAlgorithm ] = remoteAPI.useStatusAlgorithmMutation()
    const { t } = useTranslation();
    const [ modalActive, setModalActive ] = useState<boolean>(false)

    const stopAlgorithm = async () => {
        try {
            const data = {
                terminate_runtime_status: !remoteData?.terminate_runtime_status,
            }
            await setStatusAlgorithm({data, currentComp})
                .then(() => SuccessNotify('The device stops. Please wait.', 10000))
                .then(() => setTimeout(() => navigate(0), 10000))
                .then(() => setModalActive(!modalActive))
        } catch (error) {
            console.log(error)
        }
    }

    const ModalItem = () => {
        return  <ModalRemove active={modalActive} setActive={setModalActive}>
            <UI.CardAboutName>
                <MUI.DefaultSpan>
                    {t("areYouSure")}
                </MUI.DefaultSpan>
            </UI.CardAboutName>
            <GUI.DefaultBlockFlex>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => {
                    stopAlgorithm()
                        .then(() => setDisabledBlock(true))
                        .then(() => setTimeout(() => setDisabledBlock(false), 10000));
                }}> {t("Stop")}
                </MUI.DefaultButton>
                <MUI.DefaultButton width="140px" height="40px"
                                   color={getTheme().greenLight}
                                   background={getTheme().darkGreen}
                                   type="button" onClick={() => setModalActive(false)}> {t("cancel")}
                </MUI.DefaultButton>
            </GUI.DefaultBlockFlex>
        </ModalRemove>
    }
    const [disable, setDisable] = useState<boolean>(false)
    if (remoteStatus === 'ready') {
        return (
            <GUI.BlockAlgorithmButton
                type="button"
                onClick={() => {setDisable(false);execute(id)}}
                disabled={disable}>
                <MUI.CardButtonButtonSVG
                    src="/images/Play.svg"
                    alt="Run Algorithm"
                />
            </GUI.BlockAlgorithmButton>
        )
    } else if(remoteStatus === 'busy'){
        return (
            <>
                <ModalItem/>
                <GUI.BlockAlgorithmButton
                    type="button"
                    onClick={() => setModalActive(!modalActive)}>
                    <GUI.CardButtonButtonLittleSVG
                        src="/images/Block.svg"
                        alt="Run Algorithm"/>
                </GUI.BlockAlgorithmButton>
            </>
        )
    } else {
        return (
            <GUI.BlockAlgorithmButton
                type="button"
                disabled={true}>
                <GUI.CardButtonButtonLittleSVG
                    src="/images/Block.svg"
                    alt="Run Algorithm"/>
            </GUI.BlockAlgorithmButton>
        )
    }
}

export const StatusAlgorithmButton = ({remoteStatus, changeAlgorithmStatus, remoteData}: any) => {
    useEffect(() => {
    }, [remoteData])
    if (['active', 'ready'].includes(remoteStatus)) {
        return (<div></div>)
    } else {
        if (!remoteData?.stop_runtime_status) {
            return (
                <GUI.BlockAlgorithmButton
                    type="button"
                    onClick={() => {changeAlgorithmStatus()}}>
                    <MUI.CardButtonButtonSVG
                        src="/images/Pause.svg"
                        alt="Run Algorithm"
                    />
                </GUI.BlockAlgorithmButton>
            )
        } else {
            return (
                <GUI.BlockAlgorithmButton
                    type="button"
                    onClick={() => changeAlgorithmStatus()}>
                    <GUI.CardButtonButtonLittleSVG
                        src="/images/Continue.svg"
                        alt="Run Algorithm"/>
                </GUI.BlockAlgorithmButton>
            )
        }
    }
}
export const TopAlgorithmSettings = () => {
    const { t } = useTranslation();
    return (
        <MUI.TopBlockAlgorithmSettings>
            <MUI.TopBlockAlgorithmTopPart style={{padding: '0'}}>
                <GUI.TopBlockAlgorithmAbout>
                    {t("Main")}
                </GUI.TopBlockAlgorithmAbout>
                <GUI.TopBlockAlgorithmButtons>
                    <MUI.DefaultButton width="200px" height="48px"
                                      color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                                      type="submit"> {t("Create")}
                    </MUI.DefaultButton>
                </GUI.TopBlockAlgorithmButtons>
            </MUI.TopBlockAlgorithmTopPart>
        </MUI.TopBlockAlgorithmSettings>
    )
}

export const BlockSelect = ({item, index, step, register, control, fields, setValue, update, macro}:any) => {
    const selectedName = macro && macro.filter((e: any) => Number(e.id) === Number(item.macros)).map((nameMacro: any) => nameMacro.name)
    const valueSelectedName = macro && macro.map((itemMacro: any) => itemMacro.id)
    useEffect(() => {
    }, [ macro, item, index, selectedName, fields ])
    return (
        <GUI.BlockForInputSelect style={{color: getTheme().colorFont, background: getTheme().backgroundTheme}} onChange={() => {
            // @ts-ignore
            update(item[index], fields[index][step])
        }}>
            <GUI.SecondBlockAlgorithmSelectTextArea style={{background: getTheme().backgroundTheme}} {...register(`list.${index}.${step}`, {
                required: true
            })}>
                <GUI.StructureCoreOptionText style={{background: getTheme().background}} value={valueSelectedName}>{selectedName}</GUI.StructureCoreOptionText>
                {macro && macro.map((itemMacro: any, indexMacro: number) => {
                    return (
                        <Controller key={indexMacro} render={() =>
                            <GUI.StructureCoreOptionText style={{background: getTheme().background}}
                                value={itemMacro.id}>{itemMacro.name}</GUI.StructureCoreOptionText>
                        }
                                    name={`list.${index}.${step}`}
                                    control={control}/>
                    )
                })}
                {/*<ButtonInput step={step} index={index} fields={fields} setValue={setValue}/>*/}
            </GUI.SecondBlockAlgorithmSelectTextArea>
        </GUI.BlockForInputSelect>
    )
}
export const BlockInput = ({item, index, step, register, control, fields, setValue, update}:any) => {

   const [ showImage ] = filesAPI.useLazyShowPreviewQuery()
   const [ upload ] = filesAPI.useUploadFileMutation()
    const username: string = localStorage.getItem('username') || 'none'
   const [fileName, setFileName] = useState<string>(fields[index].click_to_image || fields[index].click_to_image_unnecessary)
   const uploadFile = async (index: number, e: any, key: boolean = false) => {
      setFileName(e.target.files[0].name)
      setDisplayValue('')
      const formData = new FormData();
      for (let file of e.target.files) {
         formData.append("files", file)
      }
      await upload(formData)
      key ? setValue(`list.${index}.${step}`, e?.target?.files[0].name + '/') : setValue(`list.${index}.${step}`, e?.target?.files[0].name)
   }
   const regex = /(.+)\.(png|jpe?g)/;
   const filename = fields[index][step].match(regex);

   const funcChange = (e: any) => {
      if (filename && !fileName) {
         setDisplayValue(e.target.value)
         setValue(`list.${index}.${step}`, filename[0] + '/' + e.target.value);
      } else {
         setDisplayValue(e.target.value);
         setValue(`list.${index}.${step}`, fileName + '/' + e.target.value);
      }
   }

   const [imagesArray, setImagesArray] = useState<string[]>([])
   let fileNameInCheck = () => {
      try {
         const findIndex = fields[index].checker.split('').findIndex((e: string) => e === '.')

         const formatFile: any = () => {
            let format;
            const formats = ['png', 'jpeg', 'jpg']
            for (let i of formats) {
               if (fields[index].checker.includes(i)) {
                  format = i
                  break
               }
            }
            return format
         }
         return fields[index]?.checker?.slice(0, findIndex + formatFile()?.length+1)
      } catch (e: any){
         return ''
      }
   }
   const [displayValue, setDisplayValue] = useState<any>(fields[index][step].slice(fileNameInCheck().length + 1))
    const [ myPreview, setMyPreview ] = useState<any>('')
    const [ displayImage, setDisplayImage ] = useState<boolean>(false)
   const getImage = async () => {
       if (!imagesArray.includes(fileName)){
           setImagesArray((prevState: string[]) => [...prevState, fileName])
           const image = await showImage(fileName)
           const file = new File([image.data], 'test.png', {
               type: 'image/png'
           })
           if (image.status !== 'rejected' && image.data.size > 10) {
               const binURL = window.URL.createObjectURL(file)
               setMyPreview(binURL)
           }
       }
   }
   useEffect(() => {

   }, [displayValue, fileName, setValue])

   if (['click_to_image', 'click_to_image_unnecessary', 'transfer_images'].includes(step)) {
      return (
         <GUI.BlockForInput onChange={() => {
            update(item[index], fields[index][step])
         }}
                            onMouseEnter={() => {
                                getImage()
                                setDisplayImage(true)
                            }}
                            onMouseLeave={() => {
                                setDisplayImage(false)
                            }}>
            <GUI.DefaultLabelInCore style={{background:"#29C570"}}
            >
                <GUI.InputFile type="file"
                               multiple
                               onChange={(e: any) => uploadFile(index, e, false)}
                />
                <MUI.DefaultSpan>{fileName || fields[index].click_to_image || fields[index].click_to_image_unnecessary || fields[index].transfer_images}
                </MUI.DefaultSpan>
            </GUI.DefaultLabelInCore>
             <GUI.PreviewBlock
                 index={index}
                 style={{height: displayImage ? 'auto' : '0', overflow: 'hidden'}} onMouseEnter={() => getImage()}>
                 {myPreview.length > 5 ? <GUI.PreviewImage height={displayImage ? '50px': '0'} src={myPreview} alt="preview"/> :
                     <span style={{display: displayValue ? 'block' : 'none', overflow: 'hidden'}}>
                         <LittleSpinner/>
                     </span>}
             </GUI.PreviewBlock>
         </GUI.BlockForInput>
      )
   } else if(step === 'checker') {

       return (
          <GUI.BlockForInput>
             <GUI.DefaultLabelInCore style={{background:"#29C570"}}>
                 <GUI.InputFile type="file"
                                multiple
                                onChange={(e: any) => uploadFile(index, e, true)}
                 />
                 <MUI.CardButtonSVGMini style={{background: "white", borderRadius: "50px"}}
                                        src="/images/Export.svg"
                                        alt="Upload"/>
                 <MUI.DefaultSpan>{fileName || fileNameInCheck()}</MUI.DefaultSpan>
                 <Controller render={({item}: any) =>
                     <GUI.StructureCoreInputText style={{color: getTheme().colorFont, background: getTheme().inputBackground, width: "50%", marginTop: "0.5px"}} type="text" min="0" max="3600" step="1"
                                                 value={displayValue}
                                                 onChange={(e) => {
                                                     funcChange(e)
                                                 }}
                     />
                 }
                             name={`list.${index}.${step}`}
                             control={control}/>
             </GUI.DefaultLabelInCore>
          </GUI.BlockForInput>
       )
    } else {
       if (step !== 'sleep' && step !== 'take_free_number') {
          return (
             <GUI.BlockForInput onChange={() => {
                update(item[index], fields[index][step])
             }}>
                <Controller render={({item}: any) =>
                   <GUI.StructureCoreInputText style={{color: getTheme().colorFont, background: getTheme().inputBackground}} {...register(`list.${index}.${step}`)}/>
                }
                            name={`list.${index}.${step}`}
                            control={control}/>
             </GUI.BlockForInput>
          )
       } else {
          return (
             <GUI.BlockForInput onChange={() => {
                update(item[index], fields[index][step])
             }}>
                <Controller render={({item}: any) =>
                   <GUI.StructureCoreInputText style={{color: getTheme().colorFont, background: getTheme().inputBackground}} type="number" min="0" max="3600" step="1"
                                               {...register(`list.${index}.${step}`)}/>
                }
                            name={`list.${index}.${step}`}
                            control={control}/>
             </GUI.BlockForInput>
          )
       }
    }
}

export const ButtonGetData = ({setModalActive}: any) => {
    const { t } = useTranslation();
    return (
        <MUI.DefaultButton width="180px" height="48px"
                           color={getTheme().greenLight}
                           background={getTheme().darkGreen}
                           onClick={() => setModalActive(true)}>{t("GetData")}</MUI.DefaultButton>
    )
}

export const ButtonShowData = ({setModalShowActive}: any) =>
{
    const { t } = useTranslation();
    return (
        <MUI.DefaultButton color={getTheme().greenLight}
                           background={getTheme().darkGreen}
                           width="180px" height="48px" onClick={() => setModalShowActive(true)}>{t("ShowData")}</MUI.DefaultButton>
    )
}
export const GetGoogleSheets = ({checkOwnKeys, file}: any) => {
    const { t } = useTranslation();
    const disable = false
    if (checkOwnKeys(DataJson, file) === true) {
        return (
            <MUI.DefaultButton color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                               width="180px" height="48px" disabled={disable}>{t("Submit")}</MUI.DefaultButton>
        )
    } else {
        return (
            <MUI.DefaultButton color={getTheme().greenLight}
                                      background={getTheme().darkGreen}
                               width="120px" height="32px" disabled={true}>{t("Submit")}</MUI.DefaultButton>
        )
    }
}