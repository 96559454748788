import React from 'react';
import {simBankApi} from "../../services/SimBankService";
import {Spinner} from "../Spinner";
import * as UI from '../styles'
import * as MUI from '../style'
import {useTranslation} from "react-i18next";
import {NumberStatus} from "../style";

type TStatusColor = "undefined" | "in work" | "in use" | "warmed up" | "blocked"
interface INumber {
    number: string,
    status: TStatusColor
}

const StatusColor = {
    "undefined" : "#9C9FAA",
    "in work" : "#AED413",
    "in use" : "#29C570",
    "warmed up": "#F49C35",
    "blocked": "#FF5963"
}
export const SimBank = () => {
    const username = localStorage.getItem('username')
    const { t } = useTranslation();
    const { data, error, isLoading } = simBankApi.useGetSmsBankQuery(undefined, {
        skip: false,
        refetchOnMountOrArgChange: true
    })

    if (isLoading) return <Spinner/>
    if (error) return <>Error: {JSON.stringify(error)}</>
    return (
        <MUI.MainBlock style={{margin: "3em 0"}}>
            {data && data.map((item: INumber, index: number) => {
                return (
                    <MUI.BlockNumber key={index}>
                        <h4>{item.number}</h4>
                        <MUI.BlockNumberStatus>
                            <MUI.NumberStatus color={StatusColor[item.status]}>{item.status || `Not found`}</MUI.NumberStatus>
                        </MUI.BlockNumberStatus>
                    </MUI.BlockNumber>
                )
            })}
        </MUI.MainBlock>
    )
}

