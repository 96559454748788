import {ErrorNotify} from "../Notifies/Notifications";


export const updateForRemove = (fields: any, setValue: any, index: number) => {
    for (let item of fields) {
        const indexItem = fields.indexOf(item)
        switch(Object.keys(item)[0]){
            case 'checker':
                const splitChecker = item.checker.split('')
                const indexSlash = splitChecker.indexOf('/')
                const numberOfChecker = splitChecker.slice(indexSlash + 1).join('')
                const followIndex = fields.findIndex((elem: any) =>
                    JSON.stringify(elem) === JSON.stringify(fields[numberOfChecker]))
                if (followIndex !== 0) {
                    if (followIndex === index) {
                        ErrorNotify(`Delete step ${index} for checker: step№ ${fields.indexOf(item) - 1}`)
                        setValue(`list.${indexItem - 1}.checker`, String(Object.values(item)[0]).slice(0, indexSlash + 1) + 'Enter a number')
                    } else {
                        if (followIndex >= index) {
                            setValue(`list.${indexItem - 1}.checker`, String(Object.values(item)[0]).slice(0, indexSlash + 1) + String(-1))
                        }
                    }
                }
                break;
            case 'go_to':
                const followIndexGoTo = fields.findIndex((elem: any) =>
                    JSON.stringify(elem) === JSON.stringify(fields[Number(Object.values(item)[0])]))
                const indexGoTo = fields.indexOf(item)
                if (followIndexGoTo !== 0) {
                    console.log(followIndexGoTo, index)
                    if (followIndexGoTo === index) {
                        ErrorNotify(`Delete step ${index} for Go to: step№ ${indexGoTo - 1}`)
                        setValue(`list.${indexItem - 1}.go_to`, 'Enter a number')
                    } else {
                        if (followIndexGoTo >= index) {
                            setValue(`list.${indexItem - 1}.go_to`, String(-1))
                        }
                    }
                }
                break;
        }
    }
}

export const updateForAdd = (fields: any, setValue: any, index: number) => {
    for (let item of fields) {
        if (Object.values(item)[0] !== '0') {
            const indexItem = fields.indexOf(item)
            switch(Object.keys(item)[0]){
                case 'checker':
                    const splitChecker = item.checker.split('')
                    const indexSlash = splitChecker.indexOf('/')
                    const numberOfChecker = splitChecker.slice(indexSlash + 1).join('')
                    const followIndex = fields.findIndex((elem: any) =>
                        JSON.stringify(elem) === JSON.stringify(fields[numberOfChecker]))
                    if (followIndex !== 0) {
                        if (followIndex <= index) {
                            setValue(`list.${indexItem + 1}.checker`, String(Object.values(item)[0]).slice(0, indexSlash + 1) + String(-1))
                        }
                    }
                    break;
                case 'go_to':
                    const followIndexGoTo = fields.findIndex((elem: any) =>
                        JSON.stringify(elem) === JSON.stringify(fields[Number(Object.values(item)[0])]))
                    if (followIndexGoTo !== 0) {
                        if (followIndexGoTo <= index) {
                            setValue(`list.${indexItem + 1}.go_to`, String(-1))
                        }
                    }
                    break;
            }
        }
    }
}