import React, {ChangeEvent, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'
import * as UI from '../LoginPage/style'
import {ErrorNotify, SuccessNotify} from "../../Notifies/Notifications";
import {userAPI} from "../../services/UserService";
import {useForm} from "react-hook-form";
import * as GUI from "../style";
import {useTranslation} from "react-i18next";
import {getTheme} from "../../index";

export const ChangePassword = ({token} : any) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [old_password, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordMatch, setPasswordMatch] = useState('')
    const [passwordDirty, setPasswordDirty] = useState(false)
    const [passwordError, setPasswordError] = useState('Password can not be empty!')
    const [numbPass, setNumbPass] = useState('At least one number')
    const [upperPass, setUpperPass] = useState('One uppercase letter')
    const [lowerPass, setLowerPass] = useState('One lowercase letter')
    const [minPass, setMinPass] = useState('Minimum 8 characters')
    const [formValid, setFormValid] = useState(false)
    const { handleSubmit } = useForm()
    const [changePassword ] = userAPI.useChangePasswordMutation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const passwords = {
        old_password: old_password,
        new_password: password,
    }
    const checkNewPasswords = () => {
        if (password !== passwordMatch) {
            ErrorNotify("Passwords doesn't match")
        } else {
            changePassword(passwords).then((data) => {
                if (data.hasOwnProperty('error')) {
                    ErrorNotify('An error has occurred!')
                } else {
                    SuccessNotify('Password changed!')
                }
            })
            navigate('/login')
        }
    }

    const validatorPassword = (password: string) => {
        const min1numb = /(?=.*[0-9])/g
        const minLowLet = /(?=.*[a-z])/g
        const minUppLet = /(?=.*[A-Z])/g
        password.length === 0 ? setPasswordError('Password can not be empty!')
            : setPasswordError(''); setPasswordDirty(true)

        password.length < 8 ? setMinPass('Minimum 8 characters')
            : setMinPass(''); setPasswordDirty(true)

        !min1numb.test(String(password)) ? setNumbPass('At least one number')
            : setNumbPass(''); setPasswordDirty(true)

        !minLowLet.test(String(password)) ? setLowerPass('One lowercase letter')
            : setLowerPass(''); setPasswordDirty(true)

        !minUppLet.test(String(password)) ? setUpperPass('One uppercase letter')
            : setUpperPass(''); setPasswordDirty(true)
    }
    const oldPasswordChange = (event: ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)

    const passwordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
        validatorPassword(event.target.value)
    }
    const passwordMatchChange = (event: ChangeEvent<HTMLInputElement>) => setPasswordMatch(event.target.value)
    const blurHandler = (e:any) => {
        switch (e.target.name) {
            case 'password':
                setPasswordDirty(true)
                break
        }
    }
    useEffect(() => {
        if (passwordError || upperPass || lowerPass || numbPass || minPass) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
        if (token) {
            navigate('/algorithms')
        }
    }, [password, passwords, passwordError, upperPass, lowerPass, numbPass, minPass, token, navigate])

    return (
        <GUI.DefaultBlock>
            <GUI.RegisterLoginBlock>
                <GUI.AboutBlockCenter>{t("changePassword")}</GUI.AboutBlockCenter>
                <UI.LoginPage>
                    <UI.InputDataBlock>
                        <UI.LoginForm onSubmit={handleSubmit(checkNewPasswords)}>
                            <UI.InputBlock>
                                <UI.LabelItem style={{color: '#202125'}}>{t("OldPassword")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={old_password}
                                              name="old_password" required
                                              onChange={(e) => oldPasswordChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                <UI.LabelItem style={{color: '#202125'}}>{t("NewPassword")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={password}
                                              name="password" required
                                              onChange={(e) => passwordChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                <UI.LabelItem style={{color: '#202125'}}>{t("ConfirmNewPassword")}</UI.LabelItem>
                                <UI.InputItem type="password" placeholder="******"
                                              value={passwordMatch}
                                              name="password" required
                                              onChange={(e) => passwordMatchChange(e)}
                                              onBlur={(e) => blurHandler(e)}/>
                                {(passwordDirty && passwordError) && <div style={{color: 'red'}}>{passwordError}</div>}
                            </UI.InputBlock>
                            <UI.InputBlock>
                                {(!passwordDirty || numbPass) && <UI.InfoPasswordGrey>✖ {numbPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !numbPass) && <UI.InfoPasswordGreen>✔ At least one number</UI.InfoPasswordGreen>}
                                {(!passwordDirty || upperPass) && <UI.InfoPasswordGrey>✖ {upperPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !upperPass) && <UI.InfoPasswordGreen>✔ One uppercase letter</UI.InfoPasswordGreen>}
                                {(!passwordDirty || lowerPass) && <UI.InfoPasswordGrey>✖ {lowerPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !lowerPass) && <UI.InfoPasswordGreen>✔ One lowercase letter</UI.InfoPasswordGreen>}
                                {(!passwordDirty || minPass) && <UI.InfoPasswordGrey>✖ {minPass}</UI.InfoPasswordGrey>}
                                {(passwordDirty && !minPass) && <UI.InfoPasswordGreen>✔ Minimum 8 characters</UI.InfoPasswordGreen>}
                            </UI.InputBlock>
                            <GUI.DefaultButton width="385px" height="32px" style={{marginRight: "10px"}}
                                               color={getTheme().lightStyleBackground}
                                               background={getTheme().darkGreen}
                                               disabled={!formValid}
                                               type="submit"> {t("changePassword")}
                            </GUI.DefaultButton>
                        </UI.LoginForm>
                    </UI.InputDataBlock>
                </UI.LoginPage>
            </GUI.RegisterLoginBlock>
        </GUI.DefaultBlock>
    )
}